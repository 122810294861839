import React, { useState, useEffect } from "react";
import {
    Grid,
    Container,
    Button,
    Tooltip,
    Box,
    Card,
    CardContent,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardHeader,
    CircularProgress,
    Avatar
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { cEncrypt } from "../../helpers";
import { User } from "../../api/resources/User";
import Rating from '@mui/material/Rating';
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import InfoIcon from '@mui/icons-material/Info';
import Zoom from "@mui/material/Zoom";
import wave from "../../images/waves.png";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            backgroundColor: "#EEF2F5 !important"
        }
        //   a: { color: theme.palette.common.white,
        //       '&:hover': {
        //           color: theme.palette.common.white
        //       }
        //   }
    },
    review: {
        display: "flex",
        flexWrap: "wrap",
        // justifyContent: 'space-around',
        overflow: "auto",
        // backgroundColor: theme.palette.background.paper,
        flexWrap: "nowrap",
        alignItems: "center"
    },
    reviewCard: {
        flex: "0 0 auto",
        margin: "0.5rem 0.5rem 1.5rem",
        maxWidth: "255px"
    },
    heading: {
        // fontSize: "3rem",
        paddingTop: theme.spacing(4),
        paddingBottom: "118px",
        backgroundColor: "#3f51b5",
        color: theme.palette.common.white,
        borderBottomLeftRadius: "25px",
        borderBottomRightRadius: "25px",
        backgroundImage: `url(${wave})`,
        backgroundPosition: "0 99px",
        backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
        [theme.breakpoints.up("sm")]: {
            backgroundSize: "cover"
        }
    },
    whyCoalShastra: {
        minHeight: "280px",
        [theme.breakpoints.up("sm")]: {
            minHeight: "158px"
        }
    },
    btnContainer: {
        maxWidth: '200px',
        margin: theme.spacing(1, 'auto'),
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        }
    },
    cardTitle: {
        fontSize: "18px"
    },
    whiteButton: {
        backgroundColor: theme.palette.common.white,
        color: "#3D62E4"
    },
    headerCard: {
        marginTop: "-86px",
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
    },
    twoCol: {
        display: "grid",
        gridTemplateColumns: "auto 100px"
    },
    cardContentGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        justifyItems: 'center',
        backgroundColor: '#eef2f5',
        borderRadius: 5,

    },
    card_ratingReviews: {
        display: "flex",
        // gridTemplateColumns: "25px 100px 120px",
    },
    outline: {
        boxShadow: '0px 0px 0px 2.25px #7083f0 inset'
    },
    statsWrapper: {
        position: 'relative',
    },
    stats: {
        position: 'absolute',
        top: '16px',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    cardContentInnerGrid: {
        display: "grid",
        gridTemplateColumns: "12px auto",
        gridGap: "5px"
    },
    cardButton: {
        // backgroundColor: '#2959BA',
        // opacity: '20%'
        backgroundColor: "#d4def1",
        color: "#2959BA"
    },
    bold: {
        fontWeight: "bold"
    },
    gridCol2Icon: {
        display: "grid",
        gridGap: '10px',
        gridTemplateColumns: '12px auto',
        alignItems: 'center',
        color: '#e19d25de',
        '& img': {
            width: 12
        }
    },
    textDark: {
        color: '#474747'
    }

}));

// const HtmlTooltip = withStyles(theme => ({
//     tooltip: {
//         backgroundColor: "#f5f5f9",
//         color: "rgba(0, 0, 0, 0.87)",
//         maxWidth: 220,
//         fontSize: theme.typography.pxToRem(12),
//         border: "1px solid #dadde9"
//     }
// }))(Tooltip);

/**
 *
 * @name CompanyBadges Page
 * @alias CompanyBadges Page
 * @component
 * @exports CompanyBadges Page
 * @description
 * A landing page for CompanyBadges explaining the benefits
 * @example
 *
 */

export default function CompanyBadges() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [badgesData, setBadgesData] = useState({quantity_transacted: 0, transactions_done: 0, age_of_account: 0});

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const queryString = new URLSearchParams(window.location.search)
    const queryString_k = new URLSearchParams(atob(queryString.get('k')))

    const company_id = queryString_k.get('company_id');
    const source_type = queryString_k.get('source_type');

    const whyCoalShastra = [
        {
            "id": 1,
            "media": "../images/Group-3143.svg",
            "title": "Transaction Oriented",
            // "url": "#!",
            "content": "for our prices which go as low as Rs.5 per Ad Credit",
            "childLinkLabel": 'Contact Us',
            "childLink": '/contact',
        },
        {
            "id": 2,
            "media": "../images/Group-3144.svg",
            "title": "Hot Sales Leads",
            // "url": "#!",
            "content": "KYC compliant end consumers with genuine requirements",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 3,
            "media": "../images/Group-3147.svg",
            "title": "Price Discovery",
            // "url": "#!",
            "content": "Market Intelligence and vessel wise pricing",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 4,
            "media": "../images/Group-3146.svg",
            "title": "Pan-India Reach",
            // "url": "#!",
            "content": "Access to clients from across various Indian states",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 5,
            "media": "../images/Group-3148.svg",
            "title": "Personalized Service",
            // "url": "#!",
            "content": "Relationship Managers to give personalized experience",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 6,
            "media": "../images/Group-3149.svg",
            "title": "Transaction Standardization",
            // "url": "#!",
            "content": "All deal related conditions in a single, tamper proof page Quote",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 7,
            "media": "../images/Group-3150.svg",
            "title": "Operations Management",
            // "url": "#!",
            "content": "All your deals & documents managed in one location",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 8,
            "media": "../images/Group-3151.svg",
            "title": "Safety Measures",
            // "url": "#!",
            "content": "Sales Contract between parties with past performance ratings",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 9,
            "media": "../images/Group-3152.svg",
            "title": "Dispute Resolution System",
            // "url": "#!",
            "content": "Impartial, successful method for resolution",
            "childLinkLabel": '',
            "childLink": '',
        },
        {
            "id": 10,
            "media": "../images/Group-3153.svg",
            "title": "Reliable Sourcing",
            // "url": "#!",
            "content": "Fulfill all your needs by getting bulk discounts and flexible payment options",
            "childLinkLabel": '',
            "childLink": '',
        },
    ]

    const fetchBadgesData = async () => {
        setLoading(true);

        try {
            const res = await User.companyBadgesData(company_id, {source_type: source_type});

            if (res.data.status == "success") {
                setBadgesData(res.data.data)
                // setBadgesData({quantity_transacted: 2900, transactions_done: 501, age_of_account: 1.2, rating_data: {rating_comments: ['asdfsdf', 'lskdjfksd'], total_rating: 2.76}})

            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchBadgesData();
    }, []);



    // Transactions -10,25,50,100,250,500
    // Mt - 1000,2500,10k,50k,1L,5L
    // Age 1+,2+,3+,5+

    return (
        <Box>
            <Helmet>
                <title>Company Badges | CoalShastra</title>
                <meta name="description" content="" />
            </Helmet>

            <Box py={4} textAlign="center" className={classes.heading}>
                <Typography component="h1" variant="h5">
                    <strong>Profile Summary and  <br /> Achieved Badges</strong>
                </Typography>
                {/* <Box>
                    <Button
                        style={{
                            height: "40px",
                            width: "155px",
                            fontSize: "16px",
                            fontWeight: "bold"
                        }}
                        disableElevation
                        variant="contained"
                        size="small"
                        className={classes.whiteButton}
                        to="#"
                        component={Link}
                    >
                        GET STARTED
                    </Button>
                </Box> */}
            </Box>
            <Container maxWidth="md">
                <Box className={`${classes.headerCard} ${classes.outline}`} p={2.5}>
                    <Box>
                        <img src="../images/user-icon.svg" width="50" />
                        <Box mb={1.5}>
                            <Typography
                                gutterBottom
                                className={`${classes.bold} capitalize`}>
                                    {badgesData.company_name}
                            </Typography>
                        </Box>
                        <Box className={classes.card_ratingReviews} mb={.75}>
                            <Box component='span' textAlign='left' style={{color: 'goldenrod', marginRight: '4px'}}>
                                {badgesData.rating_data && badgesData.rating_data.total_rating}
                            </Box>
                            <Box component='span' textAlign='center' style={{marginRight: '4px'}}>
                                <Rating
                                    value={(badgesData.rating_data) ? badgesData.rating_data.total_rating : 0}
                                    // value={3.9}
                                    precision={0.5}
                                    readOnly
                                    size='small'
                                />
                            </Box>
                            <Box component='span' textAlign='center'>
                                {badgesData.rating_data &&
                                <Typography variant='body2' color="textSecondary">
                                    {(!badgesData.rating_data.rating_comments || (badgesData.rating_data.rating_comments && badgesData.rating_data.rating_comments.length == 0) ) && 'No Reviews'}
                                    {(badgesData.rating_data.rating_comments && badgesData.rating_data.rating_comments.length == 1) && (badgesData.rating_data.rating_comments.length + ' Review')}
                                    {(badgesData.rating_data.rating_comments && badgesData.rating_data.rating_comments.length > 1) && (badgesData.rating_data.rating_comments.length + ' Reviews')}
                                </Typography>
                                }
                            </Box>
                        </Box>
                    </Box>

                    <Box mb={1} mt={2}>
                        <Typography
                            variant="h5"
                            component="p"
                            gutterBottom
                            className={`${classes.cardTitle} ${classes.bold}`}>
                                My achievements with CoalShastra
                        </Typography>
                    </Box>
                    <Box className={classes.cardContentGrid} textAlign='center' py={1.5} px={0}>
                        <Box>
                            <Box className={classes.statsWrapper}>
                                {/* <img src="../images/badge-green.svg" /> */}
                                {(badgesData.quantity_transacted < 1000) && <img src="../images/badge-gray.svg" /> }
                                {(badgesData.quantity_transacted >= 1000) && <img src="../images/badge-green.svg" /> }
                                <Box className={`${classes.stats} ${classes.bold} text-white`}>
                                    {(badgesData.quantity_transacted < 1000) && '1K' }
                                    {(badgesData.quantity_transacted >= 1000 && badgesData.quantity_transacted < 2500) && '1K'}
                                    {(badgesData.quantity_transacted >= 2500 && badgesData.quantity_transacted < 10000) && '2.5K'}
                                    {(badgesData.quantity_transacted >= 10000 && badgesData.quantity_transacted < 50000) && '10K'}
                                    {(badgesData.quantity_transacted >= 50000 && badgesData.quantity_transacted < 100000) && '50K'}
                                    {(badgesData.quantity_transacted >= 100000 && badgesData.quantity_transacted < 200000) && '1L'}
                                    {(badgesData.quantity_transacted >= 200000 && badgesData.quantity_transacted < 500000) && '2L'}
                                    {(badgesData.quantity_transacted >= 500000 && badgesData.quantity_transacted < 1000000) && '5L'}
                                    {(badgesData.quantity_transacted >= 1000000) && '1M'}
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant='body2' className={classes.textDark}>Quantity <br />Transacted <br />(MT) </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={classes.statsWrapper}>
                                {/* <img src="../images/badge-yellow.svg" /> */}
                                {(badgesData.transactions_done < 10) && <img src="../images/badge-gray.svg" /> }
                                {(badgesData.transactions_done >= 10) && <img src="../images/badge-yellow.svg" /> }
                                <Box className={`${classes.stats} ${classes.bold} text-white`}>
                                    {(badgesData.transactions_done < 10) && '10' }
                                    {(badgesData.transactions_done >= 10 && badgesData.transactions_done < 25) && '10'}
                                    {(badgesData.transactions_done >= 25 && badgesData.transactions_done < 50) && '25'}
                                    {(badgesData.transactions_done >= 50 && badgesData.transactions_done < 100) && '50'}
                                    {(badgesData.transactions_done >= 100 && badgesData.transactions_done < 250) && '100'}
                                    {(badgesData.transactions_done >= 250 && badgesData.transactions_done < 500) && '250'}
                                    {(badgesData.transactions_done >= 500) && '500'}
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant='body2' className={classes.textDark}>Transactions <br />Done</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={classes.statsWrapper}>
                                {/* <img src="../images/badge-blue.svg" /> */}
                                {(badgesData.age_of_account < 1) && <img src="../images/badge-gray.svg" /> }
                                {(badgesData.age_of_account >= 1) && <img src="../images/badge-blue.svg" /> }
                                <Box className={`${classes.stats} ${classes.bold} text-white`}>
                                    {(badgesData.age_of_account < 1) && '1+' }
                                    {(badgesData.age_of_account >= 1 && badgesData.age_of_account < 2) && '1+'}
                                    {(badgesData.age_of_account >= 2 && badgesData.age_of_account < 3) && '2+'}
                                    {(badgesData.age_of_account >= 3 && badgesData.age_of_account < 5) && '3+'}
                                    {(badgesData.age_of_account >= 5) && '5+'}
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant='body2' className={classes.textDark}>Age of <br />Account <br />(Years)</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box mt={4} mb={2}>
                        <Typography
                            variant="h5"
                            component="h2"
                            className={`${classes.bold}`}
                        >
                            Why CoalShastra?
                        </Typography>
                    </Box>

                    <Grid container spacing={2}>
                        {whyCoalShastra && whyCoalShastra.map((data, index) => (
                            <Grid item xs={6} key={index}>
                                <Box mb={2}>
                                    <Card className={classes.whyCoalShastra}>
                                        <CardContent>
                                            <img src={data.media} width="45" />
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    className={`${classes.cardTitle} ${classes.bold}`}
                                                >
                                                    {data.title}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography color="textSecondary" gutterBottom>
                                                    {data.childLink !== '' &&
                                                        <Link to={data.childLink}>
                                                            {data.childLinkLabel}
                                                        </Link>
                                                    } {' '} {data.content}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        <Box className={classes.btnContainer}>
                            <Button
                                style={{ height: "48px" }}
                                variant="contained"
                                color="primary"
                                fullWidth
                                to="#"
                                component={Link}
                            >
                                GET STARTED
                            </Button>
                        </Box>
                    </Grid>

                    {/* FAQs */}

                    <Box mt={4} mb={2}>
                        <Typography
                            variant="h5"
                            component="h2"
                            className={`${classes.bold}`}
                        >
                            FAQ
                        </Typography>
                    </Box>
                    <Box my={3}>
                        <Accordion
                            expanded={expanded === "FAQ_1"}
                            onChange={handleChange("FAQ_1")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography>
                                    Is it free to make an account with
                                    CoalShastra?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes, it is free to form your company’s
                                    account with CoalShastra. This will enable
                                    you to start exploring and chatting with
                                    other member companies.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "FAQ_2"}
                            onChange={handleChange("FAQ_2")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography>
                                    Is it free to transact on CoalShastra
                                    marketplace?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    No. It is a paid service. To know more
                                    please <Link to="/contact">contact us</Link>{" "}
                                    here.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "FAQ_3"}
                            onChange={handleChange("FAQ_3")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography>
                                    How to create an account?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Typography>
                                        It is super easy to create an account
                                        with by filling these 3 easy steps
                                    </Typography>
                                    <ul>
                                        <li>Input your name</li>
                                        <li>Verify your mobile number</li>
                                        <li>Verify your email address</li>
                                    </ul>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "FAQ_4"}
                            onChange={handleChange("FAQ_4")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography>
                                    What are the documents needed to add your
                                    company?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    A company needs its GSTIN number issued by
                                    the government of India.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "FAQ_5"}
                            onChange={handleChange("FAQ_5")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography>
                                    Is there any verification of member
                                    companies?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes each company has to first be verified by
                                    inputting their GST numbers for
                                    verification.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === "FAQ_6"}
                            onChange={handleChange("FAQ_6")}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography>
                                    How to boost your coal business?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    There are two primary ways to start using
                                    CoalShastra
                                </Typography>
                                <ul>
                                    <li>
                                        By listing your products for wider reach
                                        and getting incoming leads.
                                    </li>
                                    <li>
                                        By interacting with buyers on their
                                        requirements and making your offers.
                                    </li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    {/* Customer review */}
                    <Box mt={4} mb={2}>
                        <Typography
                            variant="h5"
                            component="h2"
                            className={`${classes.bold}`}
                        >
                            Customer's Review
                        </Typography>
                        <Box my={2} className={classes.review}>
                            <Card className={classes.reviewCard}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="customer name"
                                            className={classes.cardButton}
                                        >
                                            V
                                        </Avatar>
                                    }
                                    title="Vikas Garg"
                                    // subheader="Coal Dealer in Gujrat"
                                />

                                <CardContent>
                                    <Typography>
                                    I have done several transactions with them and am satisfied with their services. Best of luck to Coalshastra's team!
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card className={classes.reviewCard}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="customer name"
                                            className={classes.cardButton}
                                        >
                                            M
                                        </Avatar>
                                    }
                                    title="Manish Jain"
                                    // subheader="Coal Dealer in Gujrat"
                                />

                                <CardContent>
                                    <Typography>
                                    Understand the need of customer and gives the rate after thorough try from market.Follow up after transaction is satisfactory
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Card className={classes.reviewCard}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="customer name"
                                            className={classes.cardButton}
                                        >
                                            M
                                        </Avatar>
                                    }
                                    title="Mahi Desai"
                                    // subheader="Coal Dealer in Gujrat"
                                />

                                <CardContent>
                                    <Typography>
                                    Saved us lot of money. Got lot of good deals. Plus security by frauds. I would recommend coalshastra for your coal business.
                                    </Typography>
                                </CardContent>
                            </Card>

                            {/* <Card className={classes.reviewCard}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="customer name"
                                            className={classes.cardButton}
                                        >
                                            S
                                        </Avatar>
                                    }
                                    title="Sunakshi gupta"
                                    // subheader="Coal Dealer in Gujrat"
                                />

                                <CardContent>
                                    <Typography>
                                    We have used coalshastra platform...being a small trading company they helped us grow at a very rapid pace! I highly recommend people to connect with coalshastra so they can take advantage of their network and resources which is spread across globe.

                                    Thank you team coalshastra for your endless cooperation and support.
                                    </Typography>
                                </CardContent>
                            </Card> */}
                        </Box>
                    </Box>

                    {/* CTA */}
                    <Box mt={4} mb={2}>
                        <Box mb={2}>
                            <Typography
                                variant="h5"
                                component="h2"
                                className={`${classes.bold}`}
                            >
                                You can connect with us
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid item xs={12} sm>
                                <Box mb={2} mx={1}>
                                    <Button
                                        startIcon={<EmailIcon />}
                                        className={classes.cardButton}
                                        fullWidth
                                        size="medium"
                                        style={{textTransform: 'lowercase'}}
                                    >
                                        info@coalshastra.com
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm>
                                <Box mx={1}>
                                    <Button
                                        startIcon={<PhoneIcon />}
                                        className={classes.cardButton}
                                        fullWidth
                                        size="medium"
                                    >
                                        +91-80-10-20-1757
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
