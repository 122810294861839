import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { InputLabel, Box, MenuItem } from "@mui/material";
import { SelectValidator } from "react-material-ui-form-validator";
import LanguageTranslate from "../../partials/UnderScoreLanguage";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
}));

/**
 *
 * @name Expiry
 * @alias Expiry
 * @component
 * @exports Expiry
 *
 * @description
 * Expiry input field
 *
 */

export default function Expiry(props) {
  //console.log(props.expiry)
  const classes = useStyles();
  return (
    <Box>
      <InputLabel className={classes.marginBottom}>
        <LanguageTranslate s="Expiry" />
        <sup style={{ color: "red" }}>*</sup>
      </InputLabel>
      <SelectValidator
        native="true"
        variant="outlined"
        name="expiry"
        //disabled={props.marketData.is_auction == true ? true : false}
        fullWidth
        size="small"
        validators={["required"]}
        errorMessages={["Expiry field is required"]}
        value={props.expiry}
        onChange={(e) => {
          props.setExpiry(e.target.value);
        }}
      >
        {props.marketData.is_auction == true && (
          <MenuItem value={0.15}>15 Minutes</MenuItem>
        )}
        <MenuItem value={0.5}>30 Minutes</MenuItem>
        <MenuItem value={1}>1 Hour</MenuItem>
        <MenuItem value={2}>2 Hours</MenuItem>
        <MenuItem value={4}>4 Hours</MenuItem>
        <MenuItem value={8}>8 Hours</MenuItem>
        <MenuItem value={24}>Today</MenuItem>
        <MenuItem value={48}>2 Days</MenuItem>
        <MenuItem value={7}>7 Days</MenuItem>
        <MenuItem value={15}>15 Days</MenuItem>
        <MenuItem value={30}>30 Days</MenuItem>
      </SelectValidator>
    </Box>
  );
}
