import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    Grid,
    Box,
    InputAdornment,
    InputLabel,
    TextField
} from "@mui/material";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
}));

/**
 *
 * @name AdvanceBalance
 * @alias AdvanceBalance
 * @component
 * @exports AdvanceBalance
 *
 * @description
 * Advance balance input field
 *
 */

export default function AdvanceBalance(props) {
    const classes = useStyles();

    useEffect(() => {
        var totalBalance = 100;
        props.setBalance(totalBalance - props.advance);
    }, [props.advance]);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <InputLabel className={`${classes.marginBottom}`}>Advance
                    </InputLabel>
                    <TextField
                        className={classes.backgroundWhite}
                        variant="outlined"
                        type="number"
                        fullWidth
                        name="num_advance"
                        value={props.advance}
                        onChange={e => { props.setAdvance(e.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                >
                                    %
                                </InputAdornment>
                            )
                        }}
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <InputLabel className={`${classes.marginBottom}`}>Balance
                    </InputLabel>
                    <TextField
                        variant="outlined"
                        type="number"
                        fullWidth
                        name="num_balance"
                        value={props.balance}
                        onChange={e => { props.setBalance(e.target.value) }}
                        disabled
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                >
                                    %
                                </InputAdornment>
                            )
                        }}
                        size="small"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
