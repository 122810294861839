import React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useNavigate, useLocation, Link, useParams, useSearchParams } from 'react-router-dom'
import useMediaQuery from "@mui/material/useMediaQuery";
import eventsTracking from '../../eventsTracking';
import { uniqueId } from "lodash";
import Hidden from '@mui/material/Hidden';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, InputAdornment, TextField, Card, Button, IconButton, Dialog, Slide, Rating, CardContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ErrorHandler from '../partials/ErrorHandler'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { Helmet } from 'react-helmet';
// import bugReportIcon from '../../../images/bugReportIcon.svg'
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { User } from "../../api/resources/User"
import { debounce } from 'lodash';
import { sellerColors, buyerColors } from '../../theme';
import CardLoader from '../partials/content-loaders/CardLoader';
import { fireCompanyNotAddedEvent, calculatePriceRange, searchInJson } from '../../helpers'
import StarIcon from '@mui/icons-material/Star';
import "react-input-range/lib/css/index.css"
import RefreshIcon from '@mui/icons-material/Refresh';
import BuySell from './BuySell';
import MarketCard from './MarketCard';
import MarketFilter from './MarketFilter';
import MarketPrices from './MarketPrices';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import loadable from "@loadable/component";
import { Product } from '../../api/resources/Product';
import BottomTabsNav from './BottomTabsNav';
import Tour from 'reactour'
import CompanyBadges from '../pages/CompanyBadges';
import Badges from '../partials/Badges'
import ReactGA from "react-ga4";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import {
    setLoginModal,
    setSignupModal
} from "../../redux/actions/commonActions";
import {
    AccountCircle,
} from "@mui/icons-material";
import LoginForm from '../partials/LoginForm';
import SignUp from '../signupV2/SignUp';

const ChatModal = loadable(() => {
    return import(
        /* webpackChunkName: "js/chunks/partials-ChatModal" */ "../partials/ChatModalV3"
    );
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentMethodList = [
    { id: 1, name: "Advance" },
    // { id: 2, name: "Letter of Credit" },
    // { id: 3, name: "Credit" },
    { id: 4, name: "Cash & Carry" }
]

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}


const useStyles = makeStyles(theme => ({
    tabContainer: {
        '& .MuiTabs-flexContainer': {
            alignItems: 'center'
        }
    },
    root: {
        '& .buyerTheme-Tabs .MuiTab-textColorPrimary.Mui-selected': {
            color: buyerColors.primary.main
        },
        '& .sellerTheme-Tabs .MuiTab-textColorPrimary.Mui-selected': {
            color: sellerColors.primary.main
        },
    },
    tabItems: {
        '& .MuiTab-textColorPrimary.Mui-selected': {
            fontWeight: 'bold',
        },
    },
    mb: {
        marginBottom: theme.spacing(1.5)
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    statsWrapper: {
        position: 'relative',
    },
    searchSelect: {
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: '5px'
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: '#ffffff'
        },
        fontSize: '14px',
        marginTop: '15px',
    },
    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr .1fr",
        alignItems: 'center',
        gridGap: '10px',
        "& .MuiOutlinedInput-root": {
            backgroundColor: '#ffffff'
        },
        '& .MuiButton-root': {
            marginRight: "5px",
            fontSize: 12,
            minWidth: '40px',
            minHeight: '40px',
            color: '#cccccc',
            backgroundColor: '#ffffff',
            [theme.breakpoints.down('md')]: {
                fontSize: 11,
            },

        }
    },
    btnSpacingX: {
        marginRight: theme.spacing(1)
    },
    fs16: {
        fontSize: '16px',
    },
    fs14: {
        fontSize: '14px',
    },
    faded: {
        position: 'relative',
        '&:after': {
            content: "''",
            position: 'absolute',
            backgroundColor: '#ffffff2e',
            backdropFilter: 'blur(2px)',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        }
    },
    heading: {
        // fontSize: "3rem",
        paddingTop: theme.spacing(4),
        paddingBottom: "118px",
        backgroundColor: "#3f51b5",
        color: theme.palette.common.white,
        borderBottomLeftRadius: "25px",
        borderBottomRightRadius: "25px",
        //backgroundImage: `url(${wave})`,
        backgroundPosition: "0 99px",
        backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
        [theme.breakpoints.up("sm")]: {
            backgroundSize: "cover"
        }
    },
    card_ratingReviews: {
        display: "flex",
        // gridTemplateColumns: "25px 100px 120px",
    },
    outline: {
        // boxShadow: '0px 0px 0px 2.25px #7083f0 inset'
        boxShadow: '10px 10px 10px 2.25px #eaeaea',
        border: '1px solid #d6d6d6'
    },
    bold: {
        fontWeight: "bold"
    },
    headerCard: {
        marginTop: "32px",
        backgroundColor: theme.palette.common.white,
        borderRadius: 8,
    },
    textMuted: {
        color: "#6A6A6A",
        fontSize: 16,
        fontWeight: "bold"
    },
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const UserPlanIcon = ({ user }) => {
    return user?.active_revenueable_invoice_consumption == null ? (
        <span title={"UnPaid User"}>
            <AccountCircle
                size="small"
                color="primary"
                style={{ fontSize: "10rem" }}
            />
        </span>
    ) : (
        <img
            src={user?.active_revenueable_invoice_consumption?.plan?.icon_src}
            width={150}
            title={
                user?.active_revenueable_invoice_consumption?.plan?.title +
                " User"
            }
            alt={
                user?.active_revenueable_invoice_consumption?.plan?.title +
                " User"
            }
        />
    );
};

export default function AboutYou(props) {
   
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const location = useLocation();
    const routeParams = useParams()
    const query = useQuery();
    let { slug } = useParams();
    console.log('slug', slug);
    const queryStringContractCode = query.get('contract_code')

    const matchesViewport = useMediaQuery("(max-width:600px)");
    const user = useSelector(state => state.userReducer.user);
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn)
    const userReducer = useSelector(state => state.userReducer);
    const user_mode = useSelector(state => state.userReducer.user_mode)
    const [keyword, keywordSet] = React.useState('');
    const [value, setValue] = React.useState('');
    const [selectedItem, setSelectedItem] = React.useState(null);
    // const [marketplaceId, setMarketplaceId] = React.useState(null);
    const [marketList, setMarketList] = React.useState([]);
    const [marketListLoading, setMarketListLoading] = React.useState(false);
    const debouncedHandler = React.useCallback(debounce((f, v) => { f(v) }, 600), []);
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const [marketplaceId, setMarketplaceId] = React.useState("");
    const [marketplaceCode, setMarketplaceCode] = React.useState("");
    // const [country, countrySet] = React.useState([]);
    const [loadingCountries, setLoadingCountries] = React.useState(false);
    const [countries, countriesSet] = React.useState([]);
    const [currency, currencySet] = React.useState(user?.country_mobile_prefix == '+91' ? 'INR' : user?.country_mobile_prefix == '+1' ? 'USD' : '');
    const [currencyStatus, currencyStatusSet] = React.useState(1);
    const [loadingVessel, loadingVesselSet] = React.useState(false);
    const [showBuySell, showBuySellSet] = React.useState(false);
    const [allVessels, allVesselsSet] = React.useState([]);
    const [vessels, vesselsSet] = React.useState([]);
    const [paymentMethod, setPaymentMethod] = React.useState([]);
    const [filter, setFilter] = React.useState(false);
    const [showFilterDialog, setShowFilterDialog] = React.useState(false);
    const [showDetailsDialog, setShowDetailsDialog] = React.useState(false);
    const [rangeValue, rangeValueSet] = React.useState('');
    const [advance, setAdvance] = React.useState(0);
    const [balance, setBalance] = React.useState("");
    const [numberOfCreditDays, setNumberOfCreditDays] = React.useState("");
    const [marketDetails, marketDetailsSet] = React.useState(false);
    const [vesselPriceBuyer, vesselPriceBuyerSet] = React.useState([]);
    const [vesselPriceSeller, vesselPriceSellerSet] = React.useState([]);
    const [vesselInfo, vesselInfoSet] = React.useState({});
    const [vesselType, vesselTypeSet] = React.useState("");
    const [portId, portIdSet] = React.useState("");
    const [lastTradedPrice, lastTradedPriceSet] = React.useState("");
    const [loadingVesselPrice, loadingVesselPriceSet] = React.useState(false);
    const [marketData, marketDataSet] = React.useState({});
    const [marketAttrData, marketAttrDataSet] = React.useState({});
    const [contractAttribute, contractAttributeSet] = React.useState({});
    const defaultPriceRange = { min: 0, max: 0, avg: 0 };
    const [priceRange, priceRangeSet] = React.useState(defaultPriceRange);
    const [price, setPrice] = React.useState('');
    const [chatData, setChatData] = React.useState({});
    const [tabValue, setTabValue] = React.useState(user.user_type == 1 ? "buyer" : "seller");
    const [auctionFinished, setAuctionFinished] = React.useState(false);
    const [refreshBestBuyerSeller, setRefreshBestBuyerSeller] = React.useState(false);
    const [marketDetailsMob, marketDetailsMobSet] = React.useState(false);
    const [minimumOrderQuantity, setMinimumOrderQuantity] = React.useState("");
    const [priceTickSize, setPriceTickSize] = React.useState("");
    const [quantityTickSize, setQuantityTickSize] = React.useState("");
    const [quantityRangeMax, setQuantityRangeMax] = React.useState("");
    const [quantityRangeMin, setQuantityRangeMin] = React.useState("");
    const [isValidPrice, setIsValidPrice] = React.useState(true);
    const [isValidQuantity, setIsValidQuantity] = React.useState(true);
    const [brandStoreUserData, brandStoreUserDataSet] = React.useState('');
    const [apiErrorResponse, setApiErrorResponse] = React.useState(null);
    const [openDialog, handleDisplay] = React.useState(false);
    const userData = useSelector((state) => state.userReducer.user);
    console.log(userData?.brand_store_slug);
    React.useEffect(() => {
        if(!isLoggedIn){
            User.guestBrandStore(userData?.brand_store_slug)
            .then(response => {
                // console.log(response.data.data)
                brandStoreUserDataSet(response.data.data.user);
                // debouncedHandler(fetchMarketList(response.data.data.user?.brand_store_company_id));
            })
            .catch(error => {
                setApiErrorResponse(error.data.message);
            })
            .finally(() => { });
        }else{
            User.brandStore(userData?.brand_store_slug)
            .then(response => {
                // console.log(response.data.data)
                brandStoreUserDataSet(response.data.data.user);
                // debouncedHandler(fetchMarketList(response.data.data.user?.brand_store_company_id));
            })
            .catch(error => {
                setApiErrorResponse(error.data.message);
            })
            .finally(() => { });
        }
        
      

         
    }, []);


    React.useEffect(() => {
        dispatch({ type: 'LAST_SELECTED_BRANDSTORE', payload: slug });
        saveCount()
    }, []);

   
    // React.useEffect(() => {
    //     if (props.isLoggedIn) {
    //         props.setLoginModal(true);
    //         props.setSignupModal(true);
    //     }
    // }, [props.isLoggedIn]);
    const saveCount = async () => {
        try {
            const res = await User.saveRouteCount({
                route_name: 'brandstore_visit',
                model_id:user?.id,
                model_type: 6,
                
            })
            if (res.data.status == 'success') {
                console.log(res.data)
            }
        }
        catch (error) {
            console.log(error)
        }
       
    }

    const handleChange = (event, newValue) => {
        // setValue(parseInt(newValue));
        if (newValue == 'bookmarked') {
            setValue(newValue);
            setMarketplaceId(newValue);
        }
        else {
            setValue(parseInt(newValue));
            setMarketplaceId(parseInt(newValue));
        }
        marketDetailsSet(false);
    };

    const [isTourOpen, isTourOpenSet] = React.useState(false);

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

    const steps = [
        {
            selector: '#pageHeading',
            content: 'This is my first Step',
        },
        {
            selector: '.contract-0',
            content: 'First contract',
        },
        {
            selector: '.contractPrice',
            content: 'Contract Price',
        },
        {
            selector: '.addListing',
            content: 'Add Listing',
        },
    ]

    /**
     * @function getCountries
     * @name getCountries
     * @description
     * An async function to fetch the all the countires
     *
     * @async
     */

    // const fetchCountries = async (params) => {
    //     setLoadingCountries(true)
    //     var params = Object.assign({
    //         currency_status: 1
    //     })
    //     try {
    //         const res = await Product.getCountries(params);
    //         if (res.data.status == 'success') {
    //             countriesSet(res.data.data)
    //         }
    //     }
    //     catch (e) {
    //         console.error(e)
    //     }
    //     setLoadingCountries(false)
    // }

    // React.useEffect(() => {
    //     fetchCountries()
    // }, [])

    // React.useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [loadingVesselPrice])

    const toggleDrawer = (newOpen) => () => {
        setShowDetailsDialog(newOpen);
    };

    const fetchMarketList = async (id) => {
        setMarketListLoading(true);
        if(!isLoggedIn){
            try {
                const res = await User.guestGetBrandStoreMarketList({
                    visibility: 1,
                    brand_store_company_id: id
                })
                if (res.data.status == 'success') {
                    const lists = res.data.data &&
                        res.data.data.filter(e => {
                            return e.user_preference !== null;
                        });
    
                    if (userReducer && userReducer.noCompany) {
                        setMarketList(lists.slice(0, 5));
                    }
                    else {
                        setMarketList(lists.slice(0, 10));
                    }
    
                    if (location.hash) {
    
                        if (((location.hash).slice(1) == "bookmarked")) {
                            setValue("bookmarked");
                            setMarketplaceId("bookmarked");
                        } else {
                            setValue(parseInt(((location.hash).split('#'))[1]));
                            setMarketplaceId(parseInt(((location.hash).split('#'))[1]));
                        }
                    } else {
                        const querymarket_code = query.get('market_code')
                        if (query && querymarket_code) {
                            const seleMark = lists.find(m => m.market_code.toUpperCase() == querymarket_code.toUpperCase())
                            if (seleMark) {
                                setValue(parseInt(seleMark.id));
                                setMarketplaceId(parseInt(seleMark.id));
                            }
                            else {
                                setValue(lists.length > 0 && parseInt(lists[0].id));
                                setMarketplaceId(lists.length > 0 && parseInt(lists[0].id));
                            }
                        }
                        else {
                            setValue(lists.length > 0 && parseInt(lists[0].id));
                            setMarketplaceId(lists.length > 0 && parseInt(lists[0].id));
                        }
                    }
    
                }
            }
            catch (e) {
                setMarketListLoading(false)
                console.error(e)
            }
            setMarketListLoading(false)
        }else{
            try {
                const res = await User.getBrandStoreMarketList({
                    visibility: 1,
                    brand_store_company_id: id
                })
                if (res.data.status == 'success') {
                    const lists = res.data.data &&
                        res.data.data.filter(e => {
                            return e.user_preference !== null;
                        });
    
                    if (userReducer && userReducer.noCompany) {
                        setMarketList(lists.slice(0, 5));
                    }
                    else {
                        setMarketList(lists.slice(0, 10));
                    }
    
                    if (location.hash) {
    
                        if (((location.hash).slice(1) == "bookmarked")) {
                            setValue("bookmarked");
                            setMarketplaceId("bookmarked");
                        } else {
                            setValue(parseInt(((location.hash).split('#'))[1]));
                            setMarketplaceId(parseInt(((location.hash).split('#'))[1]));
                        }
                    } else {
                        const querymarket_code = query.get('market_code')
                        if (query && querymarket_code) {
                            const seleMark = lists.find(m => m.market_code.toUpperCase() == querymarket_code.toUpperCase())
                            if (seleMark) {
                                setValue(parseInt(seleMark.id));
                                setMarketplaceId(parseInt(seleMark.id));
                            }
                            else {
                                setValue(lists.length > 0 && parseInt(lists[0].id));
                                setMarketplaceId(lists.length > 0 && parseInt(lists[0].id));
                            }
                        }
                        else {
                            setValue(lists.length > 0 && parseInt(lists[0].id));
                            setMarketplaceId(lists.length > 0 && parseInt(lists[0].id));
                        }
                    }
    
                }
            }
            catch (e) {
                setMarketListLoading(false)
                console.error(e)
            }
            setMarketListLoading(false)
        }
       
    }

    // React.useEffect(() => {
    //     debouncedHandler(fetchMarketList);
    // }, [searchKeyword]);

    React.useEffect(() => {

        if (marketList && marketList.length > 0) {
            const seleMark = marketList.find(m => m.id == marketplaceId)
            if (seleMark) {
                setMarketplaceCode(seleMark.market_code)
            }
        }
    }, [marketplaceId, marketList])

    const getBestListingOfferRFQ = async params => {

        var formData = new FormData();
        setSelectedItem(params.contractAttribute.id);
        formData.append("vessel_attribute_id", params.contractAttribute.id);
        formData.append("type", params.vesselData.type);
        formData.append("limit", 999);
        formData.append("visibility", 1);
        formData.append("brand_store_company_id", brandStoreUserData?.brand_store_company_id);
        formData.append("cc_percentage_start", advance == 0 ? '' : advance);
        formData.append("check_mutual_contacts", 1);
        formData.append("cc_percentage_end", '');
        formData.append("num_credit_days", numberOfCreditDays);
        formData.append("lifting_day_start", rangeValue?.min ? rangeValue?.min : '');
        formData.append("lifting_day_end", rangeValue?.max ? rangeValue?.max : '');
        paymentMethod.map(p => {
            formData.append("payment_term_ids[]", p.id)
        });

        if (params.silentLoading == undefined) {
            loadingVesselPriceSet(true)
        }

        try {
            const res = await Product.bestListingOfferRFQ(formData);

            if (res.data.status == "success") {
                vesselPriceBuyerSet(res.data.data.buyer);
                vesselPriceSellerSet(res.data.data.seller);
                vesselInfoSet(res.data.data.vessel_info);

                var bestPrices = [];
                if (res.data.data.buyer && res.data.data.buyer.length > 0 && res.data.data.buyer[0] && res.data.data.buyer[0].price_value) {
                    bestPrices.push(res.data.data.buyer[0].price_value)
                }
                if (res.data.data.seller && res.data.data.seller.length > 0 && res.data.data.seller[0] && res.data.data.seller[0].price_value) {
                    bestPrices.push(res.data.data.seller[0].price_value)
                }

                let listingRangeCap = 25
                if (res.data.data.listing_percentage && res.data.data.listing_percentage.value) {
                    listingRangeCap = res.data.data.listing_percentage.value
                }
                priceRangeSet(calculatePriceRange(bestPrices, (listingRangeCap / 100)))


            }
        } catch (e) {
            console.error(e);
        }
        loadingVesselPriceSet(false)
    };



    const fetchMarketplaceTabs = async (params) => {
        loadingVesselSet(true);
        keywordSet("");
        allVesselsSet([]);

        vesselsSet([]);

        var formData = new FormData();
        formData.append("is_bookmarked", marketplaceId == "bookmarked" ? 1 : '');
        formData.append("mode", 3);
        formData.append("limit", 99);
        formData.append("visibility", 1);
        formData.append("brand_store_company_id", brandStoreUserData?.brand_store_company_id);
        formData.append("marketplace_id", marketplaceId == "bookmarked" ? '' : marketplaceId);
        formData.append("currencyKeyword", currency == null ? '' : currency);

        if (filter == true && paymentMethod.map(p => p.id).length == 0) {
            formData.append("cc_percentage_end", '');
            formData.append("lifting_day_start", rangeValue?.min ? rangeValue?.min : '');
            formData.append("lifting_day_end", rangeValue?.max ? rangeValue?.max : '');
        }


        if (paymentMethod.map(p => p.id).length > 0 && filter == true) {
            paymentMethod.map(p => {
                formData.append("payment_term_ids[]", p.id);
                if (p.id == 4) {
                    formData.append("cc_percentage_start", advance == 0 ? '' : advance);
                }
            });
        }
        if(!isLoggedIn){
            try {
                const res = await User.guestMarketplaceTabsBrandStorePost(formData)
                if (res.data.status == 'success') {
                    if (res.data.data && res.data.data.length > 0) {
                        selectContractCard(res.data?.data[0], res.data?.data[0]?.vessel_attributes.filter(e => {
                            return e.multiple_products.length > 0;
                        })[0]);
                    }
    
                    if (userReducer && userReducer.noCompany) {
                        vesselsSet(res.data.data.slice(0, 1));
                        //vesselsSet(res.data.data);
                    }
                    else {
                        vesselsSet(res.data.data);
                    }
    
    
                    allVesselsSet(res.data.data);
    
                    // if (res.data.data.length > 0) {
                    //     setSelectedItem(res.data.data[0]?.vessel_attribute_oldest.id);
                    //     marketDetailsSet(true);
                    //     setRefreshBestBuyerSeller(true);
                    //     setShowDetailsDialog(true);
                    //     vesselTypeSet(res.data.data[0]?.type);
                    //     portIdSet(res.data.data[0]?.vessel_attribute_oldest?.coal_port_id);
                    //     marketDataSet(res.data.data[0]);
                    //     marketAttrDataSet(res.data.data[0]?.vessel_attributes[0]);
                    //     lastTradedPriceSet(res.data.data[0]?.find_prices && res.data.data[0]?.find_prices?.last_trade_price);
                    // }
                    // if (matchesViewport) {
                    //     setFirstSelectedItem(res.data.data.length > 0 && res.data.data[0].vessel_attribute_oldest.id);
                    // }
                    //isTourOpenSet(true)
                }
                loadingVesselSet(false);
            }
            catch (e) {
                loadingVesselSet(false);
                console.error(e)
            }
            loadingVesselSet(false);
        }else{
            try {
                const res = await User.marketplaceTabsBrandStorePost(formData)
                if (res.data.status == 'success') {
                    if (res.data.data && res.data.data.length > 0) {
                        selectContractCard(res.data?.data[0], res.data?.data[0]?.vessel_attributes.filter(e => {
                            return e.multiple_products.length > 0;
                        })[0]);
                    }
    
                    if (userReducer && userReducer.noCompany) {
                        vesselsSet(res.data.data.slice(0, 1));
                        //vesselsSet(res.data.data);
                    }
                    else {
                        vesselsSet(res.data.data);
                    }
    
    
                    allVesselsSet(res.data.data);
    
                    // if (res.data.data.length > 0) {
                    //     setSelectedItem(res.data.data[0]?.vessel_attribute_oldest.id);
                    //     marketDetailsSet(true);
                    //     setRefreshBestBuyerSeller(true);
                    //     setShowDetailsDialog(true);
                    //     vesselTypeSet(res.data.data[0]?.type);
                    //     portIdSet(res.data.data[0]?.vessel_attribute_oldest?.coal_port_id);
                    //     marketDataSet(res.data.data[0]);
                    //     marketAttrDataSet(res.data.data[0]?.vessel_attributes[0]);
                    //     lastTradedPriceSet(res.data.data[0]?.find_prices && res.data.data[0]?.find_prices?.last_trade_price);
                    // }
                    // if (matchesViewport) {
                    //     setFirstSelectedItem(res.data.data.length > 0 && res.data.data[0].vessel_attribute_oldest.id);
                    // }
                    //isTourOpenSet(true)
                }
                loadingVesselSet(false);
            }
            catch (e) {
                loadingVesselSet(false);
                console.error(e)
            }
            loadingVesselSet(false);
        }
        
    }

    const filterByKeyword = () => {
        vesselsSet(searchInJson(keyword, allVessels));
    };

    const selectContractCard = (contract, contractAttribute) => {
        const vesselId = contract?.vessel_attribute_oldest.id
        const vesselAttrData = contract?.vessel_attribute_oldest
        //console.log(contractAttribute)
        setSelectedItem(contractAttribute?.id);
        marketDetailsSet(true);
        setRefreshBestBuyerSeller(true);
        setShowDetailsDialog(true);
        vesselTypeSet(contract?.type);
        portIdSet(contract?.vessel_attribute_oldest?.coal_port_id);
        marketDataSet(contract);
        marketAttrDataSet(contract?.vessel_attributes[0]);
        lastTradedPriceSet(contract?.find_prices?.last_trade_price);
        contractAttributeSet(contractAttribute);
        marketDetailsSet(true);

        getBestListingOfferRFQ({ vesselData: contract, vesselAttrData: vesselAttrData, contractAttribute: contractAttribute })
        setShowDetailsDialog(true);
        vesselTypeSet(contract.type);
        portIdSet(contract?.vessel_attribute_oldest?.coal_port_id);

        marketAttrDataSet(vesselAttrData);
        lastTradedPriceSet(contract.find_prices && contract.find_prices.last_trade_price);
        setMinimumOrderQuantity(contract.min_order_qty);
        setPriceTickSize(contract.tick_size);
        setQuantityTickSize(contract.qty_tick_size);
        setQuantityRangeMax(contract.price_range_max);
        setQuantityRangeMin(contract.price_range_min);
        setRefreshBestBuyerSeller(false);
        //setPrice("");
        marketDetailsMobSet(false);
        eventsTracking.contractDetailOpened();
    }


    React.useEffect(() => {
        if (marketAttrData && marketData && marketData.is_auction == true) {
           // console.log(marketData)
            let interval = setInterval(() => {
                getBestListingOfferRFQ({ vesselData: marketData, vesselAttrData: marketAttrData, contractAttribute: contractAttribute, silentLoading: true })
            },
                1000 * 10
                // 1000 * 60*2
            )

            return () => clearInterval(interval)

        }

    }, [marketData, marketAttrData, contractAttribute])

    React.useEffect(() => {
        if (marketList && marketList.length > 0 && vessels && vessels.length > 0 && queryStringContractCode && queryStringContractCode.length > 0) {
            const seleContr = vessels.find(m => m.vessel_code == queryStringContractCode)
            if (seleContr) {
                selectContractCard(seleContr)
            }
        }
    }, [vessels, marketList, location, queryStringContractCode])

    React.useEffect(() => {
        var totalBalance = 100;
        setBalance(totalBalance - parseInt(advance));
        if (marketplaceId) {
            debouncedHandler(fetchMarketplaceTabs);
        }
    }, [marketplaceId, paymentMethod, rangeValue, filter, advance, balance, currency]);


    React.useEffect(() => {
        if (keyword.trim().length === 0) {
            vesselsSet(allVessels);
        } else {
            debouncedHandler(filterByKeyword);
        }
    }, [
        keyword
    ]);

    const MetaTags = () => {
        //console.log(marketData)
        return <Helmet>
            <title>Brand Store - {brandStoreUserData?.brand_store_company?.name} | CoalShastra</title>
            {(brandStoreUserData && brandStoreUserData?.brand_store_company) && <meta name="description" content={`${brandStoreUserData?.brand_store_company?.name}`} />}
            {/* <meta name="description" content={`${marketData.vessel_name ? marketData.vessel_name : marketData.mine}`} /> */}

        </Helmet>
    }
    
    return (
        <Container component="main" maxWidth="lg" className={classes.root}>
            {brandStoreUserData && <MetaTags />}

            <br /><br />
            {/* <Link to={'/brand-stores/sign-up/MA=='}>Singup </Link> */}
            <Card elevation={10}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={9}>
                            <Box mb={1.5} display='flex' alignItems='center'>
                                <img src={brandStoreUserData?.brand_store_company?.logo} width="50" style={{ marginRight: '5px' }} />
                                <Typography
                                    gutterBottom
                                    className={`${classes.bold} capitalize`}>
                                    {brandStoreUserData?.brand_store_company?.name}
                                </Typography>
                            </Box>
                            <Box style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "baseline",
                                color: "#6A6A6A",
                                fontSize: 14,
                            }}>
                                <Box style={{ display: 'flex' }}>
                                    <Rating
                                        value={parseFloat(brandStoreUserData?.rating?.avg_round)}
                                        precision={0.5}
                                        readOnly
                                        sx={{ fontSize: '1rem' }}
                                    /> {brandStoreUserData?.rating?.avg_round} |  {brandStoreUserData?.rating?.review_count} Reviews
                                </Box>
                            </Box>

                            <Box>
                                <Typography variant='body2'>
                                    {brandStoreUserData?.brand_store_company?.about_us}
                                </Typography>
                            </Box>
                        </Grid>


                        {/* <Grid item xs={12} sm={3}>
                            <Box textAlign='center'>
                                <Box>
                                    <Box textAlign="center">
                                        <UserPlanIcon user={brandStoreUserData} />
                                    </Box>
                                    <Box>
                                        <small className={classes.textMuted}> {brandStoreUserData?.badges?.deal_quantity?.text} | {brandStoreUserData?.badges?.deal_count?.text} | {brandStoreUserData?.badges?.age_of_account?.text}
                                        </small>
                                    </Box>
                                </Box>
                            </Box>
                           
                        </Grid> */}

                    </Grid>


                </CardContent>
            </Card>


            {/* <Grid container spacing={1}> */}

                {/* Market tabs */}
                {/* <Grid item sm={12} xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={classes.tabContainer}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}
                            variant="scrollable"
                            className={`${user_mode == 1 ? 'buyerTheme-Tabs' : 'sellerTheme-Tabs'} ${classes.tabItems}`}
                        >
                            {(!marketListLoading && (marketList && marketList.length > 0) && (userReducer && !userReducer.noCompany)) && marketList.map((i, k) => (
                                <Tab
                                    key={k}
                                    label={`${(i.market_code === "" || i.market_code === null) ? i.name : i.market_code}`}
                                    value={i.id}
                                    //to={`/brand-stores?market_code=${i.market_code}`}
                                    //component={Link}
                                    {...a11yProps(i.id)}
                                />

                            ))}
                        </Tabs>
                    </Box>
                </Grid> */}

                {/* Filters and search */}
                {/* <Grid container>
                    <Grid item sm={9} xs={12}>
                        <ErrorHandler>
                            <MarketFilter
                                paymentMethod={paymentMethod}
                                rangeValue={rangeValue}
                                loadingCountries={loadingCountries}
                                currencyStatusSet={currencyStatusSet}
                                currencyStatus={currencyStatus}
                                countries={countries}
                                currencySet={currencySet}
                                currency={currency}
                                setPaymentMethod={setPaymentMethod}
                                setFilter={setFilter}
                                advance={advance}
                                setAdvance={setAdvance}
                                balance={balance}
                                setBalance={setBalance}
                                rangeValueSet={rangeValueSet}
                                setShowFilterDialog={setShowFilterDialog}
                                showFilterDialog={showFilterDialog}
                                user={user}
                            />
                        </ErrorHandler>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <TextField
                            fullWidth
                            className={classes.searchSelect}
                            size="small"
                            variant="outlined"
                            value={keyword}
                            onChange={e => {
                                keywordSet(e.target.value)
                            }}
                            placeholder={"Search by Vessel, Type, etc.."}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid> */}
                {/* <Grid item xs={12} sm={5}>
                    <Card>
                        <Box m={1} p={1}>
                            {marketList && marketplaceId != "" &&
                                <Box sx={{ color: "#666" }}>
                                    <Box
                                        sx={{ fontWeight: 'bold', display: 'inline-block', textTransform: 'uppercase' }}>
                                        "{marketplaceId == 'bookmarked' ? 'Favourite' : marketList[marketList.findIndex(m => m.id == marketplaceId)]?.name}"
                                    </Box> market
                                </Box>
                            }
                        </Box>

                        <Box>
                            <TabPanel value={value} index={value}>
                                <ErrorHandler>
                                    <Grid container spacing={2}>
                                        {loadingVessel && <Grid item sm={12}><CardLoader /></Grid>}
                                        {loadingVessel && [1, 2, 3, 4].map((i) => (
                                            <Grid item xs={12} key={i}>
                                                <CardLoader />
                                            </Grid>
                                        ))}
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box sx={{ maxHeight: '600px', overflowY: 'scroll' }}>
                                                {(vessels && vessels.length > 0) && vessels.map((vessel_details, index) =>
                                                    (vessel_details && vessel_details?.vessel_attributes && vessel_details.vessel_attributes.length > 0) && vessel_details?.vessel_attributes.filter(e => {
                                                        return e.multiple_products.length > 0;
                                                    }).map((vessel_attribute_details, key) => (
                                                        <div className={`contract-${index}`}>
                                                            <MarketCard
                                                                onClick={() => { selectContractCard(vessel_details, vessel_attribute_details); marketDetailsMobSet(matchesViewport ? true : false); }}
                                                                selectedItem={selectedItem}
                                                                setSelectedItem={setSelectedItem}
                                                                cardID={uniqueId('market_cs_')}
                                                                key={vessel_details.vessel_attribute_oldest.id}
                                                                user={user}
                                                                vesselDetails={vessel_attribute_details}
                                                                marketplaceCode={marketplaceCode}
                                                                setMarketplaceId={setMarketplaceId}
                                                                marketplaceId={marketplaceId}
                                                                matchesViewport={matchesViewport}
                                                                //  vesselsIndex={vessel_details.indexOf(vessel_details)}
                                                                marketName={marketList[marketList.findIndex(m => m.id == marketplaceId)]?.market_code}
                                                                eventsTracking={eventsTracking}
                                                                vesselAttrData={vessel_details.vessel_attribute_oldest}
                                                                vesselData={vessel_details}
                                                                marketDetails={marketDetails}
                                                                marketDetailsSet={marketDetailsSet}
                                                                rangeValue={rangeValue}
                                                                advance={advance}
                                                                balance={balance}
                                                                numberOfCreditDays={numberOfCreditDays}
                                                                paymentMethod={paymentMethod}
                                                                marketDataSet={marketDataSet}
                                                                vesselPriceBuyerSet={vesselPriceBuyerSet}
                                                                vesselPriceSellerSet={vesselPriceSellerSet}
                                                                vesselPriceSeller={vesselPriceSeller}
                                                                vesselInfoSet={vesselInfoSet}
                                                                loadingVesselPriceSet={loadingVesselPriceSet}
                                                                vesselTypeSet={vesselTypeSet}
                                                                lastTradedPriceSet={lastTradedPriceSet}
                                                                marketAttrDataSet={marketAttrDataSet}
                                                                priceRangeSet={priceRangeSet}
                                                                setPrice={setPrice}
                                                                portIdSet={portIdSet}
                                                                setShowDetailsDialog={setShowDetailsDialog}
                                                                setTabValue={setTabValue}
                                                                refreshBestBuyerSeller={refreshBestBuyerSeller}
                                                                setRefreshBestBuyerSeller={setRefreshBestBuyerSeller}
                                                                marketDetailsMobSet={marketDetailsMobSet}
                                                                setMinimumOrderQuantity={setMinimumOrderQuantity}
                                                                setPriceTickSize={setPriceTickSize}
                                                                setQuantityTickSize={setQuantityTickSize}
                                                                setQuantityRangeMax={setQuantityRangeMax}
                                                                setQuantityRangeMin={setQuantityRangeMin}
                                                                loginData={isLoggedIn}
                                                                onExpireListing={() => { fetchMarketplaceTabs(); setRefreshBestBuyerSeller(true); getBestListingOfferRFQ({ vesselData: marketData, vesselAttrData: marketAttrData, silentLoading: true }) }}
                                                            />

                                                        </div>
                                                    )))}
                                            </Box>
                                        </Grid>
                                        {(!loadingVessel && vessels.length == 0) && <Box sx={{ textAlign: 'center', p: 2, ml: 1 }}>No market found.
                                            <br />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                size="small"
                                                sx={{ width: 130, mt: 1 }}
                                                to="/markets"
                                                component={Link}
                                               
                                            >
                                                Go To Markets
                                            </Button>
                                        </Box>}
                                    </Grid>
                                </ErrorHandler>
                            </TabPanel>
                        </Box>
                        {userReducer && userReducer.noCompany &&
                            <img src='/images/faded-guest-market.png' className='pointer img-fluid' onClick={e => { fireCompanyNotAddedEvent() }} />
                        }
                    </Card>
                </Grid> */}
                {/* <Grid item sm={7} display={{ xs: "none", sm: "block" }}>

                    {marketDetails === false &&
                        <Box pl={0} pr={2} pb={2} textAlign='center' mt={2}>
                            <Typography variant='h6' component='p'>Select a market</Typography>
                            <Box sx={{ width: [320], margin: 'auto' }}><img className='w-100 img-fluid' src="/images/chat-column-fallback-bg.svg" alt="No markets" /></Box>
                        </Box>
                    }
                    {loadingVesselPrice && <Grid item sm={12}><CardLoader /></Grid>}
                    {(!loadingVesselPrice && marketDetails === true && marketData) &&
                        <ErrorHandler>
                            <div className={`contractPrice`}>
                                <MarketPrices
                                    key={selectedItem}
                                    selectedItem={selectedItem}
                                    matchesViewport={matchesViewport}
                                    eventsTracking={eventsTracking}
                                    vesselPriceBuyer={vesselPriceBuyer}
                                    vesselPriceSeller={vesselPriceSeller}
                                    vesselInfo={vesselInfo}
                                    vesselType={vesselType}
                                    marketData={marketData}
                                    lastTradedPrice={lastTradedPrice}
                                    setChatData={setChatData}
                                    user={user}
                                    minimumOrderQuantity={minimumOrderQuantity}
                                    quantityTickSize={quantityTickSize}
                                    quantityRangeMax={quantityRangeMax}
                                    quantityRangeMin={quantityRangeMin}
                                    priceTickSize={priceTickSize}
                                    auctionFinished={auctionFinished}
                                    setAuctionFinished={setAuctionFinished}
                                    onCloseClick={(e) => { setShowDetailsDialog(false) }}
                                />
                            </div>
                        </ErrorHandler>
                    }
                </Grid> */}
                {/* <Hidden smUp>
                    {isLoggedIn&&(
 <BottomTabsNav
 />
                    )}
                   
                </Hidden> */}
            {/* </Grid> */}
            {
                chatData.show && (
                    <ChatModal
                        key={chatData.listing_id}
                        data={chatData}
                        onClose={e => setChatData({})}
                        bestListingOffer={true}
                        onSubmitForm={e => setRefreshBestBuyerSeller(true)}
                    />
                )
            }

            {marketDetailsMob &&
                <Hidden smUp>
                    <Dialog
                        fullScreen
                        open={showDetailsDialog}
                        onClose={() => setShowDetailsDialog(false)}
                        scroll="body"
                        TransitionComponent={Transition}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderBottom: '1px solid #f0f0f0', p: 1, boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.03)', mb: 0 }}>
                            <IconButton
                                aria-label="previous tep"
                                onClick={() => setShowDetailsDialog(false)}
                                size="small"
                                color="inherit"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box sx={{ fontWeight: 'bold', fontSize: '18px' }} textAlign="center">Details</Box>

                        </Box>
                        <Box className={classes.gridCol2} sx={{ backgroundColor: '#F2F3F5', py: 1.25, pl: 1 }}>
                            <TextField
                                fullWidth
                                className={classes.searchSelect}
                                size="small"
                                variant="outlined"
                                value={keyword}
                                onChange={e => {
                                    keywordSet(e.target.value)
                                }}
                                placeholder={"Search by Vessel, Type, etc.."}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <IconButton
                                color="inherit"
                                variant="outlined"
                                disableElevation
                                className={`${classes.btnSpacingX}`}
                                onClick={() => fetchMarketplaceTabs()}
                                aria-label="filter"
                                size="small">
                                <RefreshIcon color="inherit" />
                            </IconButton>
                        </Box>

                        {loadingVesselPrice && <Grid item sm={12}><CardLoader /></Grid>}
                        {(!loadingVesselPrice && marketDetailsMob === true && marketData) &&
                            <ErrorHandler>
                                <MarketPrices
                                    matchesViewport={matchesViewport}
                                    eventsTracking={eventsTracking}
                                    vesselPriceBuyer={vesselPriceBuyer}
                                    onCloseClick={(e) => { setShowDetailsDialog(false) }}
                                    vesselPriceSeller={vesselPriceSeller}
                                    vesselInfo={vesselInfo}
                                    vesselType={vesselType}
                                    marketData={marketData}
                                    lastTradedPrice={lastTradedPrice}
                                    setChatData={setChatData}
                                    user={user}
                                    minimumOrderQuantity={minimumOrderQuantity}
                                    quantityTickSize={quantityTickSize}
                                    quantityRangeMax={quantityRangeMax}
                                    quantityRangeMin={quantityRangeMin}
                                    priceTickSize={priceTickSize}
                                    auctionFinished={auctionFinished}
                                    setAuctionFinished={setAuctionFinished}
                                />
                            </ErrorHandler>
                        }
                    </Dialog>
                </Hidden>
            }
            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={e => isTourOpenSet(false)}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
  

{/* <Dialog
                onClose = {handleClose} open = {openDialog}
                aria-labelledby="form-dialog-title"
               
            >
                <SignUp />
            </Dialog> */}
        
        </Container>
    )
};