import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Box from "@mui/material/Box";
import StarRateIcon from '@mui/icons-material/StarRate';
import IconButton from '@mui/material/IconButton';
import ThemeButton from './ThemeButton';
import Emitter from '../../services/emitter'
import { User } from '../../api/resources/User'
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import eventsTracking from '../../eventsTracking';

const useStyles = makeStyles(theme => ({
    bookmarker: {
        fontSize: 10,
        paddingBottom: '2px',
        '& .MuiSvgIcon-fontSizeSmall': {
            // fontSize: '.95rem',
            fontSize: '20px',
        },
    },

    button: {
        fontSize: '11px',
        backgroundColor: 'transparent',
        color: '#6a6a6a',
        padding: '1px 2px',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    buttonPrimary: {
        fontSize: '11px',
        backgroundColor: 'transparent',
        // color: '#0645ad',
        color: theme.palette.primary.main,
        padding: '1px 2px',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },

}));


export default function Bookmark(props) {
    const classes = useStyles();
    const [isBookmarked, isBookmarkedSet] = React.useState(false);
    // const [bookmarkedStatus, bookmarkedStatusSet] = React.useState(null);
    // console.log(bookmarkedStatus)
    const toggleBookmarkState = async () => {
        try {
            const res = await User.bookmarks.vesselAttributeToggle({vessel_attribute_id: props.vesselAttributeId})
            if(res.data.status == 'success')
            {
                isBookmarkedSet(res.data.data.bookmark_status)
                // bookmarkedStatusSet(res.data.message)
                if(res.data.data.bookmark_status == true){
                    Emitter.emit('SNACKBAR_NOTIFICATION', { message: `${'Favourited successfully!'}`, params: { variant: 'success' } });
                    eventsTracking.setContractFavourite()
                }
                else {
                    Emitter.emit('SNACKBAR_NOTIFICATION', { message: `${'Removed from favourite!'}`, params: { variant: 'default' } });
                    eventsTracking.removeContractFavourite()
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        isBookmarkedSet(props.hasBookmarked)
    }, [props.hasBookmarked])

    React.useEffect(() => {
        if(props.onChange)
        {
            props.onChange({isBookmarked: isBookmarked})
        }
    }, [isBookmarked])

    return (
        <>
            <Box className={classes.bookmarker} sx={{textAlign: 'right' }}>
                {props.iconButton === true ?
                    <IconButton
                        disableElevation
                        className={'titleCase' + isBookmarked ? classes.buttonPrimary : classes.button}
                        size="large"
                        onClick={e => props.isSelectMarket === true ? props.onMarkDefault(props.defaultId) : toggleBookmarkState()}
                    >
                        {
                            (props.hasStarIcon === true) &&
                            // <span style={{ backgroundColor: isBookmarked ? "#fee10b" : "#cccccc", color: "#FFF", padding: "5px" }}><StarRateIcon fontSize='small'/></span> :
                            // (isBookmarked ? <BookmarkIcon fontSize='small'/> : <BookmarkBorderIcon fontSize='small' color='primary'/>)
                            (isBookmarked ? <StarIcon fontSize='small' style={{color: '#FEE10B'}}/> : <StarIcon fontSize='small' style={{color: '#6A6A6A'}}/>)
                        }
                    </IconButton>
                :
                <ThemeButton
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={'titleCase' + isBookmarked ? classes.buttonPrimary : classes.button}
                    endIcon={
                        (props.hasStarIcon === true) ?
                        <span style={{ backgroundColor: isBookmarked ? "#fee10b" : "#cccccc", color: "#FFF" }}><StarRateIcon /></span> :
                        (isBookmarked ? <BookmarkIcon fontSize='medium'/> : <BookmarkBorderIcon fontSize='medium' color='primary'/>)
                    }
                    size="small"
                    style={{textTransform: 'initial'}}
                    onClick={e => props.isSelectMarket === true ? props.onMarkDefault(props.defaultId) : toggleBookmarkState()}
                >
                    {/* {props.isSelectMarket && <>
                        {isBookmarked ? 'Default' : 'Default'}
                    </>} */}
                    {!props.isSelectMarket && <>
                        {(props.hasStarIcon === undefined || props.hasStarIcon === false) && <>
                            {isBookmarked ? 'Marked' : 'Mark'} as favourite
                        </>}
                    </>}
                </ThemeButton>
                }
            </Box>
        </>
    )
}