import React from 'react'

export default function ShipIcon() {
	return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            stroke="currentColor"
            width="100%" height="100%"
            viewBox="0 0 8.503 9.117"
        >
            <g strokeWidth="0px" transform="translate(44.647 -80.736)"><g transform="translate(-44.547 80.836)"><path class="a" d="M9.708,8.3a.2.2,0,0,0-.258-.037.905.905,0,0,1-.939.11c-.037-.018-.092-.037-.129-.055.46-.644.884-1.473,1.325-1.988a.185.185,0,0,0-.037-.276l-.644-.4V3.092a.456.456,0,0,0-.46-.46h-.46V1.1A.534.534,0,0,0,7.573.571H5.788V.184a.184.184,0,1,0-.368,0V.552H3.634a.534.534,0,0,0-.534.534V2.632H2.64a.456.456,0,0,0-.46.46V5.651l-.644.4a.185.185,0,0,0-.037.276c.479.571.81,1.344,1.307,2.006a.841.841,0,0,1-.957-.074.182.182,0,0,0-.258.258,1.284,1.284,0,0,0,1.62,0h0a.926.926,0,0,1,1.086,0A1.164,1.164,0,0,0,5.788,8.5c.295-.295.939.037.939.037A1.559,1.559,0,0,0,8.2,8.67a.157.157,0,0,1,.129,0A1.254,1.254,0,0,0,9.69,8.523.137.137,0,0,0,9.708,8.3ZM3.45,1.1A.165.165,0,0,1,3.615.939H7.555A.165.165,0,0,1,7.72,1.1V2.651H3.45Zm-.9,1.988A.087.087,0,0,1,2.64,3H8.567a.087.087,0,0,1,.092.092V5.412L5.7,3.534a.2.2,0,0,0-.2,0L2.548,5.412Zm1.97,5.172a1.279,1.279,0,0,0-1.4-.11c-.442-.589-.755-1.307-1.215-1.9L5.419,4.031V8.32A.724.724,0,0,1,4.517,8.265Zm2.375-.018a1.638,1.638,0,0,0-1.1-.147V4.031L9.3,6.258c-.5.644-.976,1.583-1.454,2.172A1.283,1.283,0,0,1,6.892,8.246Z" transform="translate(-1.453)"/><path class="a" d="M30.152,8.7h-.368a.184.184,0,1,0,0,.368h.368a.174.174,0,0,0,.184-.184A.184.184,0,0,0,30.152,8.7Z" transform="translate(-24.419 -7.099)"/><path class="a" d="M22.552,8.7h-.368a.184.184,0,0,0,0,.368h.368a.174.174,0,0,0,.184-.184A.184.184,0,0,0,22.552,8.7Z" transform="translate(-18.218 -7.099)"/><path class="a" d="M14.952,8.7h-.368a.184.184,0,1,0,0,.368h.368a.184.184,0,1,0,0-.368Z" transform="translate(-12.017 -7.099)"/><path class="a" d="M34.384,19.468h.368a.184.184,0,0,0,0-.368h-.368a.174.174,0,0,0-.184.184A.184.184,0,0,0,34.384,19.468Z" transform="translate(-28.172 -15.584)"/><path class="a" d="M10.352,19.1H9.984a.184.184,0,0,0,0,.368h.368a.174.174,0,0,0,.184-.184A.184.184,0,0,0,10.352,19.1Z" transform="translate(-8.264 -15.584)"/></g></g>
        </svg>
    )
}