import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputLabel,
    Box
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import LanguageTranslate from '../../partials/UnderScoreLanguage'
import { validatorRegEx } from "../../../helpers";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 *
 * @name LiftingDays
 * @alias LiftingDays
 * @component
 * @exports LiftingDays
 *
 * @description
 * Lifting Days input field
 *
 */

export default function LiftingDays(props) {
  
    const classes = useStyles();

    useEffect(() => {
        if (props.marketData.is_auction == true) {
            props.setLiftingDays(4);
            props.setNumberOfDays(4);
        } else {
            if (props.quantity) {
                //console.log(props.quantity)
                if (props.quantity < 100) {
                    props.setLiftingDays(1);
                    props.setNumberOfDays(1);
                } else if (props.quantity >= 100 && props.quantity < 500) {
                    props.setLiftingDays(7);
                    props.setNumberOfDays(7);
                } else if (props.quantity >= 500 && props.quantity < 1000) {
                    props.setLiftingDays(15);
                    props.setNumberOfDays(15);
                } else if (props.quantity >= 1000) {
                    props.setLiftingDays(30);
                    props.setNumberOfDays(30);
                }
            } else {
                props.setLiftingDays("");
                props.setNumberOfDays("");
            }
        }

    }, [props.quantity, props.marketData]);

    return (
        <Box>
            <InputLabel className={classes.marginBottom}><LanguageTranslate s='Lifting Days' /><sup style={{ color: "red" }}>*</sup></InputLabel>
            <TextValidator
                variant="outlined"
                fullWidth
                size="small"
                validators={[
                    "required",
                    "isNumber",
                    `matchRegexp:${validatorRegEx("nonZero")}`
                ]}
                errorMessages={[
                    "Lifting Days field is required",
                    "Only numbers are accepted!",
                    "Lifting Days can not be Zero !!"
                ]}
                value={props.liftingDays}
                onChange={e => { props.setLiftingDays(e.target.value); props.setNumberOfDays(e.target.value); }}
            />
        </Box>
    );
}
