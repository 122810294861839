import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    Box,
    InputLabel,
    InputAdornment
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { validatorRegEx } from "../../../helpers";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
}));

/**
 *
 * @name NumberOfCreditDays
 * @alias NumberOfCreditDays
 * @component
 * @exports NumberOfCreditDays
 *
 * @description
 * Number of credit days input field
 *
 */

export default function NumberOfCreditDays(props) {
    const classes = useStyles();
    return (
        <Box>
            <InputLabel className={`${classes.marginBottom}`}> No. of Credit Days<sup style={{ color: "red" }}>*</sup>
            </InputLabel>
            <TextValidator
                className={classes.backgroundWhite}
                variant="outlined"
                type="number"
                fullWidth
                name="no_od_credit_days"
                value={props.numberOfCreditDays}
                onChange={e => { props.setNumberOfCreditDays(e.target.value) }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                        >
                            Days
                        </InputAdornment>
                    )
                }}
                validators={["required", "isNumber",   `minNumber:${0}`]}
                errorMessages={["No. of Credit Days is required.", "Only numeric values accepted.", "Credit Days can not be Negative !!"]}
                size="small"
            />
        </Box>
    );
}
