import React from 'react'
// import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import { config } from '../../config'
// import eventsTracking from '../../eventsTracking';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { TextField, Select, IconButton, Button, Menu, InputLabel, MenuItem, InputAdornment } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import ErrorHandler from '../partials/ErrorHandler'
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
// import LazyLoad from '../partials/LazyLoad';
import { validatorRegEx } from '../../helpers'
// import StarIcon from '@mui/icons-material/Star';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Autocomplete } from '@mui/material';
import "react-input-range/lib/css/index.css"
import Hidden from '@mui/material/Hidden';
import InputRange from 'react-input-range';
import {
    FilterAltOutlined as FilterIcon,
} from "@mui/icons-material";
import AdvanceBalance from '../partials/AdvanceBalance';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PaymentMethodList = [
    { id: 1, name: "Advance" },
    // { id: 2, name: "Letter of Credit" },
    // { id: 3, name: "Credit" },
    { id: 4, name: "Cash & Carry" }
]

const useStyles = makeStyles(theme => ({
    customLabel: {
        display: 'block',
        fontSize: 12
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    gridAdBal: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: 'normal',
        gridGap: '5px',
        padding: '2px 4px 9px',
    },
    gridCol3: {
        display: "grid",
        // gridTemplateColumns: ".1fr 1fr .7fr .5fr",
        // gridTemplateColumns: "18px 130px 110px 74px",
        // gridTemplateColumns: '18px 60px 219px 121px 100px',
        // gridTemplateColumns: '26px 84px 165px 121px 63px',
        gridTemplateColumns: '90px 181px 121px 63px',
        alignItems: 'center',
        gridGap: '4px',
        // marginTop: '15px',
        "& .MuiOutlinedInput-root": {
            backgroundColor: '#fff'
        },
        [theme.breakpoints.down('sm')]: {
            // gridTemplateColumns: '18px 139px 104px 100px'
            gridTemplateColumns: '21px 80px 139px 104px 100px',
            overflowX: 'auto',
            paddingBottom: theme.spacing(1),
        }
    },
    // gridCol4: {
    //     display: "grid",
    //     gridTemplateColumns: ".1fr .5fr .5fr .5fr .5fr",
    //     alignItems: 'center',
    //     gridGap: '4px',
    //     marginTop: '15px',
    //     "& .MuiOutlinedInput-root": {
    //         backgroundColor: '#ffffff'
    //     },
    // },

    gridCol4: {
        display: "grid",
        // gridTemplateColumns: ".1fr .5fr .5fr .5fr .5fr",
        // gridTemplateColumns: "26px 84px 165px 121px 63px",
        // gridTemplateColumns: "26px 91px 196px 152px 110px",
        gridTemplateColumns: "95px 196px 152px 110px 70px",
        alignItems: 'initial',
        gridGap: '4px',
        // marginTop: '15px',
        "& .MuiOutlinedInput-root": {
            backgroundColor: '#ffffff'
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '18px 139px 104px 100px'
        }
    },
    absolutePositioned: {
        // gridTemplateColumns: '18px 130px 163px 100px 64px'
        marginTop: '0',
        position: 'absolute',
        top: '18px'
    },
    absolutePositioned2: {
        // gridTemplateColumns: '18px 130px 163px 100px 64px'
        marginTop: '0',
        position: 'absolute',
        top: '22px'
    },
    btnSpacingX: {
        marginRight: theme.spacing(1)
    },
    borderBox: {
        // border: '1px solid #f0f0f0',
        // background: '#f2f3f5',
        // borderRadius: '4px'
    },
    whiteButton: {
        backgroundColor: theme.palette.common.white,
        color: '#cccccc',
        padding: '8px 10px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        }
    },
    paymentButton: {
        backgroundColor: '#f1f1f1',
        color: '#6e5959',
        padding: '5px 10px',
        borderColor: '#ccc',
        '&:hover': {
            backgroundColor: '#f1f1f1',
        }
    },
    paymentButtonSelected: {
        backgroundColor: '#d4def1',
        color: '#2959ba',
        padding: '5px 10px',
        borderColor: 'transparent',
        '&:hover': {
            backgroundColor: '#d4def1',
        }
    },
}));

export default function MarketFilter(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [selectedCurrencyObj, selectedCurrencyObjSet] = React.useState({});

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (newOpen) => () => {
        props.setShowFilterDialog(newOpen);
    };

    function changePaymentMode(event, p) {
        let checkedArray = [...props.paymentMethod];
        let selectedValue = p;

        if (checkedArray.map(e => e.id).includes(selectedValue.id)) {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            props.setPaymentMethod(checkedArray);
        } else {
            checkedArray.push(selectedValue);
            props.setPaymentMethod(checkedArray);
        }
        //console.log(checkedArray)
        props.setPaymentMethod(checkedArray);
    }


    return (
        <>
            <Box sx={{ marginTop: '15px' }}>
                {/* <Box className={props.paymentMethod.length == 0 ? classes.gridCol3 : classes.gridCol4}> */}
                <Box className={props.paymentMethod.length == 0 ? classes.gridCol3 : classes.gridCol4}>
                    <Hidden smUp>
                        <Box>
                            <IconButton
                                aria-label={`Filter`}
                                size="small"
                                color="inherit"
                                disableElevation
                                onClick={() => props.setShowFilterDialog(true)}
                            >
                                <FilterIcon fontSize='small' />
                            </IconButton>
                        </Box>
                    </Hidden>
                    <Box>
                        <InputLabel className={classes.customLabel}>Currency</InputLabel>
                        {/* {(props.currency.length > 0 || props.rangeValue || props.paymentMethod.length > 0 ) && <InputLabel className={classes.customLabel}>Currency</InputLabel>} */}
                        <Select
                            value={props.currency}
                            name="country"
                            variant="outlined"
                            placeholder='Currency'
                            size="small"
                            fullWidth
                            onChange={(e) => {
                                props.currencySet(e.target.value);
                            }}
                            inputProps={{
                                id: "open-select"
                            }}
                        >
                            {props.countries.map((option, key) => (
                                <MenuItem value={option.currency} key={key}>
                                    {/* {option.currency == 'INR' &&
                                        <img
                                            loading="lazy"
                                            width="13"
                                            src="/images/rupee-icon.png"
                                        />} */}
                                        {option.currency}
                                </MenuItem>
                            ))}
                        </Select>
                        {/* <Autocomplete
                            freeSolo
                            size="small"
                            filterSelectedOptions
                            autoHighlight
                            options={props.countries}
                            onChange={(e, countryCurrency) => {
                                props.currencySet(countryCurrency.currency);
                                selectedCurrencyObjSet(countryCurrency);
                            }}
                            getOptionLabel={countryCurrency => countryCurrency.currency ? countryCurrency.currency : ""}
                            // getOptionLabel={'currency'}
                            renderOption={(props, option) => (
                                <Box component="li"  {...props} key={option.id}>
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`${option.flag_img}`}
                                    alt={option.iso}
                                    style={{mr: 2, flexShrink: 0}}
                                  />
                                  {option.currency}
                                </Box>
                            )}
                            // value={selectedCurrencyObj}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    variant="outlined"
                                    placeholder='Currency'
                                    required
                                    size="small"
                                    fullWidth
                                    value={selectedCurrencyObj?.currency}
                                    // value={props.currency}
                                />
                            )}
                        /> */}
                    </Box>
                    <Box className={(props.paymentMethod.map(e => e.id).includes(4)) ? classes.borderBox : ''}>
                        <InputLabel className={classes.customLabel}>Payment Methods</InputLabel>
                        {/* {(props.paymentMethod.length > 0 || props.rangeValue || props.currency.length > 0 ) && <InputLabel className={classes.customLabel}>Payment Methods</InputLabel>} */}
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            // limitTags={1}
                            // style={{padding: '4px'}}
                            options={PaymentMethodList}
                            onChange={(e, payment) => {
                                props.setPaymentMethod(payment);
                                props.setFilter(payment.length == 0 ? false : true);
                            }}
                            getOptionLabel={option =>
                                option.name
                                    ? `${option.name} ${option.id === 1 ? "(100%)" : ''}`
                                    : ""
                            }
                            // renderOption={(props, option) => (
                            //     <>
                            //         {option.id === 4 ?
                            //             <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ boxShadow: 'none' }}>
                            //                 <AccordionSummary
                            //                     expandIcon={<ExpandMoreIcon />}
                            //                     aria-controls="panel1bh-content"
                            //                     id="panel1bh-header"
                            //                 >
                            //                     <Typography className={classes.secondaryHeading}>{option.name}</Typography>
                            //                 </AccordionSummary>
                            //                 <AccordionDetails sx={{ p: 0, backgroundColor: '#F2F3F5' }}>
                            //                     <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            //                         <Box className={classes.textLabel} mb={.5}>Advance:</Box>
                            //                         <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth={125}>
                            //                             <TextField
                            //                                 variant="outlined"
                            //                                 className={`${classes.inputSpacing}`}
                            //                                 fullWidth
                            //                                 name="advance"
                            //                                 InputProps={{
                            //                                     endAdornment: (
                            //                                         <InputAdornment
                            //                                             position="end"
                            //                                             className="fsSmall"
                            //                                         >
                            //                                             %
                            //                                         </InputAdornment>
                            //                                     )
                            //                                 }}
                            //                                 value={props.advance}
                            //                                 onChange={e => { props.setAdvance(e.target.value) }}
                            //                                 size="small"
                            //                             />
                            //                         </Box>
                            //                     </Box>
                            //                     <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            //                         <Box className={classes.textLabel} mb={.5}>Balance:</Box>
                            //                         <Box display="flex" justifyContent="space-between" alignItems="center" maxWidth={125}>
                            //                             <TextField
                            //                                 variant="outlined"
                            //                                 className={`${classes.inputSpacing}`}
                            //                                 fullWidth
                            //                                 name="balance"
                            //                                 value={props.balance + '%'}
                            //                                 disabled
                            //                                 size="small"
                            //                             />
                            //                         </Box>
                            //                     </Box>
                            //                 </AccordionDetails>

                            //             </Accordion>
                            //             :
                            //             <Typography sx={{ p: 1 }}  {...props}>{option.name}</Typography>
                            //         }
                            //     </>
                            // )}
                            value={props.paymentMethod}
                            filterSelectedOptions
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Payment Methods"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={props.paymentMethod}
                                />
                            )}
                        />
                    </Box>

                    {(props.paymentMethod.map(e => e.id).includes(4)) &&
                        <Box className={classes.gridAdBal}>
                            <Box>
                                <InputLabel className={classes.customLabel}>Adv: {props.advance + '%'}</InputLabel>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    name="num_advance"
                                    value={props.advance}
                                    onChange={e => { props.setAdvance(e.target.value) }}
                                    validators={[
                                        "isNumber",
                                        `matchRegexp:${validatorRegEx("numeric")}`,
                                    ]}
                                    size="small"
                                />
                            </Box>
                            <Box>
                                <InputLabel className={classes.customLabel}>Bal: {props.balance + '%'}</InputLabel>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    name="num_balance"
                                    value={props.balance}
                                    onChange={e => { props.setBalance(e.target.value) }}
                                    disabled
                                    validators={[
                                        "isNumber",
                                        `matchRegexp:${validatorRegEx("numeric")}`,
                                    ]}

                                    size="small"
                                />
                            </Box>
                        </Box>
                    }

                    <Box>
                        {/* <InputLabel className={classes.customLabel}>{(props.currency.length > 0 || props.rangeValue || props.paymentMethod.length > 0) && <span>Lifting Days: { props.rangeValue && (`(${props.rangeValue.min}` + '-' + `${props.rangeValue.max})`)}</span>}</InputLabel> */}
                        <InputLabel className={classes.customLabel}>Lifting Days {(props.currency.length > 0 || props.rangeValue || props.paymentMethod.length > 0) && props.rangeValue && (`(${props.rangeValue.min}` + '-' + `${props.rangeValue.max})`)}</InputLabel>
                        <Button
                            variant="outlined"
                            name="lifting_days"
                            fullWidth
                            color="inherit"
                            className={classes.whiteButton}
                            onClick={handleClick}
                        >
                            Lifting Days{' '}
                        </Button>
                        <Hidden smDown>
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <Box pl={1.5} pr={1.5} pb={2} pt={2} component="li" width={150}>
                                    <InputRange
                                        //draggableTrack
                                        maxValue={60}
                                        minValue={1}
                                        onChange={value => { props.rangeValueSet(value); value.min == 0 ? props.setFilter(false) : props.setFilter(true); }}
                                        value={props.rangeValue || { min: 1, max: 60 }}
                                    />
                                </Box>
                            </Menu>
                        </Hidden>
                    </Box>

                    <Box sx={{ display: 'flex', maxWidth: '135px', justifyContent: 'space-between', position: 'relative' }}>
                        <Button variant="text" size="small" className={(props.paymentMethod.map(e => e.id).includes(4)) ? classes.absolutePositioned : (props.paymentMethod.map(e => e.id).includes(1)) ? classes.absolutePositioned2 : ''} onClick={() => { props.rangeValueSet(''); props.setAdvance(0); props.setPaymentMethod([]); props.currencySet(''); selectedCurrencyObjSet({}); props.setFilter(false); }}>Clear All</Button>
                    </Box>
                </Box>
            </Box>

            <Hidden smUp>
                <SwipeableDrawer
                    anchor="bottom"
                    open={props.showFilterDialog}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #f0f0f0', p: 1, boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.03)', mb: 1.8 }}>
                        <IconButton
                            aria-label="previous tep"
                            onClick={() => props.setShowFilterDialog(false)}
                            size="small"
                            color="inherit"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Box sx={{ fontWeight: 'bold', fontSize: '18px' }}>Filter</Box>
                        <Button variant="text" size="small" onClick={() => { props.rangeValueSet(''); props.setAdvance(0); props.setPaymentMethod([]); props.setFilter(false) }}>Clear All</Button>
                    </Box>

                    <Box m={1}>
                        <Box sx={{ px: 1, mb: .75 }}>
                            <Box sx={{ fontWeight: 'bold', ml: 1 }}>Lifting Days{' '}{props.rangeValue && <span>(Range {props.rangeValue.min} - {props.rangeValue.max})</span>}</Box>
                            <Box p={4}>
                                <InputRange

                                    maxValue={60}
                                    minValue={1}
                                    onChange={value => { props.rangeValueSet(value); }}
                                    value={props.rangeValue || { min: 1, max: 60 }}
                                />
                            </Box>
                        </Box>

                        <Box sx={{ pl: 1.5, pr: 1, mb: .75, pb: 2 }}>
                            <Box sx={{ fontWeight: 'bold', mb: 1, }}>Payment Methods</Box>
                            {PaymentMethodList.map((paymentType, index) => (
                                <>
                                    {props.paymentMethod.map(e => e.id).includes(paymentType.id) ?
                                        <Button
                                            className={`${classes.btnSpacingX} ${classes.paymentButtonSelected}`}
                                            style={{ marginLeft: .5, marginBottom: '14px' }}
                                            variant='outlined'
                                            size="small"
                                            key={index}
                                            color="inherit"
                                            value={paymentType}
                                            onClick={e => { changePaymentMode(e, paymentType) }}>
                                            {paymentType.name} {paymentType.id === 1 ? "(100%)" : (paymentType.id === 4 && props.advance) && `(${props.advance}%)`}
                                        </Button>
                                        :
                                        <Button
                                            className={`${classes.btnSpacingX} ${classes.paymentButton}`}
                                            style={{ marginLeft: .5, marginBottom: '14px' }}
                                            variant='outlined'
                                            size="small"
                                            color="inherit"
                                            key={index}
                                            value={paymentType}
                                            onClick={e => { changePaymentMode(e, paymentType) }}>
                                            {paymentType.name}
                                        </Button>
                                    }
                                </>
                            ))}
                        </Box>

                        {props.paymentMethod.map(e => e.id).includes(4) &&
                            <Box sx={{ px: 1, mb: .75, pb: 2 }}>
                                <Box sx={{ fontWeight: 'bold', ml: .5 }}>Cash & Carry Payment</Box>
                                <AdvanceBalance
                                    advance={props.advance}
                                    setAdvance={props.setAdvance}
                                    balance={props.balance}
                                    setBalance={props.setBalance}
                                />
                            </Box>
                        }

                        <Box sx={{ px: 4, mb: .75 }}>
                            <Button
                                fullWidth
                                variant='contained'
                                type="submit"
                                color="primary"
                                onClick={() => { props.setFilter(true); props.setShowFilterDialog(false); }}
                                disableElevation
                            >
                                Done
                            </Button>
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </Hidden>
        </>
    )
};