import React from 'react'
import Box from '@mui/material/Box';
import clsx from "clsx";
import { Product } from "../../api/resources/Product"
import makeStyles from '@mui/styles/makeStyles';
import ListIcon from '@mui/icons-material/List';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link as RouteLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { Tooltip, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import Bookmark from '../partials/Bookmark'
import ShipIcon from "../partials/icons/ShipIcon";
import { calculatePriceRange } from '../../helpers'
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import SocialShareModal from '../partials/SocialShareModal'
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PushPinIcon from '@mui/icons-material/PushPin';
import Button from '@mui/material/Button';
import ChatModalV3 from '../partials/ChatModalV3';
import HighlightOffIcon from '@mui/icons-material/Close';
import {
    Grid,
    Dialog,
    DialogTitle,
    Slide,
    DialogContent,
    DialogActions,
    CircularProgress,
} from "@mui/material";
import ThemeButton from "../partials/ThemeButton";
import ThemeIconButton from "../partials/ThemeIconButton";
import LoginForm from "../partials/LoginForm";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    gridCol3: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        alignItems: 'center',
        gridGap: '5px',
        "& .MuiOutlinedInput-root": {
            backgroundColor: '#ffffff',
            height: '30px',
        },
    },
    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: 'center',
        gridGap: '5px',
        "& .MuiOutlinedInput-root": {
            backgroundColor: '#ffffff'
        },
    },
    selected: {
        borderLeft: '5px solid',
        borderLeftColor: theme.palette.primary.main,
        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);'
    },
    textMuted: {
        color: '#6A6A6A',
        fontSize: '12px',
    },
    card: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        borderBottom: '1px solid #ccc',
        borderRadius: 0,
        "& .Mui-expanded": {
            margin: '0px'
        },
        "& .MuiPaper-elevation1": {
            boxShadow: 'none'
        }
    },
    // starBookmarkButton: {
    //     position: 'absolute',
    //     top: '-1px',
    //     right: '-2px',
    // },
    fs16: {
        fontSize: '16px',
    },
    fs14: {
        fontSize: '14px',
    },
    gridCol2Icon: {
        display: "grid",
        gridGap: '5px',
        gridTemplateColumns: '12px auto',
        alignItems: 'center',
        fontSize: '12px',
        '& img': {
            width: 12
        }
    },
    btnBuyer: {
        backgroundColor: '#00838F',
        padding: 0,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: '#005b64',
        },

    },
    expireIcon: {
        color: '#bdbdbd',
        padding: 0,
        '&:hover': {
            color: '#424242',
        },
    },
}));

export default function MarketCard(props) {
    const classes = useStyles();

    const [chatData, setchatData] = React.useState({});
    const [isBookmarked, isBookmarkedSet] = React.useState(false);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openDialog, setOpen] = React.useState(false);
    const [selectedListingID, setSelectedListingId] = React.useState(false);
    const [expireLoader, setExpireLoader] = React.useState(false);
    const [expireSuccess, setExpireSuccess] = React.useState(false);
    const [openDialogL, handleDisplay] = React.useState(false);
    const handleCloseL = () => {
        handleDisplay(false);
     };
  
     const openDialogBox = () => {
        handleDisplay(true);
     };
    const expireButtonClassname = clsx({
        [classes.buttonSuccess]: expireSuccess
    });

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const [newID, setNewID] = React.useState(null);
    // {console.log(newID)}

    /**
     * @function getBestListingOfferRFQ
     * @name getBestListingOfferRFQ
     * @description
     * Function to fetch the best 3 listing. requirements and offers
     *
     */

    const getBestListingOfferRFQ = async params => {
        var formData = new FormData();
        formData.append("vessel_attribute_id", props.vesselDetails.id);
        formData.append("type", props.vesselData.type);
        formData.append("limit", 999);
        formData.append("cc_percentage_start", props.advance == 0 ? '' : props.advance);
        formData.append("cc_percentage_end", '');
        formData.append("num_credit_days", props.numberOfCreditDays);
        formData.append("lifting_day_start", props.rangeValue?.min ? props.rangeValue?.min : '');
        formData.append("lifting_day_end", props.rangeValue?.max ? props.rangeValue?.max : '');
        props.paymentMethod.map(p => {
            formData.append("payment_term_ids[]", p.id)
        });

        props.loadingVesselPriceSet(true)

        try {
            const res = await Product.bestListingOfferRFQ(formData);

            if (res.data.status == "success") {
                props.vesselPriceBuyerSet(res.data.data.buyer);
                props.vesselPriceSellerSet(res.data.data.seller);
                props.vesselInfoSet(res.data.data.vessel_info);

                var bestPrices = [];
                if (res.data.data.buyer && res.data.data.buyer.length > 0 && res.data.data.buyer[0] && res.data.data.buyer[0].price_value) {
                    bestPrices.push(res.data.data.buyer[0].price_value)
                }
                if (res.data.data.seller && res.data.data.seller.length > 0 && res.data.data.seller[0] && res.data.data.seller[0].price_value) {
                    bestPrices.push(res.data.data.seller[0].price_value)
                }

                let listingRangeCap = 25
                if (res.data.data.listing_percentage && res.data.data.listing_percentage.value) {
                    listingRangeCap = res.data.data.listing_percentage.value
                }
                props.priceRangeSet(calculatePriceRange(bestPrices, (listingRangeCap / 100)))
            }
        } catch (e) {
            console.error(e);
        }
        props.loadingVesselPriceSet(false)
    };

    const expireAction = () => {
        setExpireLoader(true);
        setExpireSuccess(false);
        Product.expireListing({ listing_id: selectedListingID })
            .then(response => {
                setExpireSuccess(true);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setExpireLoader(false);
            });
    };

    // React.useEffect(() => {
    //     console.log("market card selected item " + props.selectedItem)
    //     if (props.marketDetails === true && props.refreshBestBuyerSeller === true && (props.selectedItem == props.vesselDetails.id)) {
    //         getBestListingOfferRFQ();
    //     }
    // }, [props.refreshBestBuyerSeller, props.marketDetails, props.selectedItem, props?.vesselAttrData.id]);

    React.useEffect(() => {
        props.setMinimumOrderQuantity(props.vesselData?.min_order_qty);
        props.setPriceTickSize(props.vesselData?.tick_size);
        props.setQuantityTickSize(props.vesselData?.qty_tick_size);
        props.setQuantityRangeMax(props.vesselData?.price_range_max);
        props.setQuantityRangeMin(props.vesselData?.price_range_min);
    }, [props.vesselData])



    return (
        <Box
            // key={props.key}
            className={(props.selectedItem == props.vesselDetails.id) ? `${classes.selected} ${classes.card}` : `${classes.card}`}
            sx={{ p: 1.5, display: 'block', mb: 1.5 }}
            color="inherit"
            //id={props.key}
            disableElevation
        >
            {props.vesselData?.is_auction == 1 && <Box className='blinking' sx={{ fontSize: 12 }}><PushPinIcon fontSize='small' sx={{ transform: 'rotate(40deg)', verticalAlign: 'text-bottom', fontSize: '15px', mt: .5 }} /> CS eAuction</Box>}
            {props.vesselData?.via_cs == 1 && <Box className='blinking' sx={{ fontSize: 12 }}><PushPinIcon fontSize='small' sx={{ transform: 'rotate(40deg)', verticalAlign: 'text-bottom', fontSize: '15px', mt: .5 }} /> CS Preferred Seller</Box>}

            <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: .75, alignItems: "flex-start" }}>
                    <Box
                        style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                        className={`pointer ${classes.fs16}`}
                        onClick={e => {
                            props.onClick()
                        }}
                    >
                        {(props.vesselData.type == 1 && props.vesselData.vessel_name) && props.vesselData.vessel_name} {props.vesselData.type == 2 && props.vesselData.mine}
                    </Box>
                    <Box className={`${classes.gridCol3}`}>
                        <SocialShareModal
                            shareCardID={props.vesselDetails.id}
                            cardData={props.vesselData}
                            countryId={props.vesselData?.orginate}
                            marketplaceId={props.marketplaceId}
                            eventsTracking={props.eventsTracking}
                            vesselAttributeData={props.vesselAttrData}
                        />

                        <>
                            {(props.vesselData?.vessel_attributes && props.vesselData.vessel_attributes.length == 1) ?
                                <IconButton
                                    className={classes.actionButton}
                                    // style={{ backgroundColor: "#d4def1", borderRadius: "3px" }}
                                    // fullWidth
                                    disabled={props.vesselData?.vessel_attribute_oldest.attachment === null}
                                    size="small"
                                    component={Link}
                                    //style={{ color: '#6A6A6A' }}
                                    color="primary"
                                    variant="outlined"
                                    href={props.vesselData?.vessel_attribute_oldest.attachment}
                                    target="_blank"
                                >
                                    <GetAppIcon fontSize='small' />
                                </IconButton>
                                :
                                <>
                                    <IconButton
                                        id="basic-button"
                                        className={classes.actionButton}
                                        color="primary"
                                        aria-controls={open ? 'download-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <GetAppIcon fontSize='small' />
                                    </IconButton>

                                    <Menu
                                        id="download-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'download-button',
                                        }}
                                    >
                                        {(props.vesselData.vessel_attributes && props.vesselData.vessel_attributes.length > 0) && props.vesselData.vessel_attributes.map((va, key) => (
                                            <MenuItem
                                                disabled={va.attachment == ""}
                                                component={Link}
                                                target="_blank"
                                                href={va.attachment}
                                                onClick={handleClose}
                                            >
                                                <ListItemIcon>
                                                    <GetAppIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>{va.vessel_name}</ListItemText>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            }
                        </>
                        {(props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.company_id == props.user?.brand_store_company_id) &&
                            <Tooltip
                                title="Expire Listing"
                                aria-label="expire"
                            >
                                <IconButton
                                    onClick={e => {
                                        setSelectedListingId(props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.id);
                                        handleClickOpen("expireModal");
                                    }}
                                    size="small"
                                    className={classes.expireIcon}
                                >
                                    <HighlightOffIcon
                                        style={{
                                            fontSize: props.matchesViewport
                                                ? "18px"
                                                : "18px",
                                        }}
                                        fontSize="small"
                                        className="vAlignBottom"
                                    />
                                </IconButton>
                            </Tooltip>
                        }
                        {/* <Bookmark
                            iconButton={true}
                            hasStarIcon={true}
                            isNewMarket={true}
                            onChange={e => { isBookmarkedSet(e.isBookmarked) }}
                            vesselId={props.vesselData.id}
                            vesselAttributeId={props.vesselDetails.id}
                            // hasBookmarked={props.vesselAttrData.has_bookmarked}
                            hasBookmarked={props.vesselData.has_bookmarked}
                        // hasBookmarked={props.vesselData.vessel_attributes.filter(v => v.has_bookmarked == true)}
                        /> */}
                    </Box>
                </Box>
            </Box>
            <Box className='pointer'
                onClick={e => {
                    props.onClick()
                }}>
                <Box className={classes.fs14} mb={.55}>

                    <img src={props.vesselData?.origin_vessel?.flag_img} alt="flag" width="20" />&nbsp;
                    {props.vesselData.type == 1 && props.vesselData.origin_vessel && props.vesselData.origin_vessel.name}
                    {props.vesselData.type == 2 && "India"} | &nbsp;
                    {/* {(props.vesselData.type == 1 && props.vesselAttrData.port) && <><LocationOnIcon fontSize='small' sx={{ verticalAlign: 'text-bottom', fontSize: '14' }} /> {props.vesselAttrData.port.port_name}</>} */}
                    {(props.vesselData.type == 1 && props.vesselData.vessel_attributes[0].port) && <><LocationOnIcon sx={{ verticalAlign: 'text-bottom', fontSize: '15px' }} /> {props.vesselData.vessel_attributes[0].port.port_name}</>}
                    {/* {(props.vesselData.type == 2 && props.vesselAttrData.city) && <><LocationOnIcon fontSize='small' sx={{ verticalAlign: 'text-bottom', fontSize: '14' }} /> {props.vesselAttrData.city.region_name}</>} | &nbsp; */}
                    {(props.vesselData.type == 2 && props.vesselData.vessel_attributes[0].city) && <><LocationOnIcon fontSize='small' sx={{ verticalAlign: 'text-bottom', fontSize: '14' }} /> {props.vesselData.vessel_attributes[0].city.region_name}</>} | &nbsp;
                    {/* {props.vesselAttrData.quality} */}
                    {props.vesselData?.quality != null
                        ? ` ${props.vesselData?.quality}`
                        : props.vesselData?.quality == null
                            ? ` ${props.vesselAttrData?.quality}`
                            : ''}{' '}
                    {
                        props.vesselData.quality_type == 1 ? "GAR"
                            : props.vesselData.quality_type == 2 ? "GAD"
                                : props.vesselData.quality_type == 3 && "NAR"
                    }
                    (Kcal/kg)
                </Box>
                {props.vesselData.type == 1 &&
                    <Box className={classes.gridCol2Icon} mb={.55}>
                        <Tooltip title='Vessels Name'
                            aria-label='Vessels Name'>
                            <ShipIcon />
                        </Tooltip>
                        <Typography component='strong' variant='subtitle2'>
                            {props.vesselDetails?.vessel_name}
                        </Typography>
                    </Box>
                }
            </Box>

            {props.matchesViewport &&
                <Box sx={{ display: 'grid', gridTemplateColumns: "1fr 1fr 1fr", textAlign: 'center', fontWeight: 'bold', mt: 1.8 }}>
                    <Box sx={{ fontSize: '14px', fontWeight: '600' }} textAlign="left">
                        <small className={classes.textMuted}>
                            Offer Price</small>
                        <br />
                    </Box>
                    <Box sx={{ fontSize: '14px', fontWeight: '600' }}>
                        {props.vesselData.currency} {!props?.loginData
                            ?props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.price && props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.price !== 0 
                            ? props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.price.toString().slice(0, -3) + "***":""  
                            : props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.price && props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.price !== 0 ?
                             props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.price : "NA"}{'/MT'}
                    </Box>
                    <Box textAlign="center">
                        <Button
                            variant='contained'
                            disableElevation
                            color='primary'
                            size="small"
                            disabled={(props.vesselData?.find_prices?.seller_chat_data[0]?.chat_company_id == props.user?.active_company || props.vesselData?.find_prices?.seller_chat_data[0]?.chat_user_id == props.user?.id) ? true : false}
                            className={classes.btnBuyer}
                            onClick={e => {
                                if(!props?.loginData){
                                    openDialogBox()
                                }else{
                                    setchatData({
                                        show: true,
                                        listing_id: props.vesselData?.vessel_attributes[props.vesselData.vessel_attributes.findIndex(m => m.vessel_name == props.vesselDetails?.vessel_name)]?.multiple_products[0]?.id,
                                        chat_company_id: props.vesselData?.find_prices?.seller_chat_data[0]?.chat_company_id,
                                        badges_data: props.vesselPriceSeller[0]?.user,
                                        badges_label: "Seller Info",
                                        brandStore: true,
                                    }
                                    );
                                }
                               
                            }}
                        >
                            BID
                        </Button>
                    </Box>

                </Box>
            }
            {
                chatData.show && (
                    <ChatModalV3
                        key={chatData.listing_id}
                        data={chatData}
                        onClose={e => setchatData({})}
                    />
                )
            }
            <Dialog
                open={openDialog === "expireModal"}
                TransitionComponent={Transition}
                keepMounted
                //maxWidth="sm"
                onClose={e => {
                    handleCloseDialog(e);
                    if (expireSuccess) {
                        props.onExpireListing();
                    }
                }}
            >
                {expireSuccess && (
                    <Box style={{ maxWidth: "300px" }}>
                        <ThemeIconButton
                            aria-label="close"
                            className="closeBtn"
                            onClick={e => {
                                props.onExpireListing()
                                handleCloseDialog(e);
                            }}
                            to="/dashboard/expired-listings"
                            component={Link}
                        >
                            <img src="/images/cross-icon.svg" alt="Cancel" />
                        </ThemeIconButton>
                        <DialogTitle
                            align="center"
                            className={`${"text-white"}`}
                        >
                            <img src="/images/expired-image.svg" alt="Expired" />
                        </DialogTitle>
                        <DialogContent>
                            <Box textAlign="center" mb={1}>
                                <Typography variant="h5">Listing expired</Typography>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle1">
                                    Your Listing ID {selectedListingID} has been
                                    expired!
                                </Typography>
                            </Box>
                            <Box mb={2}>
                                <ThemeButton
                                    onClick={e => {
                                        props.onExpireListing()
                                        handleCloseDialog(e);
                                    }}
                                    to="/dashboard/expired-listings"
                                    component={Link}
                                    disableElevation
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    fullWidth
                                >
                                    Close
                                </ThemeButton>
                            </Box>
                        </DialogContent>
                    </Box>
                )}
                {!expireSuccess && (
                    <Box style={{ maxWidth: "300px" }}>

                        <DialogContent>
                            <Box>
                                <Box mb={2} fontWeight="bold" fontSize={16}>Are you Sure you want to expire listing?</Box>
                                <Box fontSize={14} className="textMuted">Listing
                                    ID: {selectedListingID}

                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center", padding: "24px", paddingTop: "0px" }}>
                            <Grid container spacing={1}>

                                <Grid item xs={6}>
                                    <ThemeButton
                                        onClick={handleCloseDialog}
                                        //variant="contained"
                                        color="inherit"
                                        type="text"
                                        style={{ width: "100%" }}
                                    >
                                        NO THANKS
                                    </ThemeButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <ThemeButton
                                        onClick={e => expireAction()}
                                        color="primary"
                                        //variant="light"
                                        type="text"
                                        className={expireButtonClassname}
                                        disabled={expireLoader}
                                        style={{ width: "100%" }}
                                    >
                                        {expireLoader && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}{" "} &nbsp;
                                        YES EXPIRE IT
                                    </ThemeButton>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Box>
                )}
            </Dialog>
            {!props?.loginData && <Dialog
 onClose = {handleCloseL} open = {openDialogL}
  aria-labelledby="form-dialog-title"
>

  <LoginForm
  brandStore={true}
  />
</Dialog> }
        </Box>

    )
};