import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { loader } from "../../redux/actions/commonActions";
import { Product } from "../../api/resources/Product";
import ThemeButton from "../partials/ThemeButton";
import {
  Container,
  Grid,
  Box,
  Paper,
  Tabs,
  CircularProgress,
  Dialog,
  Tab,
  IconButton,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  Typography,
  Button,
  Slide,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Close";
import { ValidatorForm } from "react-material-ui-form-validator";
import EditAddress from "../requirement/partials/EditAddress";
import AnimatedSuccess from "../partials/AnimatedSuccess";
import Quantity from "./partials/Quantity";
import AddIcon from "@mui/icons-material/Add";
import OfferPrice from "./partials/OfferPrice";
import PaymentTerms from "./partials/PaymentTerms";
import LiftingDays from "./partials/LiftingDays";
import Expiry from "./partials/Expiry";
import Address from "./partials/Address";
import AdvanceBalance from "./partials/AdvanceBalance";
// import Hidden from '@mui/material/Hidden';
import { fireAdCreditQTYListingBuySellNow } from "../../helpers";
import { useSelector } from "react-redux";
import { addDays, endOfDay } from "date-fns";
import dateformat from "dateformat";
import Emitter from "../../services/emitter";
import { Navigate, Link } from "react-router-dom";
import NumberOfCreditDays from "./partials/NumberOfCreditDays";
import NumberOfPaymentDays from "./partials/NumberOfPaymentDays";
import CurrencyValidationDialog from "../partials/chat/CurrencyValidationDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paperSpacing: {
    // color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2.5, 0),
    },
  },
  buttonLoaderWrapper: {
    // margin: theme.spacing(1),
    position: "relative",
    marginRight: 0,
  },
  tabItems: {
    "& .MuiTab-textColorPrimary.Mui-selected": {
      fontWeight: "bold",
    },
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    textAlign: "center",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  vesselInfoCard: {
    maxWidth: "320px",
    padding: "15px",
    backgroundColor: theme.palette.common.white,
  },
  gridHeader: {
    display: "grid",
    gridTemplateColumns: "16px auto 70px",
    // justifyItems: 'center',
    alignItems: "center",
  },
  fs12Grey: {
    fontSize: "12px",
    color: "#6a6a6a",
  },
  gridRating: {
    display: "grid",
    gridTemplateColumns: "auto 100px auto",
    alignItems: "center",
  },
  fsSmall: {
    fontSize: "10px",
  },
  tag: {
    width: "64px",
    height: "20px",
    lineHeight: "20px",
    borderRadius: "15px",
    backgroundColor: "#f2f3f5",
    textAlign: "center",
    display: "inline-block",
    marginLeft: "6px",
  },
  action: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "5px",
  },
  labelColor: {
    // color: '#0645ad',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  grayBackground: {
    backgroundColor: "#f2f3f5",
  },

  dialogHeader: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    "& h2": {
      fontSize: 24,
      fontWeight: "bolder",
    },
  },
  fontSizeXs: {
    fontSize: "0.75rem",
  },
  p0: {
    padding: "0 !important",
  },

  specialRequestData: {
    backgroundColor: "#f2f5fb",
    padding: theme.spacing(0.5, 1),
    // display: 'flex',
    // justifyContent: 'space-between',
    borderRadius: "4px",
    position: "relative",
    "& .edit": {
      visibility: "hidden",
      position: "absolute",
      right: "5px",
      top: "2px",
    },
    "&:hover > .edit": {
      visibility: "visible",
    },
  },
}));

/**
 *
 * @name BuySell
 * @alias BuySell
 * @component
 * @exports BuySell
 *
 * @description
 * BuySell component to post rfq/listing
 */

function BuySell(props) {
  //console.log(props.marketData.is_auction == true ? 0.5 : 24)
  const classes = useStyles();
  const matchesViewport = useMediaQuery((theme) =>
    theme.breakpoints.only("xs")
  );
  const userReducer = useSelector((state) => state.userReducer);
  const user = useSelector((state) => state.userReducer.user);
  const company = useSelector((state) => state.userReducer.company);
  const states = useSelector((state) => state.commonReducer.states);
  const globalSetting = useSelector(
    (state) => state.commonReducer.globalSettings
  );
  const paymentTerms = useSelector((state) => state.commonReducer.paymentTerms);
  const [open, setOpen] = React.useState(false);
  const [coalPortList, setCoalPortList] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [regionId, setRegionId] = React.useState("");
  const [port, setPort] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  //const [price, setPrice] = React.useState("");
  const [paymentTermId, setPaymentTermId] = React.useState("");
  const [liftingDays, setLiftingDays] = React.useState(
    props.marketData.is_auction == true ? 4 : ""
  );
  const [expiry, setExpiry] = React.useState(
    props.marketData.is_auction == true ? 0.5 : 24
  );
  const [rfqSuccessLoader, setRfqSuccessLoader] = useState(false);
  const [originOfCoal, setOriginOfCoal] = React.useState("");
  const [coalType, setCoalType] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [advance, setAdvance] = React.useState(0);
  const [balance, setBalance] = React.useState("");
  const [numberOfCreditDays, setNumberOfCreditDays] = React.useState("30");
  const [numberOfDays, setNumberOfDays] = React.useState("");
  const [vesselAttributeId, setVesselAttributeId] = React.useState("");
  const [tempAddresses, setTempAddresses] = useState([]);
  //const [value, setValue] = React.useState("seller");
  const [calorificValue, setCalorificValue] = React.useState("");
  const [calorificType, setCalorificType] = React.useState("");
  const [specialRequests, setSpecialRequests] = React.useState("");
  const [filledAddress, setFilledAddress] = React.useState("");
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [mineName, setMineName] = React.useState("");
  const [miningCompany, setMiningCompany] = React.useState("");
  const [manufactureName, setManufactureName] = React.useState("");
  const [madeFrom, setMadeFrom] = React.useState("");
  const [shape, setShape] = React.useState("");
  const [refinery, setRefinery] = React.useState("");
  const [ashValue, setAshValue] = React.useState("");
  const [sulphurValue, setSulphurValue] = React.useState("");
  const [volatileMatterValue, setVolatileMatterValue] = React.useState("");
  const [sizeRangeFromValue, setSizeRangeFromValue] = React.useState("");
  const [sizeRangeToValue, setSizeRangeToValue] = React.useState("");
  const [editAddressId, setEditAddressId] = React.useState("");
  const [editAddress, setEditAddress] = React.useState("");
  const [editPincode, setEditPincode] = React.useState("");
  const [editCity, setEditCity] = React.useState("");
  const [editState, setEditState] = React.useState("");
  const [editAddressSuccess, setEditAddressSuccess] = React.useState(false);
  const [currencyValidationDialog, currencyValidationDialogSet] =
    React.useState(false);
  const [automateChatDialog, automateChatDialogSet] = React.useState({
    show: false,
    data: {},
  });
  const [doublePriceEAuctionDialog, doublePriceEAuctionDialogSet] =
    React.useState({ show: false, data: {} });
  const [isDialogMessage, isDialogMessageSet] = React.useState("");
  const [gotoPlanPage, gotoPlanPageSet] = React.useState(false);

  React.useEffect(() => {
    if (user.ad_credits >= quantity) {
      isDialogMessageSet(
        props.tabValue == "buyer"
          ? "Which means, for next 30 minutes, any exactly matching offer will get automatically accepted on your behalf."
          : "Which means, for next 30 minutes, any exactly matching bid will get automatically accepted on your behalf."
      );
      gotoPlanPageSet(false);
    } else {
      isDialogMessageSet(
        `You are just ${quantity - user.ad_credits
        } Ad Credits away from creating a HOT listing.`
      );
      gotoPlanPageSet(true);
    }
  }, [props.tabValue, quantity, user.ad_credits]);

  const handleChange = (event, newValue) => {
    props.setTabValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * @function fetchData
   * @name fetchData
   * @description
   * Function to fetch the static data like coal ports list, vessel list, temp addresses list, eyard groups , old rfq info, mining companies list, states list
   *
   */

  const fetchData = async () => {
    props.loader(true);
    try {
      const coalPortsRes = await Product.getCoalPorts({ token: user.token });
      setCoalPortList(coalPortsRes.data.data);
      props.loader(false);
    } catch (error) {
      console.error(error);
    }
    props.loader(false);
  };

  const fetchAddressesData = async () => {
    try {
      let d = {};
      if (user) {
        d = { token: user.token };
      }
      const tempAddressesRes = await Product.allTempAddressForRfq(d);
      if (tempAddressesRes.data.status == "success") {
        setTempAddresses(tempAddressesRes.data.data);
      } else {
        if (
          tempAddressesRes.data.status == "fail" &&
          tempAddressesRes.data.message ==
          "Company Not Found Or Not Added Yet Please."
        ) {
          <Navigate to="/add-company-eKyc" />;
        }
        setTempAddresses([]);
      }

      props.loader(false);
    } catch (error) {
      console.error(error);
    }
    props.loader(false);
  };

  useEffect(() => {
    fetchAddressesData();
    fetchData();
  }, [user.token, user.active_company]);

  useEffect(() => {
    if (
      (props.marketData.is_auction == true &&
        (props.vesselPriceSeller == null ||
          props.vesselPriceSeller.length == 0)) ||
      (props.marketData.is_auction == false && user.user_type != 1)
    ) {
      props.setTabValue("seller");
    }
    if (
      (props.marketData.is_auction == true &&
        props.vesselPriceSeller != null &&
        props.vesselPriceSeller.length > 0) ||
      (props.marketData.is_auction == false && user.user_type == 1)
    ) {
      props.setTabValue("buyer");
    }
  }, [props.marketData, props.vesselPriceSeller]);

  useEffect(() => {
    let root =
      props.portId &&
      coalPortList.length > 0 &&
      coalPortList.find((p) => p.id == props.portId);
    let portName =
      root && root.port_name && root.port_name !== null ? root.port_name : "";
    let regionName =
      root &&
        root.region_city &&
        root.region_city.region_name &&
        root.region_city.region_name !== null
        ? root.region_city.region_name
        : "";
    let stateName =
      root &&
        root.region_state &&
        root.region_state.region_name &&
        root.region_state.region_name !== null
        ? root.region_state.region_name
        : "";
    let zip = root && root.pincode && root.pincode !== null ? root.pincode : "";

    if (props.marketData) {
      if (props.marketData.type == "1") {
        setOriginOfCoal("2");
        setPort(props.portId);
        setAddress(
          props.portId &&
          coalPortList.length > 0 &&
          coalPortList.find((p) => p.id == props.portId).location
        );
        setRegionId(
          props.portId &&
          coalPortList.length > 0 &&
          coalPortList.find((p) => p.id == props.portId).city_id
        );
        setPincode(
          props.portId &&
          coalPortList.length > 0 &&
          coalPortList.find((p) => p.id == props.portId).pincode
        );
        setFilledAddress(`${portName}, ${regionName}, ${stateName}, ${zip}`);
      } else {
        setOriginOfCoal("1");
        setMiningCompany(props.marketData.mining_company_id);
        setMineName(props.marketData.mine);
        if (tempAddresses && tempAddresses.length > 0) {
          setAddress(
            tempAddresses && tempAddresses[selectedAddressIndex].address
          );
          setRegionId(
            tempAddresses && tempAddresses[selectedAddressIndex].region_id
          );
          setPincode(
            tempAddresses && tempAddresses[selectedAddressIndex].pincode
          );
          setFilledAddress(
            `${tempAddresses[selectedAddressIndex].address}, ${tempAddresses[selectedAddressIndex].state}, ${tempAddresses[selectedAddressIndex].city}, ${tempAddresses[selectedAddressIndex].pincode}`
          );
          setEditAddressId(
            tempAddresses && tempAddresses[selectedAddressIndex].id
          );
          setEditAddress(
            tempAddresses && tempAddresses[selectedAddressIndex].address
          );
          setEditPincode(
            tempAddresses && tempAddresses[selectedAddressIndex].pincode
          );
          setEditCity(
            tempAddresses && tempAddresses[selectedAddressIndex].region_id
          );
          setEditState(
            tempAddresses &&
            tempAddresses[selectedAddressIndex].region.parent_id
          );
        }
      }
      setCountry(props.marketData.orginate);
      setCoalType(props.marketData.coal_type);
      setVesselAttributeId(props.marketAttrData?.id);
      setCalorificValue(props.marketAttrData?.quality);
      setCalorificType(props.marketData?.quality_type);
      if (
        props.marketDetails &&
        !props.loadingVesselPrice &&
        props.marketData?.find_prices?.best_buyer_price != 0 &&
        props.tabValue == "buyer"
      ) {
        props.setPrice(
          parseFloat(props.marketData?.find_prices?.best_buyer_price) +
          parseFloat(5)
        );
        setQuantity(props.marketData?.find_prices?.buyer_chat_data[0].quantity);
        setLiftingDays(
          props.marketData?.find_prices?.buyer_chat_data[0].price_data.days
        );
        setNumberOfDays(
          props.marketData?.find_prices?.buyer_chat_data[0].price_data.days
        );
        setPaymentTermId(
          props.marketData?.find_prices?.buyer_chat_data[0].price_data
            .payment_term_id
        );
        setAdvance(
          props.marketData?.find_prices?.buyer_chat_data[0].cc_percentage
        );
      } else if (
        props.marketDetails &&
        !props.loadingVesselPrice &&
        props.marketData?.find_prices?.best_seller_price != 0 &&
        props.tabValue == "seller"
      ) {
        props.setPrice(props.marketData.is_auction == true ? '' :
          parseFloat(props.marketData?.find_prices?.best_seller_price) -
          parseFloat(5)
        );
        setQuantity(props.marketData.is_auction == true ? '' :
          props.marketData?.find_prices?.seller_chat_data[0].quantity
        );
        setLiftingDays(
          props.marketData?.find_prices?.seller_chat_data[0].price_data.days
        );
        setNumberOfDays(
          props.marketData?.find_prices?.seller_chat_data[0].price_data.days
        );
        setPaymentTermId(
          props.marketData?.find_prices?.seller_chat_data[0].price_data
            .payment_term_id
        );
        setAdvance(
          props.marketData?.find_prices?.seller_chat_data[0].cc_percentage
        );
      } else if (
        props.marketData.is_auction == true &&
        props.vesselPriceSeller?.length > 0
      ) {
        props.setPrice("");
        setQuantity(
          props.marketData?.find_prices?.seller_chat_data[0]?.quantity
        );
        setLiftingDays(4);
        setNumberOfDays(4);
        setPaymentTermId(6);
        setExpiry(0.5);
        setAdvance(
          props.marketData?.find_prices?.seller_chat_data[0]?.cc_percentage
        );
      } else {
        props.setPrice("");
        setQuantity("");
        setLiftingDays("");
        setNumberOfDays("");
        setPaymentTermId("");
        setExpiry(24);
        setAdvance(0);
      }

      if (props.marketData.is_auction == true) {
        setLiftingDays(4);
        setNumberOfDays(4);
        setExpiry(0.5);
      }
    }
  }, [
    props.marketData,
    props.marketAttrData,
    tempAddresses,
    props.portId,
    props.tabValue,
    props.loadingVesselPrice,
    props.marketDetails,
    coalPortList,
  ]);

  /**
   * @function submitRfqForm
   * @name submitRfqForm
   * @description
   * Function to store the rfq/ listing form details
   *
   */

  const submitRfqForm = (e) => {
    if (
      props.marketData.is_auction == true &&
      props.tabValue == "seller" &&
      (props.vesselPriceSeller == null || props.vesselPriceSeller?.length == 0)
    ) {
      if (
        (user?.country_mobile_prefix != "+91" &&
          props.vesselInfo &&
          props.vesselInfo.vessel_currency &&
          props.vesselInfo.vessel_currency != "INR") ||
        (user?.country_mobile_prefix == "+91" &&
          props.vesselInfo &&
          props.vesselInfo.vessel_currency)
      ) {
        setRfqSuccessLoader(true);

        var expiryDateTime =
          expiry === 0.15
            ? new Date().setMinutes(new Date().getMinutes() + 15)
            : expiry === 0.5
              ? new Date().setMinutes(new Date().getMinutes() + 30)
              : expiry === 1
                ? new Date().setHours(new Date().getHours() + 1)
                : expiry === 2
                  ? new Date().setHours(new Date().getHours() + 2)
                  : expiry === 4
                    ? new Date().setHours(new Date().getHours() + 4)
                    : expiry === 8
                      ? new Date().setHours(new Date().getHours() + 8)
                      : expiry === 24
                        ? endOfDay(new Date())
                        : expiry === 48
                          ? endOfDay(addDays(new Date(), +1))
                          : expiry === 7
                            ? endOfDay(addDays(new Date(), +7))
                            : expiry === 15
                              ? endOfDay(addDays(new Date(), +15))
                              : expiry === 30 && endOfDay(addDays(new Date(), +30));

        expiryDateTime = dateformat(expiryDateTime, "yyyy-mm-dd HH:MM:ss");

        let data = {
          user_id: user.id,
          token: user.token,
          company_id: user.active_company,
          dealer_id: "",
          product_type: props.tabValue == "seller" ? 2 : 1,
          origin_of_coal: originOfCoal,
          coal_type_id_fk: coalType,
          country_id_fk: originOfCoal == 2 ? country : 1,
          vessel_attribute_id: vesselAttributeId && vesselAttributeId,
          calorific_value_type:
            coalType == 1 || coalType == 3 || coalType == 5
              ? calorificType
              : null,
          calorific_value:
            coalType == 1 || coalType == 3 || coalType == 5
              ? calorificValue
              : null,
          mining_company_id_fk:
            originOfCoal == 1 && (coalType == 1 || coalType == 2)
              ? miningCompany
              : null,
          mine:
            originOfCoal == 1 && (coalType == 1 || coalType == 2)
              ? mineName
              : null,
          manufacturer_name:
            originOfCoal == 1 && coalType == 4 ? manufactureName : null,
          coal_form_id: shape,
          made_from:
            originOfCoal == 1 && coalType == 5
              ? madeFrom
              : originOfCoal == 1 && (coalType == 4 || coalType == 5)
                ? manufactureName
                : originOfCoal == 1 && coalType == 3
                  ? refinery
                  : null,
          made_from_type:
            originOfCoal == 1 && coalType == 5
              ? "1"
              : originOfCoal == 1 && (coalType == 4 || coalType == 5)
                ? "2"
                : originOfCoal == 1 && coalType == 3
                  ? "3"
                  : null,
          ash: coalType == 2 || coalType == 4 ? ashValue : null,
          sulphur: coalType == 2 || coalType == 3 ? sulphurValue : null,
          volatile_matter: coalType == 2 ? volatileMatterValue : null,
          size_from: coalType == 4 ? sizeRangeFromValue : null,
          size_to: coalType == 4 ? sizeRangeToValue : null,
          quantity: quantity,
          address: address,
          region_id: regionId,
          pin_code: pincode,
          special_requests: specialRequests,
          visibility: 0,
          marketplace_id: props.marketData?.market_place?.id,
          price_list_price: props.price,
          price_list_payment_term_id: paymentTermId,
          cc_percentage:
            paymentTermId == 1 || paymentTermId == 4 ? advance : null,
          no_payment_days: paymentTermId == 4 ? numberOfDays : null,
          num_credit_days:
            props.marketData.is_auction == true
              ? ""
              : paymentTermId == 2 || paymentTermId == 3
                ? numberOfCreditDays
                : "",
          price_list_days:
            props?.marketData?.is_auction == true &&
              props.vesselPriceSeller != null
              ? props.vesselPriceSeller[0].days
              : liftingDays,
          price_list_validity: expiryDateTime,
          validity: expiryDateTime,
          is_internal: 0,
          status: 1,
        };
        Product.postProductSavingForm(data)
          .then((response) => {
            if (response.data.status == "success") {
              setRfqSuccessLoader(false);
              setQuantity("");
              props.setPrice("");
              setPaymentTermId("");
              setLiftingDays("");
              setNumberOfDays("");
              //setExpiry(props.marketData.is_auction == true ? 0.5 : 24);

              setSpecialRequests("");
              setSelectedAddressIndex(0);
              props.setTabValue("buyer");
              setTimeout(() => {
                Emitter.emit("SNACKBAR_NOTIFICATION", {
                  message: `Listing posted successfully!`,
                  params: { variant: "success" },
                });
              }, 300);

              props.onAddListing();
            } else {
              setRfqSuccessLoader(false);
            }

            if (response.data.status === "fail") {
              // setTimeout(() => {
              //     Emitter.emit('SNACKBAR_NOTIFICATION', { message: `Sorry, You are not allowed to post the listing in E-Auction contract.`, params: { variant: 'success' } });
              // }, 500);
              // else if (props.marketData.is_auction == true && props.tabValue == "seller" && (props.vesselPriceSeller?.length > 0)) {
              //     setTimeout(() => {
              //         Emitter.emit('SNACKBAR_NOTIFICATION', { message: `You can only post one seller listing.`, params: { variant: 'success' } });
              //     }, 500);
              // }
              setTimeout(() => {
                Emitter.emit("SNACKBAR_NOTIFICATION", {
                  message: response.data.message,
                  params: { variant: "error" },
                });
              }, 500);
            }
          })
          .finally(() => { });
      } else {
        currencyValidationDialogSet(true);
      }
    } else {
      if (
        (user?.country_mobile_prefix != "+91" &&
          props.vesselInfo &&
          props.vesselInfo.vessel_currency &&
          props.vesselInfo.vessel_currency != "INR") ||
        (user?.country_mobile_prefix == "+91" &&
          props.vesselInfo &&
          props.vesselInfo.vessel_currency)
      ) {
        setRfqSuccessLoader(true);

        var expiryDateTime =
          expiry === 0.15
            ? new Date().setMinutes(new Date().getMinutes() + 15)
            : expiry === 0.5
              ? new Date().setMinutes(new Date().getMinutes() + 30)
              : expiry === 1
                ? new Date().setHours(new Date().getHours() + 1)
                : expiry === 2
                  ? new Date().setHours(new Date().getHours() + 2)
                  : expiry === 4
                    ? new Date().setHours(new Date().getHours() + 4)
                    : expiry === 8
                      ? new Date().setHours(new Date().getHours() + 8)
                      : expiry === 24
                        ? endOfDay(new Date())
                        : expiry === 48
                          ? endOfDay(addDays(new Date(), +1))
                          : expiry === 7
                            ? endOfDay(addDays(new Date(), +7))
                            : expiry === 15
                              ? endOfDay(addDays(new Date(), +15))
                              : expiry === 30 && endOfDay(addDays(new Date(), +30));

        expiryDateTime = dateformat(expiryDateTime, "yyyy-mm-dd HH:MM:ss");

        let data = {
          user_id: user.id,
          token: user.token,
          company_id: user.active_company,
          dealer_id: "",
          product_type: props.tabValue == "seller" ? 2 : 1,
          origin_of_coal: originOfCoal,
          coal_type_id_fk: coalType,
          country_id_fk: originOfCoal == 2 ? country : 1,
          vessel_attribute_id: vesselAttributeId && vesselAttributeId,
          calorific_value_type:
            coalType == 1 || coalType == 3 || coalType == 5
              ? calorificType
              : null,
          calorific_value:
            coalType == 1 || coalType == 3 || coalType == 5
              ? calorificValue
              : null,
          mining_company_id_fk:
            originOfCoal == 1 && (coalType == 1 || coalType == 2)
              ? miningCompany
              : null,
          mine:
            originOfCoal == 1 && (coalType == 1 || coalType == 2)
              ? mineName
              : null,
          manufacturer_name:
            originOfCoal == 1 && coalType == 4 ? manufactureName : null,
          coal_form_id: shape,
          made_from:
            originOfCoal == 1 && coalType == 5
              ? madeFrom
              : originOfCoal == 1 && (coalType == 4 || coalType == 5)
                ? manufactureName
                : originOfCoal == 1 && coalType == 3
                  ? refinery
                  : null,
          made_from_type:
            originOfCoal == 1 && coalType == 5
              ? "1"
              : originOfCoal == 1 && (coalType == 4 || coalType == 5)
                ? "2"
                : originOfCoal == 1 && coalType == 3
                  ? "3"
                  : null,
          ash: coalType == 2 || coalType == 4 ? ashValue : null,
          sulphur: coalType == 2 || coalType == 3 ? sulphurValue : null,
          volatile_matter: coalType == 2 ? volatileMatterValue : null,
          size_from: coalType == 4 ? sizeRangeFromValue : null,
          size_to: coalType == 4 ? sizeRangeToValue : null,
          quantity: quantity,
          address: address,
          region_id: regionId,
          pin_code: pincode,
          special_requests: specialRequests,
          visibility: 0,
          marketplace_id: props.marketData?.market_place?.id,
          price_list_price: props.price,
          price_list_payment_term_id: paymentTermId,
          cc_percentage:
            paymentTermId == 1 || paymentTermId == 4 ? advance : null,
          no_payment_days: paymentTermId == 4 ? numberOfDays : null,
          num_credit_days:
            props.marketData.is_auction == true
              ? ""
              : paymentTermId == 2 || paymentTermId == 3
                ? numberOfCreditDays
                : "",
          price_list_days:
            props?.marketData?.is_auction == true &&
              props.vesselPriceSeller != null
              ? props.vesselPriceSeller[0].days
              : liftingDays,
          price_list_validity: expiryDateTime,
          validity: expiryDateTime,
          is_internal: 0,
          status: 1,
        };
        Product.postProductSavingForm(data)
          .then((response) => {
            if (response.data.status == "success") {
              setRfqSuccessLoader(false);
              setQuantity("");
              props.setPrice("");
              setPaymentTermId("");
              setLiftingDays("");
              setNumberOfDays("");
              //setExpiry(props.marketData.is_auction == true ? 0.5 : 24);
              setSpecialRequests("");
              setSelectedAddressIndex(0);
              setTimeout(() => {
                Emitter.emit("SNACKBAR_NOTIFICATION", {
                  message: `Listing posted successfully!`,
                  params: { variant: "success" },
                });
              }, 500);
              props.onAddListing();
            } else {
              setRfqSuccessLoader(false);
            }

            if (response.data.status === "fail") {
              setTimeout(() => {
                Emitter.emit("SNACKBAR_NOTIFICATION", {
                  message: response.data.message,
                  params: { variant: "error" },
                });
              }, 500);
            }
          })
          .finally(() => { });
      } else {
        currencyValidationDialogSet(true);
      }
    }
  };

  /**
   * @function storeEditAddressAction
   * @name storeEditAddressAction
   * @description
   * Function to edit the existing address
   *
   */

  const storeEditAddressAction = (e) => {
    props.loader(true);
    let data = {
      company_id: user.active_company,
      id: editAddressId,
      city: editCity,
      pin_code: editPincode,
      address: editAddress,
      token: user.token,
    };

    Product.storeAddressRfq(data)
      .then((response) => {
        if (response.data.status === "success") {
          setEditAddressSuccess(true);
          Product.allTempAddressForRfq({
            token: user.token,
          }).then((response) => {
            setTempAddresses(response.data.data);
            setSelectedAddressIndex(1);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        props.loader(false);
      });
  };

  return (
    <Box>
      {/* <Container maxWidth="md"> */}
      <Grid container spacing={2}>
        <Grid item sm xs={12}>
          <Box my={1}>
            <Paper elevation={2} className={classes.paperSpacing}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={props.tabValue}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  variant="scrollable"
                  className={`${props.user_mode == 1
                    ? "buyerTheme-Tabs"
                    : "sellerTheme-Tabs"
                    } ${classes.tabItems}`}
                >
                  {/* {((props.marketData.is_auction == true &&
                    props.vesselPriceSeller?.length > 0) ||
                    props.marketData.is_auction == false) && (
                      <Tab label="Buy" value="buyer" />
                    )} */}
                  {((props.marketData.is_auction == true &&
                    (props.vesselPriceSeller == null ||
                      props.vesselPriceSeller?.length == 0)) ||
                    props.marketData.is_auction == false) && (
                      <Tab label="Sell" value="seller" />
                    )}
                </Tabs>
              </Box>
              <ValidatorForm
                onSubmit={(e) => {
                  props.marketData.is_auction == true &&
                    props.vesselPriceBuyer != null &&
                    props.vesselPriceBuyer?.length > 0 &&
                    props.price > props.vesselPriceBuyer[0]?.price_value * 2
                    ? doublePriceEAuctionDialogSet({ show: true })
                    : props.marketData.is_auction == true
                      ? submitRfqForm()
                      : automateChatDialogSet({ show: true });
                }}
                noValidate
              >
                <Container
                  component="main"
                  maxWidth="sm"
                  className={props.isMarketPage == true && `${classes.p0}`}
                >
                  <Grid container spacing={2} alignItems="self-start">
                    <Grid
                      item
                      sm={
                        props.marketData.is_auction == true &&
                          props.tabValue == "buyer"
                          ? 6
                          : 4
                      }
                      xs={12}
                    >
                      <Quantity
                        quantity={quantity}
                        setQuantity={setQuantity}
                        user={user}
                        minimumOrderQuantity={props.minimumOrderQuantity}
                        quantityTickSize={props.quantityTickSize}
                        quantityRangeMax={props.quantityRangeMax}
                        quantityRangeMin={props.quantityRangeMin}
                        isValidQuantity={props.isValidQuantity}
                        setIsValidQuantity={props.setIsValidQuantity}
                        marketData={props.marketData}
                        vesselPriceSeller={props.vesselPriceSeller}
                      />
                    </Grid>

                    <Grid
                      item
                      sm={
                        props.marketData.is_auction == true &&
                          props.tabValue == "buyer"
                          ? 6
                          : 4
                      }
                      xs={12}
                    >
                      <OfferPrice
                        key={props.tabValue}
                        originOfCoal={originOfCoal}
                        country={country}
                        user={user}
                        listingType={props.tabValue}
                        price={props.price}
                        setPrice={props.setPrice}
                        priceRange={props.priceRange}
                        tickSize={globalSetting.tick_size}
                        vesselInfo={props.vesselInfo}
                        priceTickSize={props.priceTickSize}
                        isValidPrice={props.isValidPrice}
                        setIsValidPrice={props.setIsValidPrice}
                        marketData={props.marketData}
                        vesselPriceSeller={props.vesselPriceSeller}
                        vesselPriceBuyer={props.vesselPriceBuyer}
                      />
                    </Grid>
                    {(props.marketData.is_auction != true ||
                      (props.marketData.is_auction == true &&
                        props.vesselPriceSeller == null)) && (
                        <Grid item sm={4} xs={12}>
                          <PaymentTerms
                            paymentTermId={paymentTermId}
                            setPaymentTermId={setPaymentTermId}
                            paymentTerms={paymentTerms}
                            setAdvance={setAdvance}
                            user={user}
                            marketData={props.marketData}
                            vesselPriceSeller={props.vesselPriceSeller}
                          />
                        </Grid>
                      )}

                    {(paymentTermId == 2 || paymentTermId == 3) && (
                      <Grid item sm={12} xs={12}>
                        <NumberOfCreditDays
                          numberOfCreditDays={numberOfCreditDays}
                          setNumberOfCreditDays={setNumberOfCreditDays}
                        />
                      </Grid>
                    )}

                    {paymentTermId == 4 && (
                      <Grid item sm={8} xs={12}>
                        <AdvanceBalance
                          advance={advance}
                          setAdvance={setAdvance}
                          balance={balance}
                          setBalance={setBalance}
                        />
                      </Grid>
                    )}

                    {paymentTermId == 4 && (
                      <Grid item sm={4} xs={12}>
                        <NumberOfPaymentDays
                          numberOfDays={numberOfDays}
                          setNumberOfDays={setNumberOfDays}
                        />
                      </Grid>
                    )}
                    {(props.marketData.is_auction != true ||
                      (props.marketData.is_auction == true &&
                        props.vesselPriceSeller == null)) && (
                        <Grid item sm={3} xs={12}>
                          <LiftingDays
                            liftingDays={liftingDays}
                            quantity={quantity}
                            marketData={props.marketData}
                            setNumberOfDays={setNumberOfDays}
                            setLiftingDays={setLiftingDays}
                          />
                        </Grid>
                      )}

                    {/* {(props.marketData.is_auction != true ||
                      (props.marketData.is_auction == true &&
                        props.vesselPriceSeller == null)) && (
                        <Grid item sm={3} xs={12}>
                          <Expiry
                            expiry={expiry}
                            setExpiry={setExpiry}
                            marketData={props.marketData}
                            user={user}
                            vesselPriceSeller={props.vesselPriceSeller}
                          />
                        </Grid>
                      )} */}
                    {/* {(props.marketData.is_auction != true ||
                      (props.marketData.is_auction == true &&
                        props.vesselPriceSeller == null)) && (
                        <Grid item sm={6} xs={12}>
                          <Address
                            tempAddresses={tempAddresses}
                            originOfCoal={originOfCoal}
                            vesselAttributeId={vesselAttributeId}
                            filledAddress={filledAddress}
                            port={port}
                            vesselType={props.vesselType}
                            onEditAddressDialog={(e) => {
                              setOpen("editAddressModal");
                            }}
                            setEditAddressId={setEditAddressId}
                            setEditAddress={setEditAddress}
                            setEditPincode={setEditPincode}
                            setEditCity={setEditCity}
                            setEditState={setEditState}
                          />
                        </Grid>
                      )} */}
                    {(props.marketData.is_auction != true ||
                      (props.marketData.is_auction == true &&
                        props.vesselPriceSeller == null)) && (
                        <Grid item sm={12} xs={12}>
                          {!open && specialRequests.length > 0 ? (
                            <Box className={classes.specialRequestData}>
                              <Typography fontSize={13}>
                                {specialRequests}
                              </Typography>
                              <IconButton
                                aria-label="open comment box"
                                className="edit bg-white"
                                size="small"
                                color="primary"
                                onClick={(e) => {
                                  setOpen("specialCommentsModal");
                                }}
                              >
                                <EditIcon size="small" />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box
                              className={`${classes.labelColor} pointer`}
                              onClick={(e) => {
                                setOpen("specialCommentsModal");
                              }}
                            >
                              <AddIcon className="vAlignBottom" /> Add Special
                              Comments
                            </Box>
                          )}
                        </Grid>
                      )}

                    <Grid item sm xs={12} alignSelf="flex-end">
                      <Box className="w-100" textAlign="left">
                        <span className={classes.buttonLoaderWrapper}>
                          <ThemeButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            //size="medium"
                            disableElevation
                            disabled={
                              rfqSuccessLoader ||
                              props.marketDetails === false ||
                              quantity == "" ||
                              props.price == "" ||
                              liftingDays == "" ||
                              paymentTermId == "" ||
                              props.isValidPrice === false ||
                              props.isValidQuantity === false
                            }
                            fullWidth={matchesViewport ? true : false}
                            onClick={(e) => {
                              userReducer.isLoggedIn && userReducer.noCompany
                                ? Emitter.emit("SNACKBAR_NOTIFICATION", {
                                  message: `${"Please add your company first!"}`,
                                  params: { variant: "error" },
                                })
                                : setTimeout(() => {
                                  if (props.marketData.is_auction == true) {
                                    return null;
                                  } else {
                                    // fireAdCreditQTYListingBuySellNow({ uType: props.tabValue, quantity: quantity, adCredits: user.ad_credits });
                                  }
                                }, 1500);
                            }}
                          >
                            SUBMIT YOUR {(props.marketData.is_auction == true &&
                              props.vesselPriceSeller?.length > 0) ? 'BID' : 'PRODUCT'}
                          </ThemeButton>
                          {rfqSuccessLoader && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </ValidatorForm>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {currencyValidationDialog === true && (
        <CurrencyValidationDialog
          show={currencyValidationDialog}
          //currency={props.vesselInfo?.vessel_currency}
          currency={user?.country_mobile_prefix}
          listing={true}
          currencyValidationDialogSet={currencyValidationDialogSet}
        />
      )}

      <Dialog
        open={doublePriceEAuctionDialog.show}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {doublePriceEAuctionDialog && doublePriceEAuctionDialog.show && (
            <>
              <Typography
                variant="body"
                component="p"
                gutterBottom
                lineHeight={2}
              >
                <Box style={{ fontSize: 16 }}>
                  <strong>Hey {user.first_name},</strong>
                </Box>
                <Box style={{ fontSize: 16 }}>
                  Your bid price seems to be double the amount of previous bid
                  price are you sure you want to continue? <br />
                </Box>
              </Typography>

              <Box textAlign={"right"}>
                <Button
                  variant="text"
                  className={classes.btnDefault}
                  onClick={(e) => {
                    doublePriceEAuctionDialogSet({ show: false });
                  }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.btnDefault}
                  onClick={(e) => {
                    submitRfqForm();
                    doublePriceEAuctionDialogSet({ show: false });
                  }}
                >
                  Yes
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={automateChatDialog.show}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {automateChatDialog && automateChatDialog.show && (
            <>
              <Typography
                variant="body"
                component="p"
                gutterBottom
                lineHeight={2}
              >
                <Box style={{ fontSize: 16 }}>
                  <strong>Hey {user.first_name},</strong>
                </Box>
                {user.ad_credits >= quantity ? (
                  <Box style={{ fontSize: 16 }}>
                    {" "}
                    This will be a HOT{" "}
                    <img
                      src="../images/hot-listing.gif"
                      alt="hot"
                      width="18"
                    />{" "}
                    listing.
                    <br />
                    {isDialogMessage} <br />
                  </Box>
                ) : (
                  <Box style={{ fontSize: 16 }}>
                    You are just{" "}
                    <strong>{quantity - user.ad_credits} Ad Credits</strong>{" "}
                    away from creating a HOT{" "}
                    <img src="../images/hot-listing.gif" alt="hot" width="18" />{" "}
                    listing. <br />
                  </Box>
                )}

                {user.ad_credits >= quantity ? (
                  <Box
                    style={{
                      fontSize: "13px",
                      fontStyle: "italic",
                      marginTop: 4,
                    }}
                  >
                    <strong>Note: </strong>You can always expire your listing
                    before 30 min from <strong>My Listings</strong> page.
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      //justifyContent: 'center',
                      //alignItems: 'center',
                      //background: '#d2dcf14a',
                      //color: '#04368a',
                      //borderRadius: '4px',
                      //px: 1,
                      py: 0.5,
                      mx: "auto",
                      mt: 1,
                      fontSize: "13px",
                      fontStyle: "italic",
                      mb: { xs: 1, sm: 1 },
                    }}
                    className={`w-100`}
                  >
                    <Box sx={{ mr: 1 }}>
                      <img
                        src="../images/bulb-icon.svg"
                        alt="info"
                        width="15"
                      />
                    </Box>
                    <Box component="span">
                      {" "}
                      Did you know? A HOT{" "}
                      <img
                        src="../images/hot-listing.gif"
                        alt="hot"
                        width="15"
                      />{" "}
                      listing lead to faster deals.
                    </Box>
                    <br />
                  </Box>
                )}
              </Typography>
              {gotoPlanPage == true ? (
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    component={Link}
                    to="/pricing-plans"
                    onClick={(e) => {
                      automateChatDialogSet({ show: false });
                    }}
                  >
                    Recharge Now
                  </Button>
                  <Button
                    variant="text"
                    className={classes.btnDefault}
                    onClick={(e) => {
                      submitRfqForm();
                      automateChatDialogSet({ show: false });
                    }}
                  >
                    LATER
                  </Button>
                </Box>
              ) : (
                <Box textAlign={"right"}>
                  <Button
                    variant="text"
                    className={classes.btnDefault}
                    onClick={(e) => {
                      submitRfqForm();
                      automateChatDialogSet({ show: false });
                    }}
                  >
                    okay
                  </Button>
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* Special comments dialog */}
      <Dialog
        open={open === "specialCommentsModal"}
        maxWidth="xs"
        fullWidth={true}
        scroll="paper"
        aria-labelledby="Add special comments"
        onClose={(e) => {
          handleClose(e);
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className={`text-center ${classes.dialogHeader}`}
        >
          Add Special Comments
        </DialogTitle>
        <IconButton
          aria-label="close"
          className="closeBtn"
          size="small"
          onClick={(e) => {
            handleClose(e);
          }}
        >
          <CancelIcon />
        </IconButton>
        <DialogContent dividers={true}>
          <Box py={2}>
            <InputLabel className={classes.marginBottom}>
              Special Comments
            </InputLabel>
            <TextField
              name="comments"
              fullWidth
              multiline
              rows="4"
              value={specialRequests}
              onChange={(e) => {
                setSpecialRequests(e.target.value);
              }}
              variant="outlined"
              size="small"
              inputProps={{
                maxlength: globalSetting?.settings_special_comment_length,
              }}
              helperText={
                <Box align="right">
                  Words: {specialRequests?.length}
                  <span style={{ color: "#2264e9" }}>
                    /{globalSetting?.settings_special_comment_length}
                  </span>
                </Box>
              }
            />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <ThemeButton
              color="primary"
              variant="contained"
              disableElevation
              onClick={(e) => {
                handleClose(e);
              }}
            >
              Done
            </ThemeButton>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Create dialog for edit existing address */}
      <Dialog
        open={open === "editAddressModal"}
        scroll="paper"
        aria-labelledby="edit address modal"
        onClose={(e) => {
          handleClose(e);
        }}
      >
        {editAddressSuccess && (
          <>
            <DialogTitle align="center" className={`${"text-white"}`}>
              <AnimatedSuccess />
            </DialogTitle>
            <DialogContent>
              <Box sx={{ textAlign: "center" }} mb={1}>
                <Typography variant="h5">Great!!</Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="subtitle1">
                  Address edited successfully!!
                </Typography>
              </Box>
              <Box mb={2}>
                <ThemeButton
                  style={{
                    background: "#4CAF50",
                    color: "#fff",
                  }}
                  type="button"
                  onClick={(e) => {
                    handleClose(e);
                    setEditAddressSuccess(false);
                  }}
                  variant="contained"
                  disableElevation
                  size="small"
                  fullWidth
                >
                  Done
                </ThemeButton>
              </Box>
            </DialogContent>
          </>
        )}
        {!editAddressSuccess && (
          <>
            <DialogTitle align="center" id="addNewAddress">
              Edit Address
            </DialogTitle>
            <IconButton
              aria-label="close"
              className="closeBtn"
              size="small"
              onClick={(e) => {
                handleClose(e);
              }}
            >
              <CancelIcon />
            </IconButton>
            <ValidatorForm onSubmit={storeEditAddressAction} noValidate>
              <DialogContent dividers={true}>
                <EditAddress
                  states={states}
                  editCity={editCity}
                  editState={editState}
                  editPincode={editPincode}
                  setEditCity={setEditCity}
                  editAddress={editAddress}
                  setEditState={setEditState}
                  setEditAddress={setEditAddress}
                  setEditPincode={setEditPincode}
                  loader={props.loader}
                />
                <Box sx={{ textAlign: "center" }} mt={2} mb={2}>
                  <ThemeButton
                    variant="contained"
                    disableElevation
                    color="primary"
                    type="submit"
                  >
                    Save
                  </ThemeButton>
                </Box>
              </DialogContent>
            </ValidatorForm>
          </>
        )}
      </Dialog>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { loader })(BuySell);
