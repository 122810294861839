import * as React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import InfoIcon from '@mui/icons-material/Info';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
    activeNavItem: {
        color: theme.palette.primary.main,
        '& .MuiBottomNavigationAction-label': {
            fontSize: 11.5
        }

    },
    navItem: {
        // background: '#d4def1',
        color: '#222',
        '& .MuiBottomNavigationAction-label': {
            fontSize: 11.5
        }
    }
}));


export default function BottomTabsNav(props) {

    const classes = useStyles();
    const location = useLocation();
    const ref = React.useRef(null);
    const [value, setValue] = React.useState(null);
    const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
    const user = useSelector((state) => state.userReducer.user);
    let brandStoreId = null;
    if (
      isLoggedIn &&
      window.location?.search != '' &&
      window.location?.search.split('?bs=').length > 1
    ) {
      brandStoreId = JSON.parse(atob(window.location?.search.split('?bs=')[1]));
    }
    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
    }, [value]);

    return (
        <Box ref={ref}>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', zIndex: 1, bottom: 5, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    sx={{ background: '#F2F3F5' }}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    {/* <BottomNavigationAction
                        label="Home"
                        component={Link}
                        to="/"
                        className={location.pathname == '/' ? classes.activeNavItem : classes.navItem}
                        icon={location.pathname == '/' ? <img src="/images/home-active.svg" alt="" style={{ width: 22, marginBottom: 4 }} /> : <img src="/images/home-default.svg" alt="" style={{ width: 22, marginBottom: 4 }} />}
                    /> */}
                     <BottomNavigationAction
                        label="About"
                        to="/brand-stores/about-us"
                        component={Link}
                        className={location.pathname == '/brand-stores/about-us' ? classes.activeNavItem : classes.navItem}
                        icon={<InfoIcon></InfoIcon>}
                    />
                   
                   

                    <BottomNavigationAction
                        label="Orders"
                        component={Link}
                        to="/deals-and-trades"
                        className={location.pathname == '/deals-and-trades' ? classes.activeNavItem : classes.navItem}
                        // icon={location.pathname == '/deals-and-trades' ? <img src="/images/markets-active.svg" alt="" style={{ width: 20, marginBottom: 4 }} /> : <img src="/images/markets-default.svg" alt="" style={{ width: 20, marginBottom: 4 }} />}
                        icon={<DepartureBoardIcon></DepartureBoardIcon>}
                    />
                    {user?.active_company == brandStoreId &&(
 <BottomNavigationAction
 label="Clients"
 to="/brand-stores/add-client"
 component={Link}
 className={location.pathname == '/brand-stores/add-client' ? classes.activeNavItem : classes.navItem}
 icon={<PlaylistAddCheckIcon></PlaylistAddCheckIcon>}
/>
                    )}
                     <BottomNavigationAction
                        label="Products"
                         to="/brand-stores/update-product"
                         component={Link}
                        className={location.pathname == '/brand-stores/update-product' ? classes.activeNavItem : classes.navItem}
                        icon={location.pathname == '/brand-stores/update-product' ? <img src="/images/brandstore-active.png" alt="" style={{ width: 20, marginBottom: 4 }} /> : <img src="/images/brandstore.png" alt="" style={{ width: 20, marginBottom: 4 }} />}
                    />
                   <BottomNavigationAction
                        label="Inbox"
                        component={Link}
                        to="/dashboard/inbox"
                        className={location.pathname == '/dashboard/inbox' ? classes.activeNavItem : classes.navItem}
                        icon={location.pathname == '/dashboard/inbox' ? <img src="/images/inbox-active.svg" alt="" style={{ width: 20, marginBottom: 4 }} /> : <img src="/images/inbox-default.svg" alt="" style={{ width: 20, marginBottom: 4 }} />}
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}