import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

export default function ClickToCopy(props) {

    const [copySuccess, setCopySuccess] = useState(false);
    const textInputRef = useRef(null);

    function copyToClipboard(e) {
      textInputRef.current.select();
      document.execCommand('copy');
      e.target.focus();
      setCopySuccess(true);
    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2, mt: 2.5}}>
            <input type='text'
                ref={textInputRef}
                value={props.contentToCopy}
                readOnly
                style={{border: '1px solid #ddd', backgroundColor: '#f7f7f7', width: '100%', padding: '0 8px'}}
            />
            {
                /* if the copy command exists */
                document.queryCommandSupported('copy') &&
                <IconButton size="small" onClick={copyToClipboard} sx={{ml: 1}}>
                    {copySuccess ? <CheckIcon  sx={{color: 'green'}} variant="contained" /> : <ContentCopyIcon/>}
                </IconButton>
            }
      </Box>
    );
  }
