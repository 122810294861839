import React from 'react'
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import Slide from "@mui/material/Slide";
import ShareIcon from '@mui/icons-material/Share';
import ClickToCopy from './ClickToCopy'
import CancelIcon from '@mui/icons-material/Close';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    EmailIcon,
    LinkedinIcon,
    TwitterIcon,
    FacebookIcon,
    WhatsappIcon
} from "react-share";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});







// const shareableDescription = 'Best Buyer Price INR:' + (props.cardData.find_prices && props.cardData.find_prices.best_buyer_price !== 0) && props.cardData.find_prices.best_buyer_price +', Best Seller Price INR: '+ (props.cardData.find_prices && props.cardData.find_prices.best_seller_price !== 0) && props.cardData.find_prices.best_seller_price +', Location: ' + (props.cardData?.type == 1 && props.vesselAttributeData.port) ? props.vesselAttributeData.port.port_name : (props.cardData?.type == 2 && props.vesselAttributeData.city) ? props.vesselAttributeData.city.region_name : ''}

// {console.log(shareableDescription)}

export default function SocialShareModal(props) {
    const [open, setOpen] = React.useState(false);


    const bestBuyerPrice = (props.cardData && props.cardData.find_prices && props.cardData.find_prices.best_buyer_price !== 0) ?
        ('Best Buyer Price: ' + props.cardData.currency + ' ' + props.cardData.find_prices.best_buyer_price) : 'Best Buyer Price: ' + props.cardData.currency + ' NA';

    const bestSellerPrice = (props.cardData && props.cardData.find_prices && props.cardData.find_prices.best_seller_price !== 0) ?
        ('Best Seller Price: ' + props.cardData.currency + ' ' + props.cardData.find_prices.best_seller_price) : 'Best Seller Price: ' + props.cardData.currency + ' NA';

    const lastMatchPrice = (props.cardData && props.cardData.find_prices && props.cardData.find_prices.last_trade_price !== 0) ?
        ('Last Match Price: ' + props.cardData.currency + ' ' + props.cardData.find_prices.last_trade_price) : 'Last Match Price: ' + props.cardData.currency + ' NA';

    // const MinePortLocation = (props.cardData && props.cardData?.type == 1 && props.vesselAttributeData.port) ?
    //                         ('Mine ' + props.vesselAttributeData.port.port_name) :
    //                         (props.cardData && props.cardData?.type == 2) ?
    //                         ('Location ' + props.vesselAttributeData.city) :
    //                         ('Vessel ' + props.vesselAttributeData?.city?.region_name);
    // const MinePortLocation = ''

    // const MinePortLocation = (' Region: ' + (props.vesselAttributeData.city !== null ? props.vesselAttributeData.city : 'NA'))
    const MinePortLocation = ('Region: ' + (props.cardData && props.cardData?.type == 2 && props.vesselAttributeData.city ? props.vesselAttributeData.city.region_name : 'NA'))



    const marketTitle = ((props.cardData && props.cardData?.type == 1 && props.cardData.vessel_name) ? props.cardData.vessel_name : props.cardData?.type == 2 ? props.cardData.mine : '\n') + '\n Coal Type: ' + props.vesselAttributeData.coaltype?.type + '\n ' + bestBuyerPrice + '\n ' + bestSellerPrice + '\n ' + lastMatchPrice + '\n ' + MinePortLocation + '\n';

    // console.log(props.cardData.market_place.id)

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label={`Share`}
                size="small"
                onClick={e => { handleClickOpen("socialShareModal"); props.eventsTracking.shareContract(e) }}
            >
                <ShareIcon fontSize='small' />
            </IconButton>
            <Dialog
                open={open === "socialShareModal"}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-labelledby="add-contact"
                fullWidth
                maxWidth='sm'
            >
                <IconButton
                    aria-label="close"
                    className="closeBtn"
                    size="small"
                    onClick={e => {
                        handleClose(e)
                    }}
                >
                    <CancelIcon />
                </IconButton>
                <DialogTitle id="dialog-title">
                    Share
                </DialogTitle>
                <Box sx={{ maxWidth: '380px', margin: '1rem auto' }}>
                    <Box sx={{ display: "flex", justifyContent: 'space-around' }}>
                        {/* {console.log()} */}

                        <LinkedinShareButton
                            style={{ marginRight: '8px' }}
                            title={marketTitle}
                            description={bestBuyerPrice + bestSellerPrice + lastMatchPrice + MinePortLocation}
                            url={`${window.location.origin}/coal-origin#${props.countryId}#${props.marketplaceId}#${props.shareCardID}`}
                        >
                            <LinkedinIcon size={64} round={true} />
                        </LinkedinShareButton>
                        <TwitterShareButton
                            style={{ marginRight: '8px' }}
                            title={marketTitle}
                            description={bestBuyerPrice + bestSellerPrice + lastMatchPrice + MinePortLocation}
                            url={`${window.location.origin}/coal-origin#${props.countryId}#${props.marketplaceId}#${props.shareCardID}`}
                        >
                            <TwitterIcon size={64} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                            style={{ marginRight: '8px' }}
                            title={marketTitle}
                            description={bestBuyerPrice + bestSellerPrice + lastMatchPrice + MinePortLocation}
                            url={`${window.location.origin}/coal-origin#${props.countryId}#${props.marketplaceId}#${props.shareCardID}`}
                        >
                            <WhatsappIcon size={64} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton
                            style={{ marginRight: '8px' }}
                            title={marketTitle}
                            quote={bestBuyerPrice + bestSellerPrice + lastMatchPrice + MinePortLocation}
                            url={`${window.location.origin}/coal-origin#${props.countryId}#${props.marketplaceId}#${props.shareCardID}`}
                        >
                            <FacebookIcon size={64} round={true} />
                        </FacebookShareButton>
                        <EmailShareButton
                            subject={"Check this - " + (props.cardData?.type == 1 && props.cardData.vessel_name ? props.cardData.vessel_name : props.cardData?.type == 2 ? props.cardData.mine : '')}
                            separator=" - "
                            title={marketTitle}
                            body={bestBuyerPrice + bestSellerPrice + lastMatchPrice + MinePortLocation}
                            url={`${window.location.origin}/coal-origin#${props.countryId}#${props.marketplaceId}#${props.shareCardID}`}
                        >
                            <EmailIcon size={64} round={true} />
                        </EmailShareButton>
                    </Box>
                    <ClickToCopy contentToCopy={`${window.location.origin}/coal-origin#${props.countryId}#${props.marketplaceId}#${props.shareCardID}`} />
                </Box>
            </Dialog>
        </>
    )
}
