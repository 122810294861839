import React, { useEffect } from "react";
// import { makeStyles } from "@mui/material/styles";
import { Grid, Box, MenuItem } from "@mui/material";
import { validatorRegEx } from "../../../helpers";
import {
    // ValidatorForm,
    TextValidator,
    SelectValidator
} from "react-material-ui-form-validator";
import { Product } from '../../../api/resources/Product'
import LanguageTranslate from '../../partials/UnderScoreLanguage'

// const useStyles = makeStyles(theme => ({
//     white: {
//         color: theme.palette.common.white
//     }
// }));

/**
 *
 * @name EditAddress
 * @alias EditAddress
 * @component
 * @exports EditAddress
 *
 * @description
 * Dialog to edit existing address in rfq/listing form
 *
 */

export default function EditAddress(props) {
    // const classes = useStyles();
    const [cities, setCities] = React.useState([]);

    const getCityByState = async params => {
        props.loader(true);
        var params = Object.assign(
            { state_id: props.editState },
            params
        );
        try {
            const res = await Product.getPostCity(params);
            if (res.data.status == "success") {
                setCities(res.data.data);
                props.loader(false);
            }
        } catch (e) {
            console.error(e);
        }
        //setLoading(false);
    };

    useEffect(() => {
        if (props.editState) {
            getCityByState();
        }
    }, [props.editState]);

    return (
        <Box py={2}>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <TextValidator
                        label={<LanguageTranslate s='Address' />}
                        name="editAddress"
                        fullWidth
                        multiline
                        rows="3"
                        value={props.editAddress}
                        onChange={e => { props.setEditAddress(e.target.value) }}
                        variant="outlined"
                        required
                        size="small"
                        validators={["required"]}
                        errorMessages={["Address field is required"]}
                    />
                </Grid>
                <Grid item sm xs={12}>
                    <SelectValidator
                        native="true"
                        required
                        variant="outlined"
                        label={<LanguageTranslate s='State' />}
                        name="state"
                        fullWidth
                        validators={["required"]}
                        errorMessages={["State field is required"]}
                        size="small"
                        value={props.editState}
                        onChange={e => { props.setEditState(e.target.value); props.setEditCity("") }}
                    >
                        {(props.states && props.states.length > 0) && props.states.map((state, key) => (
                            <MenuItem
                                value={state.id}
                                key={key}
                            >
                                {state.region_name}
                            </MenuItem>
                        ))}
                    </SelectValidator>
                </Grid>
                <Grid item sm xs={12}>
                    <SelectValidator
                        native="true"
                        required
                        variant="outlined"
                        label={<LanguageTranslate s='City' />}
                        name="city"
                        fullWidth
                        validators={["required"]}
                        errorMessages={["City field is required"]}
                        size="small"
                        value={props.editCity}
                        onChange={e => { props.setEditCity(e.target.value) }}
                    >
                        {(cities && cities.length > 0) && cities.map((city, key) => (
                            <MenuItem
                                value={city.id}
                                key={key}
                            >
                                {city.region_name}
                            </MenuItem>
                        ))}
                    </SelectValidator>
                </Grid>
                <Grid item sm xs={12}>
                    <TextValidator
                        label={<LanguageTranslate s='Pin Code' />}
                        required
                        name="pincode"
                        fullWidth
                        value={props.editPincode}
                        onChange={e => { props.setEditPincode(e.target.value) }}
                        variant="outlined"
                        size="small"
                        validators={[
                            `matchRegexp:${validatorRegEx("pin_code")}`,
                            "required"
                        ]}
                        errorMessages={[
                            "Pincode field is required"
                        ]}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
