import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    Grid,
    Box,
    InputAdornment,
    InputLabel
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { validatorRegEx } from "../../../helpers";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
}));

/**
 *
 * @name AdvanceBalance
 * @alias AdvanceBalance
 * @component
 * @exports AdvanceBalance
 *
 * @description
 * Advance balance input field
 *
 */

export default function AdvanceBalance(props) {
    const classes = useStyles();

    useEffect(() => {
        var totalBalance = 100;
        props.setBalance(totalBalance - props.advance);
    }, [props.advance]);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <InputLabel className={`${classes.marginBottom}`}>Advance<sup style={{ color: "red" }}>*</sup>
                    </InputLabel>
                    <TextValidator
                        className={classes.backgroundWhite}
                        variant="outlined"
                        type="number"
                        fullWidth
                        name="num_advance"
                        value={props.advance}
                        onChange={e => { props.setAdvance(e.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                >
                                    %
                                </InputAdornment>
                            )
                        }}
                        validators={["required", "isNumber", "maxNumber:100",`matchRegexp:${validatorRegEx("nonZero")}`]}
                        errorMessages={[
                            "Advance field is required",
                            "Advance can be number only.",
                            "Advance can not be greater than 100",
                            "Advance cannot be Zero!",
                        ]}
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <InputLabel className={`${classes.marginBottom}`}>Balance<sup style={{ color: "red" }}>*</sup>
                    </InputLabel>
                    <TextValidator
                        variant="outlined"
                        type="number"
                        fullWidth
                        name="num_balance"
                        value={props.balance}
                        onChange={e => { props.setBalance(e.target.value) }}
                        disabled
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                >
                                    %
                                </InputAdornment>
                            )
                        }}
                        validators={["required", "isNumber", "maxNumber:100"]}
                        errorMessages={[
                            "Balance amount field is required",
                            "Balance amount can be number only.",
                            "Balance can not be greater than 100"
                        ]}
                        size="small"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
