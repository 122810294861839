import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    Box,
    Tooltip,
    InputLabel,
    InputAdornment
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import InfoIcon from '@mui/icons-material/Info';
import { validatorRegEx } from "../../../helpers";

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
}));

/**
 *
 * @name NumberOfPaymentDays
 * @alias NumberOfPaymentDays
 * @component
 * @exports NumberOfPaymentDays
 *
 * @description
 * Number of payment days input field
 *
 */

export default function NumberOfPaymentDays(props) {
    const classes = useStyles();
    return (
        <Box>
            <InputLabel className={`${classes.marginBottom}`}> No. of Payment Days<sup style={{ color: "red" }}>*</sup>
                <Tooltip placement="top" title="Number of days in which full payment will be done. Will be counted from the Next working Day after Awarding of Quote." arrow><InfoIcon style={{ fontSize: 14 }} className={classes.toolTipIcon} /></Tooltip>
            </InputLabel>
            <TextValidator
                className={classes.backgroundWhite}
                variant="outlined"
                type="number"
                fullWidth
                name="no_od_payment_days"
                value={props.numberOfDays}
                onChange={e => { props.setNumberOfDays(e.target.value) }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                        >
                            Days
                        </InputAdornment>
                    )
                }}
                validators={[
                    "required",
                    "isNumber",
                    `matchRegexp:${validatorRegEx("nonZero")}`
                ]}
                errorMessages={[
                    "Payment Days field is required",
                    "Payment Days can be number only.",
                    "Payment Days can not be Zero !!"
                ]}
                size="small"
            />
        </Box>
    );
}
