import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputLabel,
    Box,
    MenuItem
} from "@mui/material";
import { SelectValidator } from "react-material-ui-form-validator";
import LanguageTranslate from '../../partials/UnderScoreLanguage'

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 *
 * @name PaymentTerms
 * @alias PaymentTerms
 * @component
 * @exports PaymentTerms
 *
 * @description
 * Payment Terms input field
 *
 */

export default function PaymentTerms(props) {
    const classes = useStyles();

    return (
        <Box>
            <InputLabel className={classes.marginBottom}><LanguageTranslate s='Payment Terms' /><sup style={{ color: "red" }}>*</sup></InputLabel>
            <SelectValidator
                native="true"
                variant="outlined"
                name="payment_term_id"
                fullWidth
                size="small"
                validators={[
                    "required",
                ]}
                errorMessages={[
                    "Payment Terms field is required",
                ]}
                value={props.paymentTermId}
                onChange={e => {
                    props.setPaymentTermId(e.target.value);
                    props.setAdvance(e.target.value == 4 ? 10 : 0);
                }}
            >
                {/* {(props.paymentTermId != 6 && props.paymentTerms && props.paymentTerms.length > 0) && props.paymentTerms.map((p, key) => (
                    <MenuItem
                        value={p.id}
                        key={key}
                    >
                        {p.payment_title}
                    </MenuItem>
                ))} */}
                {((props.marketData.is_auction == true && (props.vesselPriceSeller == null || props.vesselPriceSeller?.length == 0)) || props.paymentTermId == 6) ?
                    <MenuItem value={6}>Payment through CoalShastra</MenuItem>
                    :
                    (props.paymentTerms.length > 0) && props.paymentTerms.map((p, key) => (
                        <MenuItem
                            value={p.id}
                            key={key}
                        >
                            {p.payment_title}
                        </MenuItem>
                    ))
                }
                {/* <MenuItem value={1}>Advance</MenuItem>
                <MenuItem value={4}>Cash & Carry</MenuItem>
                <MenuItem value={3}>Credit</MenuItem>
                <MenuItem value={2}>Letter of Credit</MenuItem> */}
            </SelectValidator>
        </Box>
    );
}
