import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputLabel,
    Box,
    InputAdornment,
    Typography
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { validatorRegEx } from "../../../helpers";
import LanguageTranslate from '../../partials/UnderScoreLanguage'

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 *
 * @name OfferPrice
 * @alias OfferPrice
 * @component
 * @exports OfferPrice
 *
 * @description
 * Offer Price input field
 *
 */

export default function OfferPrice(props) {
    const classes = useStyles();
    const priceTickSize = props.priceTickSize === null ? 1 : props.priceTickSize;
    //const [multiple25Error, multiple25ErrorSet] = React.useState(false);

    const [validators, validatorsSet] = React.useState([
        "required",
        "isNumber"
    ])

    const [errorMessages, errorMessagesSet] = React.useState([
        "Price field is required",
        "Only numbers are accepted!"
    ])

    // React.useEffect(() => {
    //     props.setPrice('');
    //     // if (props.price != "") {
    //     //     if (props.price % 25 == 0) {
    //     //         multiple25ErrorSet(false);
    //     //     } else {
    //     //         multiple25ErrorSet(true);
    //     //     }
    //     // }
    // }, [])

    React.useEffect(() => {

        let vald = [
            "required",
            "isFloat",
            "isPositive",
            //`matchRegexp:${validatorRegEx("numeric")}`,
            //`matchRegexp:${validatorRegEx("nonZero")}`,
            //`matchRegexp:${validatorRegEx(props.tickSize == 25 ? "multiple25" : "endsWith", [0, 5])}`,
        ]
        let errormsg = [
            "Price field is required",
            "Only numbers are accepted!",
            "Price cannot be in negative numbers!",
            //"Price value can not be Zero !!",
            //`${props.tickSize == 25 ? "Please enter Price as multiple of 25" : "Please enter Price as multiple of 5"}`
        ]

        if (props.priceRange.min != 0 && props.priceRange.max != 0) {
            // const min = (props.marketData?.is_auction == 1) ? (props.listingType == "buyer" && props.vesselPriceBuyer?.length > 0) ? (props.vesselPriceBuyer[0]?.price_value) : props.vesselPriceSeller?.length > 0 ? props.vesselPriceSeller[0]?.price_value : Math.round(props.priceRange.min) : Math.round(props.priceRange.min)
            const min = (props.marketData?.is_auction == 1 && props.vesselPriceSeller?.length > 0) ? props.priceTickSize : Math.round(props.priceRange.min)
            const max = props.marketData?.is_auction == 1 ? 100000000 : Math.round(props.priceRange.max)

            vald.push(`minNumber:${min}`)
            vald.push(`maxNumber:${max}`)

            errormsg.push(`Please enter the price in the range of ${min} to ${max}`)
            errormsg.push(`Please enter the price in the range of ${min} to ${max}`)
        }

        //vald.push(`matchRegexp:${validatorRegEx(props.tickSize == 25 ? "multiple25" : "endsWith", [0, 5])}`)
        //errormsg.push(props.listingType == "buyer" ? `Please enter Required Price as multiple of ${props.tickSize == 25 ? "25" : "5"}` : `Please enter Offer Price as multiple of ${props.tickSize == 25 ? "25" : "5"}`)

        validatorsSet(vald)
        errorMessagesSet(errormsg)
    }, [props.priceRange, props.listingType, props.marketData, props.vesselPriceSeller, props.user])

    React.useEffect(() => {
        if (props?.price && props.priceTickSize) {
            if (props.price % priceTickSize === 0) {
                props.setIsValidPrice(true)
            }
            if (props.price % priceTickSize !== 0) {
                props.setIsValidPrice(false)
            }
        }
    }, [props.price, props.priceTickSize])

    return (
        <Box>
            {/* <InputLabel className={classes.marginBottom}><LanguageTranslate s={props.listingType == "buyer" ? "Required Price" : 'Offer Price'} /><sup style={{ color: "red" }}>*</sup></InputLabel> */}
            <InputLabel className={classes.marginBottom}><LanguageTranslate s={props.listingType == "buyer" ? "Required Price" : 'Offer Price'} />{(props.vesselInfo && props.vesselInfo.vessel_currency && props.vesselInfo.vessel_currency == 'INR') && <Box sx={{ fontSize: 11, display: 'inline-block' }}> (Including CESS)</Box>}<sup style={{ color: "red" }}>*</sup></InputLabel>
            {/* props?.vesselInfo?.vessel_currency */}
            {/* {props.originOfCoal == 2 && */}

            <TextValidator
                variant="outlined"
                fullWidth
                size="small"
                validators={validators}
                errorMessages={errorMessages}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            className="fsSmall"
                        >
                            {props?.vesselInfo?.vessel_currency}/MT
                        </InputAdornment>
                    )
                }}
                value={props.price}
                onChange={e => { props.setPrice(e.target.value) }}
            />
            {props.isValidPrice === false && (
                <Typography
                    component="p"
                    color="error"
                    className="has__error"
                    style={{ margin: '0 14px' }}
                >
                    Please enter Price as multiple of {props.priceTickSize}.
                </Typography>
            )}
            {/* {multiple25Error && (
                <Box mt={1}>
                    <Typography component="p" color="error" className="has__error">
                        {props.listingType == "buyer" ? "Please enter Required Price as multiple of 25" : "Please enter Offer Price as multiple of 25"}
                    </Typography>
                </Box>
            )} */}
            {/* } */}
            {/* {props.originOfCoal != 2 &&
                <TextValidator
                    variant="outlined"
                    fullWidth
                    size="small"
                    validators={[
                        "required",
                        "isNumber",
                        `matchRegexp:${validatorRegEx("numeric")}`,
                        `matchRegexp:${validatorRegEx("nonZero")}`,
                    ]}
                    errorMessages={[
                        "Price field is required",
                        "Only numbers are accepted!",
                        "Price cannot be in negative numbers!",
                        "Price value can not be Zero !!",
                    ]}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className="fsSmall"
                            >
                                ₹/MT
                            </InputAdornment>
                        )
                    }}
                    value={props.price}
                    onChange={e => { props.setPrice(e.target.value) }}
                />
            } */}
        </Box>
    );
}
