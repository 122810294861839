import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import makeStyles from "@mui/styles/makeStyles";
import { loader } from "../../redux/actions/commonActions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Grid,
  Box,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import MuiLink from "@mui/material/Link";
import Link from "@mui/material/Link";
import Countdown, { zeroPad } from "react-countdown";
import SpecialRequest from "./partials/SpecialRequest";
import LoadingButton from '@mui/lab/LoadingButton';
import { User } from "../../api/resources/User";
import Emitter from "../../services/emitter";
import genOfferAndBidMessage from "../partials/chat/genOfferAndBidMessage";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShipIcon from "../partials/icons/ShipIcon";
import CommentIcon from '@mui/icons-material/Comment';
import LoginForm from "../partials/LoginForm";
import SignupForm from "../signupV2/SignUp"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paperSpacing: {
    // color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2.5, 0.75),
    },
  },
  fs16: {
    fontSize: "16px",
  },
  textBlue: {
    color: "#0645ad",
  },
  fs14: {
    fontSize: "14px",
    color: "#0645ad",
  },
  price: {
    textDecoration: "underline",
    fontSize: "13px",
    fontWeight: "600",
    display: "inline-block",
  },
  buyPrice: {
    color: "red",
  },
  sellPrice: {
    color: "#4E9F3D",
  },

  gridCol2: {
    display: "grid",
    gridTemplateColumns: ".8fr 1fr",
    alignItems: "center",
    gridGap: "10px",
    marginBottom: "10px",
  },
  isPaidUserListing_Seller: {
    background: "#f9ecf1",
    //backgroundImage: `url('/images/hot-listing.gif')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "30px",
    backgroundPosition: "center -6px",
  },
  isPaidUserListing_Buyer: {
    background: "#eefffd",
    //backgroundImage: `url('/images/hot-listing.gif')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "30px",
    backgroundPosition: "center -6px",
  },
  gridCols2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    gridGap: "10px",
    marginBottom: "10px",
    borderBottom: "1px solid #ccc",
    '& .MuiButton-root': {
      fontSize: 12,
      minWidth: '30px',
      maxHeight: '24px',
      //minHeight: '20px',
    }
  },
  gridCols2WoMargin: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    gridGap: "10px",
    // marginBottom: "10px",
    // borderBottom: "1px solid #ccc",
    '& .MuiButton-root': {
      fontSize: 12,
      minWidth: '30px',
      maxHeight: '24px',
      //minHeight: '20px',
    }
  },
  gridCols3: {
    display: "grid",
    gridTemplateColumns: "1.8fr .2fr",
    alignItems: "center",
    gridGap: "10px",
    // marginBottom: "10px",
    // borderBottom: "1px solid #ccc",
    '& .MuiButton-root': {
      fontSize: 12,
      minWidth: '30px',
      maxHeight: '24px',
      //minHeight: '20px',
    }
  },
  gridColTotal2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    gridGap: "10px",
    marginBottom: "10px",
    borderBottom: "1px solid #ccc",
    borderTop: "1px solid #ccc",
    padding: "4px 0",
  },
  gridCol3: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    gridGap: "10px",
    marginBottom: "10px",
    borderBottom: "1px solid #ccc",
    borderTop: "1px solid #ccc",
    padding: "4px 0",
  },
  preFilledInputContainer: {
    background: "#F2F3F5",
    padding: theme.spacing(1.8, 1.8, 0),
    borderRadius: "6px",
  },
  textMuted: {
    color: "#6A6A6A",
    fontSize: 13,
  },
  dashedBorder: {
    borderRight: "1px dashed #CCCCCC",
    paddingRight: "7px",
  },
  padding: {
    padding: 0,
  },
  px1: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonPrimaryLight: {
    backgroundColor: '#d4def1',
    color: '#2959BA',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#3f51b557',
    },
  },
  btnBuyer: {
    backgroundColor: '#00838F',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#005b64',
    },

  },
  gridVesselsCol3: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    gridGap: "10px",
    marginBottom: "10px",
  },
  gridCol2Icon: {
    display: "grid",
    gridGap: '5px',
    gridTemplateColumns: '10px auto',
    alignItems: 'center',
    fontSize: '12px',
    padding: 5,
    marginLeft: '10px',
    '& img': {
      width: 12
    }
  },
}));

/**
 *
 * @name MarketPrices
 * @alias MarketPrices
 * @component
 * @exports MarketPrices
 *
 * @description
 * MarketPrices component to show market prices
 */

function MarketPrices(props) {
  //console.log(props.marketData)
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn)
  const globalSetting = useSelector(
    (state) => state.commonReducer.globalSettings
  );
  const [open, setOpen] = React.useState(false);
  const [openEAuctionOfferDialog, setOpenEAuctionOfferDialog] = React.useState(false);
  const [buyerData, setBuyerData] = React.useState(false);
  const [chats, setChats] = useState([]);
  //console.log(props.vesselPriceSeller[0]?.validity)
  //const validity = "2023-02-13 14:40:27"
  const [fetching, fetchingSet] = React.useState(false);
  const [message, setMessage] = useState("");

  const [noMoreListingStatus, setNoMoreListingStatus] = React.useState(
    (props.vesselPriceBuyer !== null && props.vesselPriceBuyer.length > 5) ||
      (props.vesselPriceSeller !== null && props.vesselPriceSeller.length > 5)
      ? false
      : true
  );
  const [totalBuyerQtySet, setTotalBuyerQtySet] = React.useState(null);
  const [totalSellerQtySet, setTotalSellerQtySet] = React.useState(null);
  // const [userTotalBuyerQty, setUserTotalBuyerQty] = React.useState(null);
  // const [userTotalSellerQty, setUserTotalSellerQty] = React.useState(null);

  // Load more listings
  const [visibleItems, visibleItemsSet] = React.useState(5);
  const [openDialogL, handleDisplay] = React.useState(false);
  const handleCloseL = () => {
      handleDisplay(false);
   };

   const openDialogBox = () => {
      handleDisplay(true);
   };
  const showMoreItems = () => {
    visibleItemsSet((prevValue) => prevValue + 20);
  };

  const AuctionFinished = () => {
    React.useEffect(() => {
      props.setAuctionFinished(true);
      setOpen(true);
    }, []);

    return <>Finished</>;
  };
  const handleClose = () => {
    setOpen(false);
  };
  const RenderCountdown = ({ hours, minutes, seconds, completed }) => {
    const Span = ({ children }) => {
      return (
        <span
          // style={{backgroundColor: "red", color: "#FFF"}}
          className="countdown-span"
        >
          {children}
        </span>
      );
    };
    if (completed) {
      // Render a completed state
      return <AuctionFinished />;
    } else {
      // Render a countdown
      return (
        <Box className="countdown">
          <Span>{zeroPad(hours)}</Span>:<Span>{zeroPad(minutes)}</Span>:
          <Span>{zeroPad(seconds)}</Span>
        </Box>
      );
    }
  };

  // Get total Qty. of buyer/seller data
  React.useEffect(() => {
    var buyerSum = 0;
    var sellerSum = 0;
    if (props.vesselPriceBuyer !== null && props.vesselPriceBuyer.length > 0) {
      props.vesselPriceBuyer.forEach((p) => (buyerSum += p.chat_data.quantity));
    }
    setTotalBuyerQtySet(buyerSum);

    if (
      props.vesselPriceSeller !== null &&
      props.vesselPriceSeller.length > 0
    ) {
      props.vesselPriceSeller.forEach(
        (p) => (sellerSum += p.chat_data.quantity)
      );
    }
    setTotalSellerQtySet(sellerSum);
  }, [props.vesselPriceBuyer, props.vesselPriceSeller]);

  const prepareAndSubmitMessage = (data) => {

    const msgProps = {
      product_type: data?.chat_data?.product_type,
      currency: data?.price_currency
    }
    let msgData = {
      price: data?.price_value,
      lifting: data?.days,
      paymentMode: data?.payment_term_text,
      paymentModeId: data?.payment_term_id,
      quantity: data?.chat_data?.quantity,
      validityTime: 30,
      deliveryTerm: (data.chat_data?.origin_of_coal == 1 && data.chat_data?.coal_type?.id == 5) ? 'FOB (free on board)' : (data.chat_data?.origin_of_coal == 1 && data.chat_data?.coal_type?.id != 5) ? 'Ex-mine/depo' : (data.chat_data?.origin_of_coal == 2) && 'Ex-port',
      comment: data?.special_requests,
      advance: data?.cc_percentage,
      balance: 100 - parseInt(data?.cc_percentage),
      creditDays: data?.num_credit_days,
      paymentDays: '',
    }

    const message = genOfferAndBidMessage(msgProps, msgData);

    setMessage(message)
  }

  const fetchChats = (data) => {
    prepareAndSubmitMessage(data);
    User.chats
      .fetch({ listing_id: data.chat_data?.listing_id, company_id: data.chat_data?.chat_company_id })
      .then(response => {
        if (response.data.status == "success") {

          if (response.data.data.length > 0) {
            props.setChatData({
              show:
                data.chat_data.chat_company_id ==
                  user.company_id
                  ? false
                  : true,
              listing_id: data.chat_data.listing_id,
              chat_company_id:
                data.chat_data.chat_company_id,
              product: data.chat_data,
              price: data.price_value,
              payment_term_id: data.payment_term_id,
              cc_percentage: data.cc_percentage,
              days: data.days,
              product_type:
                data.chat_data.product_type,
              productCompanyId:
                data.chat_data.chat_company_id,
              currency: props.vesselInfo?.vessel_currency,
              minimumOrderQuantity:
                props.minimumOrderQuantity,
              quantityTickSize: props.quantityTickSize,
              quantityRangeMax: props.quantityRangeMax,
              quantityRangeMin: props.quantityRangeMin,
              priceTickSize: props.priceTickSize,
              creditDays: data?.num_credit_days,
            });
            props.eventsTracking.contractDetailChat();
            props.onCloseClick();
          } else {
            setOpenEAuctionOfferDialog(true);
          }
        } else {
          setOpenEAuctionOfferDialog(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const sendMessageHttpReq = (data) => {
    //sendingMessageSet(true)
    fetchingSet(true);
    var postData = {
      company_id: data.chat_data?.chat_company_id,
      listing_id: data.chat_data?.listing_id,
      session_company_id: user.company_id,
      message: message,
      action: 2,
      reason_ids: [],
      price: data.price_value,
      quantity: data?.quantity,
      lifting_days: data?.days,
      payment_id: 6,
      payment_text: 'Payment through CoalShastra',
      delivery_term_id: 2,
      advance: '',
      balance: '',
      no_credit_days: '',
      validity_time: 30,
      comment: '',
      is_top_five: 0,
      initiated_from: 0,
      source: 2
    }
    User.chats
      .sendMessage(postData)
      .then(response => {
        if (response.data.status === "success") {
          fetchingSet(false);
          setOpenEAuctionOfferDialog(false);
          Emitter.emit('SNACKBAR_NOTIFICATION', {
            message: response.data.message,
            params: { variant: 'success' },
          });
          Emitter.emit('CHAT_MESSAGE_SENT', postData);

        }
        if (response.data.status === "fail") {
          fetchingSet(false);
        }
      })
      .finally(() => {

      });
  }

  // // Get total Qty. of user buyer/seller data
  // React.useEffect(() => {
  //     var buyerSum = 0
  //     var sellerSum = 0
  //     if (props.vesselPriceBuyer !== null && props.vesselPriceBuyer.length > 0) {
  //         props.vesselPriceBuyer.forEach(p => buyerSum += p.user_id == user.id ? p.chat_data.quantity : 0);
  //     }
  //     setUserTotalBuyerQty(buyerSum);

  //     if (props.vesselPriceSeller !== null && props.vesselPriceSeller.length > 0) {
  //         props.vesselPriceSeller.forEach(p => sellerSum += (p.user_is_paid == true && p.created_at_paid == true && p.user_id == user.id) ? p.chat_data.quantity : 0);

  //     }
  //     setUserTotalSellerQty(sellerSum);

  // }, [props.vesselPriceBuyer, props.vesselPriceSeller]);

  // React.useEffect(() => {
  //     console.log("selected item " + props.selectedItem)
  // }, [props.selectedItem])

  return (
    <Paper elevation={2} className={classes.paperSpacing}>
      {/* props.marketData.is_auction */}
      {props.vesselInfo &&
        props.vesselInfo.vessel_currency &&
        props.vesselInfo.vessel_currency == "INR" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#d2dcf14a",
              color: "#04368a",
              py: 0.5,
              px: 1,
              mx: "auto",
              borderRadius: "4px",
              fontSize: "12px",
              mb: { xs: 1, sm: 1 },
            }}
            className={`w-100`} mb={1}
          >
            <Box sx={{ mr: 1 }}>
              <img src="../images/bulb-icon.svg" alt="info" width="16" />
            </Box>
            <Box component="span">
              {props.vesselInfo &&
                props.vesselInfo.vessel_currency &&
                props.vesselInfo.vessel_currency == "INR" &&
                "All Prices are including CESS"}
            </Box>
          </Box>
        )}

      {props?.marketData.is_auction == true &&
        props.vesselPriceSeller &&
        props.vesselPriceSeller != null &&
        props.vesselPriceSeller.length > 0 &&
        props.auctionFinished == false && (
          <Box textAlign="center">
            <Countdown
              date={props.vesselPriceSeller[0]?.validity}
              renderer={RenderCountdown}
              zeroPadTime={2}
            />
          </Box>
        )}
      <Box
        key={props.key}
        className={props.matchesViewport ? `${classes.px1}` : ""}
        mb={0.5}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          pt: 1,
          mb: 0.5,
          borderBottom: "1",
          borderColor: "divider",
        }}
      >
        <Box>
          <Box fontWeight="bold" className={classes.fs16}>
            {props.vesselType == 1 &&
              props.vesselInfo.vessel &&
              props.vesselInfo.vessel}
            {props.vesselType == 2 && props.vesselInfo.mine}
          </Box>
          <Box className={classes.textMuted} mb={0.55}>
            <img
              src={props.marketData?.origin_vessel?.flag_img}
              alt="flag"
              width="20"
            />
            &nbsp;
            {props.vesselType == 1 &&
              props.vesselInfo.country &&
              props.vesselInfo.country}
            {props.vesselType == 2 && "India"} | &nbsp;
            {props.vesselType == 1 && props.vesselInfo.port && (
              <>
                <LocationOnIcon
                  fontSize="small"
                  sx={{ verticalAlign: "bottom" }}
                />{" "}
                {props.vesselInfo.port}
              </>
            )}
            {props.vesselType == 2 && props.vesselInfo.city && (
              <>
                <LocationOnIcon
                  fontSize="small"
                  sx={{ verticalAlign: "bottom" }}
                />{" "}
                {props.vesselInfo.city}
              </>
            )}{" "}
            | &nbsp;
            {/* {props.vesselInfo.calorific_value} */}
            {props.marketData?.quality != null
              ? ` ${props.marketData?.quality}`
              : props.marketData?.quality == null
                ? ` ${props.marketData.vessel_attribute_oldest?.quality}`
                : ""}{" "}
            {props.marketData.quality_type == 1
              ? "GAR"
              : props.marketData.quality_type == 2
                ? "GAD"
                : props.marketData.quality_type == 3 && "NAR"}
            (Kcal/kg)
          </Box>
          <Box className={classes.textMuted} mb={0.55}>
            {(props.marketData.is_auction == true && props.marketData.attachments != null) ?
              <MuiLink
                component={Link}
                href={props.marketData?.attachments[0]}
                color="primary"
                underline="always"
                target="_blank"
                disabled={props.marketData?.attachments === null}
                size="small"
              >
                <GetAppIcon sx={{ fontSize: "16px", verticalAlign: "sub" }} />
                {props.marketData.is_auction == true
                  ? `Terms & Conditions`
                  : `Download Report`}
              </MuiLink>
              :
              (props.marketData?.coal_path == undefined &&
                props.marketData?.coal_path === null) ? (
                <MuiLink
                  component={Link}
                  color="inherit"
                  underline="always"
                  target="_blank"
                  size="small"
                >
                  <GetAppIcon sx={{ fontSize: "16px", verticalAlign: "sub" }} />{" "}
                  {props.marketData.is_auction == true
                    ? `Terms & Conditions`
                    : `Download Report`}
                </MuiLink>
              )
                : (
                  <MuiLink
                    component={Link}
                    href={props.marketData?.coal_path}
                    color="primary"
                    underline="always"
                    target="_blank"
                    disabled={props.marketData?.coal_path === null}
                    size="small"
                  >
                    <GetAppIcon sx={{ fontSize: "16px", verticalAlign: "sub" }} />
                    {props.marketData.is_auction == true
                      ? `Terms & Conditions`
                      : `Download Report`}
                  </MuiLink>
                )}
          </Box>
        </Box>
        {/* {props?.marketData.is_auction == false && (
          <Box>
            <small className={classes.textMuted}>Last Match</small>
            <Box
              // component={Link} href='#!'
              color="primary"
              size="small"
              underline="none"
              sx={{
                fontSize: "16px",
                color: "#2959BA",
                cursor: "initial",
                fontWeight: "bold",
              }}
            >
              {props.vesselInfo?.vessel_currency}{" "}
              {props.lastTradedPrice && props.lastTradedPrice !== 0
                ? props.lastTradedPrice
                : "NA"}{" "}
              /MT
            </Box>
          </Box>
        )} */}
      </Box>

      <Box sx={props.matchesViewport && { px: 1.25 }}>
        {/* <Box fontWeight="bold" className={classes.fs16} mb={1}>
          All Listings
        </Box> */}
        <Grid container spacing={1}>
          {/* <Grid item xs={6} sm={6} className={classes.dashedBorder}>
            <Box className={classes.gridCols2}>
              <small className={classes.textMuted}>Required Qty</small>
              <Box textAlign="right">
                <small className={classes.textMuted}>
                  {props.marketData?.is_auction == true
                    ? `Bid Price`
                    : `Buyers`}{" "}
                </small>
              </Box>
            </Box>
            {props.vesselPriceBuyer &&
              props.vesselPriceBuyer == null &&
              "No Data"}

            {props.vesselPriceBuyer &&
              props.vesselPriceBuyer !== null &&
              props.vesselPriceBuyer.length > 0 &&
              props.vesselPriceBuyer
                .slice(0, visibleItems)
                .map((buyer_data, key) => (
                  <Box
                    className={
                      buyer_data.user_is_paid == true &&
                        buyer_data.created_at_paid == true &&
                        !props.marketData.is_auction &&
                        buyer_data.user_ad_credit >=
                        buyer_data?.chat_data.quantity
                        ? `${classes.isPaidUserListing_Buyer} ${classes.gridCols2}`
                        : classes.gridCols2
                    }
                    key={key}
                    sx={props.matchesViewport && { alignItems: "baseline" }}
                  >
                    <Box>
                      <Box sx={{ display: "flex" }}>
                        <strong>{buyer_data.quantity} |</strong>
                        <SpecialRequest
                          specialRequestData={buyer_data.special_requests}
                        />
                      
                        {user &&
                          user.reference_contacts_count != null &&
                          user.reference_contacts_count >=
                          globalSetting.settings_mutual_badge_min_count &&
                          buyer_data.is_trusted_contact == true && (
                            <>
                              |{" "}
                              <Tooltip
                                title={`This company is in your trusted contact`}
                                aria-label="additional info"
                              >
                                <img
                                  src={`/images/trusted-company-listing.svg`}
                                  style={{ width: "20px", margin: "0 5px" }}
                                  alt="trusted listing"
                                />
                              </Tooltip>
                            </>
                          )}
                      </Box>
                      <small className={classes.textMuted}>
                        {buyer_data?.days} Lifting Days
                      </small>
                      <Tooltip
                        title={`Listing ID: ${buyer_data.chat_data.listing_id}`}
                        aria-label="additional info"
                      >
                        <small className={classes.textMuted}>
                          {" "}
                          | {buyer_data.chat_data.listing_id}
                        </small>
                      </Tooltip>
                    </Box>
                    <Box sx={{ textAlign: "right", color: "green" }}>
                      {props.marketData?.is_auction == true && (
                        <Box>
                         
                          <Box className={classes.price}>{buyer_data.price}</Box>
                          {(user.id == props.vesselPriceSeller[0].user_id && props.auctionFinished == true) &&
                            <IconButton
                              onClick={e => {
                                fetchChats(buyer_data);
                                setBuyerData(buyer_data);
                              }}
                              size="small"
                              className={classes.padding}
                            >
                              <ChevronRightIcon
                                style={{
                                  fontSize: props.matchesViewport
                                    ? "13px"
                                    : "20px",
                                }}
                                fontSize="small"
                                className="vAlignBottom"
                                sx={{ color: "green" }}
                              />
                            </IconButton>
                          }
                        </Box>
                      )}
                      {props.marketData?.is_auction != true ? (
                        <Box
                          sx={
                            props.matchesViewport && {
                              display: "flex",
                              fontSize: "13px",
                            }
                          }
                          className={`${classes.sellPrice} ${classes.price}`}
                        >
                          {props.marketData?.is_auction == true ? (
                          
                            ""
                          ) : props.user.user_type == 1 ||
                            buyer_data.chat_data.chat_company_id ==
                            user.active_company ||
                            buyer_data.chat_data.chat_user_id == user.id ? (
                            <>
                              <Box className={classes.price}>
                                {buyer_data.price}
                              </Box>
                              <IconButton
                                disabled={true}
                                size="small"
                                className={classes.padding}
                              >
                                <ChevronRightIcon
                                  style={{
                                    fontSize: props.matchesViewport
                                      ? "13px"
                                      : "20px",
                                  }}
                                  fontSize="small"
                                  className="vAlignBottom"
                                />
                              </IconButton>
                            </>
                          ) : (
                            <Box
                              className="pointer"
                              onClick={(e) => {
                                props.setChatData({
                                  show:
                                    buyer_data.chat_data.chat_company_id ==
                                      user.company_id
                                      ? false
                                      : true,
                                  listing_id: buyer_data.chat_data.listing_id,
                                  chat_company_id:
                                    buyer_data.chat_data.chat_company_id,
                                  product: buyer_data.chat_data,
                                  price: buyer_data.price_value,
                                  payment_term_id: buyer_data.payment_term_id,
                                  cc_percentage: buyer_data.cc_percentage,
                                  days: buyer_data.days,
                                  product_type:
                                    buyer_data.chat_data.product_type,
                                  productCompanyId:
                                    buyer_data.chat_data.chat_company_id,
                                  currency: props.vesselInfo?.vessel_currency,
                                  minimumOrderQuantity:
                                    props.minimumOrderQuantity,
                                  quantityTickSize: props.quantityTickSize,
                                  quantityRangeMax: props.quantityRangeMax,
                                  quantityRangeMin: props.quantityRangeMin,
                                  priceTickSize: props.priceTickSize,
                                  creditDays: buyer_data?.num_credit_days,
                                });
                                props.eventsTracking.contractDetailChat();
                                props.onCloseClick();
                              }}
                            >
                              <Box className={classes.price}>
                                {buyer_data.price}
                              </Box>
                              <IconButton
                                size="small"
                                className={classes.padding}
                              >
                                <ChevronRightIcon
                                  style={{
                                    fontSize: props.matchesViewport
                                      ? "13px"
                                      : "20px",
                                  }}
                                  fontSize="small"
                                  className="vAlignBottom"
                                  sx={{ color: "green" }}
                                />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box></Box>
                      )}{" "}
                      {props.marketData?.is_auction != true && <br />}
                      <small className={classes.textMuted}>
                        {buyer_data.payment_term_id == 1
                          ? "100% Advance"
                          : buyer_data.payment_term_id == 4
                            ? `${buyer_data?.cc_percentage}% Advance`
                            : buyer_data.payment_term_id == 2
                              ? `LC (${buyer_data?.num_credit_days == 0
                                ? `At Sight`
                                : `${buyer_data?.num_credit_days} days`
                              })`
                              : buyer_data.payment_term_id == 3
                                ? `Credit(${buyer_data?.num_credit_days == null
                                  ? 0
                                  : buyer_data?.num_credit_days
                                } Days)`
                                : buyer_data.payment_term_text}
                      </small>
                    </Box>
                  </Box>
                ))}
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <Box className={classes.gridCols2}>
              {/* <small className={classes.textMuted}>
                {props.marketData?.is_auction == true
                  ? `Floor Price`
                  : `Sellers`}
              </small> */}

              {/* <Box textAlign="right">
                <small className={classes.textMuted}>Offer Qty </small>
              </Box> */}
            </Box>
            {props.vesselPriceSeller &&
              props.vesselPriceSeller == null &&
              "No Data"}

            {props.vesselPriceSeller &&
              props.vesselPriceSeller !== null &&
              props.vesselPriceSeller.length > 0 &&
              props.vesselPriceSeller
                .slice(0, visibleItems)
                .map((seller_data, key) => (
                  // <Box className={classes.gridCols2} key={key} sx={props.matchesViewport && { alignItems: 'baseline' }}>
                  //#TODO If add credit is 100, and user is listing 1st one 50MT and 2nd one is 50MT, both will stand out
                  // if ad credit is 100, and user is listing 1st one 50MT and 2nd one is 70MT then only 1st listing will stand out.

                  <Box
                    className={
                      seller_data.user_is_paid == true &&
                        seller_data.created_at_paid == true &&
                        !props.marketData.is_auction &&
                        seller_data.user_ad_credit >=
                        seller_data?.chat_data.quantity
                        ? `${classes.isPaidUserListing_Seller} ${classes.gridCols3}`
                        : classes.gridCols3
                    }
                    key={key}
                    sx={props.matchesViewport && { alignItems: "baseline" }}
                  >
                    <Box >
                      <Box style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}>
                        <Box>{props.marketData.is_auction != true && props.marketData.type == 1 && seller_data?.vessel_attributes &&
                          <Box className={classes.gridCol2Icon} mb={.55}>
                            <Tooltip title='Vessels Name'
                              aria-label='Vessels Name'>
                              <ShipIcon />
                            </Tooltip>
                            <small className={classes.textMuted}>
                              {seller_data?.vessel_attributes?.map(vessels => vessels?.vessel_name).join(', ')}
                            </small>
                          </Box>
                        }</Box>
                        <Box className={classes.gridCols2WoMargin}>
                          <Box style={{ color: "red" }}>

                            {props.marketData?.is_auction == true && (
                              <Box className={classes.price}>****</Box>
                            )}
                            {props.marketData?.is_auction != true ? (
                              <Box
                                sx={
                                  props.matchesViewport && {
                                    display: "flex",
                                    fontSize: "13px",
                                  }
                                }
                                className={`${classes.buyPrice} ${classes.price}`}
                              >
                               
                                  <Box
                                    className="pointer"
                                  >
                                    <Box className={classes.price}>
                                    {!isLoggedIn?'*****': seller_data.price}
                                    </Box>

                                  </Box>
                                
                              </Box>
                            ) : (
                              <Box></Box>
                            )}{" "}
                            {props.marketData?.is_auction != true && <br />}
                            <small className={classes.textMuted}>
                              {seller_data.payment_term_id == 1
                                ? "100% Advance"
                                : seller_data.payment_term_id == 4
                                  ? `${seller_data?.cc_percentage}% Advance`
                                  : seller_data.payment_term_id == 2
                                    ? `LC (${seller_data?.num_credit_days == 0
                                      ? `At Sight`
                                      : `${seller_data?.num_credit_days} days`
                                    })`
                                    : seller_data.payment_term_id == 3
                                      ? `Credit(${seller_data?.num_credit_days == null
                                        ? 0
                                        : seller_data?.num_credit_days
                                      } Days)`
                                      : seller_data.payment_term_text}
                            </small>
                          </Box>
                          <Box textAlign="right">

                            {user &&
                              user.reference_contacts_count != null &&
                              user.reference_contacts_count >=
                              globalSetting.settings_mutual_badge_min_count &&
                              seller_data.is_trusted_contact == true && (
                                <>
                                  {" "}
                                  <Tooltip
                                    title={`This company is in your trusted contact`}
                                    aria-label="additional info"
                                  >
                                    <img
                                      src={`/images/trusted-company-listing.svg`}
                                      style={{ width: "20px", marginRight: "5px" }}
                                      className={"vAlignWithIcon"}
                                      alt="trusted listing"
                                    />
                                  </Tooltip>
                                </>
                              )}
                            <br />
                            <small className={classes.textMuted}>
                              {seller_data?.days} Lifting Days{" "}
                            </small>
                          </Box>
                        </Box>
                      </Box>
                      {seller_data.special_requests != null &&
                        <Box style={{
                          marginBottom: "10px",
                          borderBottom: "1px solid #ccc",
                        }}>
                          <IconButton size="small">
                            <CommentIcon color='primary' style={{ fontSize: '13px', marginRight: .5 }} fontSize='small' className="vAlignBottom" />

                          </IconButton>
                          {seller_data.special_requests}
                        </Box>
                      }
                    </Box>
                    <Box textAlign="center">
                      <Button
                        variant='contained'
                        disableElevation
                        color='primary'
                        size="small"
                        disabled={(seller_data.chat_data.chat_company_id ==
                          user.active_company ||
                          seller_data.chat_data.chat_user_id == user.id) ? true : false}
                        className={classes.btnBuyer}
                        onClick={(e) => {

                          if(!isLoggedIn){
                              openDialogBox()
                        }else{
                          props.setChatData({
                            show:
                              seller_data.chat_data.chat_company_id ==
                                user.company_id
                                ? false
                                : true,
                            listing_id: seller_data.chat_data.listing_id,
                            chat_company_id:
                              seller_data.chat_data.chat_company_id,
                            product: seller_data.chat_data,
                            price: seller_data.price_value,
                            badges_data: seller_data?.user,
                            badges_label: "Seller Info",
                            brandStore: true,
                            payment_term_id: seller_data.payment_term_id,
                            cc_percentage: seller_data.cc_percentage,
                            days: seller_data.days,
                            product_type:
                              seller_data.chat_data.product_type,
                            productCompanyId:
                              seller_data.chat_data.chat_company_id,
                            currency: props.vesselInfo?.vessel_currency,
                            minimumOrderQuantity:
                              props.minimumOrderQuantity,
                            quantityTickSize: props.quantityTickSize,
                            quantityRangeMax: props.quantityRangeMax,
                            quantityRangeMin: props.quantityRangeMin,
                            priceTickSize: props.priceTickSize,
                            creditDays: seller_data?.num_credit_days,
                          });
                          props.eventsTracking.contractDetailChat();
                          props.onCloseClick();
                        }
                          
                        }}
                      >
                        BID
                      </Button>
                    </Box>
                  </Box>
                ))}
          </Grid>
          {/* <Grid item sm={12} xs={12}>
            <Box className={classes.gridColTotal2}>
              <Box>
                <small className={classes.textMuted}>{totalBuyerQtySet}</small>
              </Box>
              <Box textAlign="center">
                <small className={classes.textMuted}>Total Quantity</small>
              </Box>
              <Box textAlign="right">
                <small className={classes.textMuted}>{totalSellerQtySet}</small>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
        {/* <Box textAlign="center" my={1}>
          {((props.vesselPriceBuyer !== null &&
            props.vesselPriceBuyer.length > 5) ||
            (props.vesselPriceSeller !== null &&
              props.vesselPriceSeller.length > 5)) &&
            noMoreListingStatus === false && (
              <Button
                disableElevation
                size="small"
                onClick={(e) => {
                  showMoreItems(e);
                  setNoMoreListingStatus(true);
                }}
              >
                View more listings{" "}
                <KeyboardArrowDownIcon
                  fontSize="small"
                  className="vAlignBottom"
                />
              </Button>
            )}
          {noMoreListingStatus === true && (
            <Button disableElevation size="small">
              No more listings
            </Button>
          )}
        </Box> */}
      </Box>
      {props.marketData.is_auction != 1 && (
        <Accordion
        // expanded={expandedOne}
        // onChange={(e) => setExpandedOne(!expandedOne)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box fontWeight="bold" className={classes.fs16}>
              Contract Info
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container className={classes.preFilledInputContainer}>
              <Grid item xs={12} sm={12} className={classes.gridCol2}>
                <Box className={classes.spacing}>Coal Type</Box>
                <Box textAlign="right">
                  <strong>{props.vesselInfo?.coal_type}</strong>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} className={classes.gridCol2}>
                <Box className={classes.spacing}>Origin</Box>
                <Box textAlign="right">
                  <strong>{props.vesselInfo?.country}</strong>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} className={classes.gridCol2}>
                <Box className={classes.spacing}>
                  {props.vesselType == 1 ? "Port" : "Mine"}
                </Box>
                <Box textAlign="right">
                  <strong>
                    {props.vesselType == 1
                      ? props.vesselInfo?.port
                      : props.vesselInfo?.mine}
                  </strong>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} className={classes.gridCol2}>
                <Box className={classes.spacing}>Calorific Value</Box>
                <Box textAlign="right">
                  <strong>
                    {/* {props.vesselInfo.calorific_value} */}
                    {props.marketData?.quality != null
                      ? ` ${props.marketData?.quality}`
                      : props.marketData?.quality == null
                        ? ` ${props.marketData.vessel_attribute_oldest?.quality}`
                        : ""}{" "}
                    {props.marketData.quality_type == 1
                      ? "GAR"
                      : props.marketData.quality_type == 2
                        ? "GAD"
                        : props.marketData.quality_type == 3 && "NAR"}
                    (Kcal/kg)
                  </strong>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {props.vesselType == 1 &&
        <Accordion
        // expanded={expandedTwo}
        // onChange={(e) => setExpandedTwo(!expandedTwo)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box fontWeight="bold" className={classes.fs16}>
              Vessels Info
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container className={classes.preFilledInputContainer}>
              {(props.vesselPriceSeller && props.vesselPriceSeller?.length > 0 && props.vesselPriceSeller[0]?.vessel_attributes) && props.vesselPriceSeller[0]?.vessel_attributes.map((va, key) => (
                <Grid item xs={12} sm={12} className={classes.gridVesselsCol3}>
                  <Box className={classes.spacing}>{va.vessel_name}</Box>
                  <Box textAlign="center">
                    {va.quantity}MT
                  </Box>
                  <Box textAlign="right">
                    <span>{va?.quality
                      ? va?.quality +
                      (props.marketData?.quality_type == 1
                        ? ' GAR'
                        : props.marketData?.quality_type == 2
                          ? ' GAD'
                          : ' NAR')
                      : va?.quality
                        ? va?.quality +
                        (props.marketData?.quality_type == 1
                          ? ' GAR'
                          : props.marketData?.quality_type == 2
                            ? ' GAD'
                            : ' NAR')
                        : va?.quality +
                        (props.marketData?.quality_type == 1
                          ? ' GAR'
                          : props.marketData?.quality_type == 2
                            ? ' GAD'
                            : ' NAR')}
                    </span>
                    <a
                      disabled={va.attachment == ""}
                      component={Link}
                      target="_blank"
                      href={va.attachment}
                      onClick={handleClose}
                    >
                      <GetAppIcon style={{ fontSize: 16 }} />
                    </a>
                  </Box>
                </Grid>

              ))}

            </Grid>
          </AccordionDetails>
        </Accordion>
      }
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        onClose={(e) => {
          setOpen(false);
        }}
      >
        <Box>
          <DialogTitle>
            <Box
              sx={{
                fontSize: "14px",
                color: "#2a2a2a",
                mb: 0.75,
                fontWeight: "normal",
              }}
            >
              <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                Dear {user.first_name},
              </span>
              <br /> eAuction is closed now.
            </Box>

            <Box sx={{ textAlign: "center", mb: 1.5 }}>
              <Button
                onClick={(e) => {
                  setOpen(false);
                }}
                disableElevation
                size="small"
                variant="contained"
                color="primary"
              >
                Okay
              </Button>
            </Box>
          </DialogTitle>
        </Box>
      </Dialog>

      <Dialog
        open={openEAuctionOfferDialog}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        onClose={(e) => {
          setOpenEAuctionOfferDialog(false);
        }}
      >
        <Box>
          <DialogTitle>
            <Box
              sx={{
                fontSize: "14px",
                color: "#2a2a2a",
                mb: 0.75,
                fontWeight: "normal",
              }}
            >
              <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                Dear {user.first_name},
              </span>
              <br /> Are you sure you want to send offer on this bid?
            </Box>

            <Box sx={{ textAlign: "right", mb: 1.5 }}>
              <Button
                disableElevation
                size="small"
                variant="contained"
                className={classes.buttonPrimaryLight}
                onClick={e => {
                  setOpenEAuctionOfferDialog(false);
                }}
              >
                No
              </Button>
              <LoadingButton
                disableElevation
                size="small"
                loading={fetching}
                color="primary"
                variant="contained"
                onClick={e => {
                  sendMessageHttpReq(buyerData);
                }}
              >
                Yes
              </LoadingButton>
            </Box>
          </DialogTitle>
        </Box>
      </Dialog>

      {!isLoggedIn && <Dialog
 onClose = {handleCloseL} open = {openDialogL}
  aria-labelledby="form-dialog-title"
>

  <LoginForm
  brandStore={true}
  />
</Dialog> }
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { loader })(MarketPrices);
