import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    InputLabel,
    Box,
    Typography,
    InputAdornment
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { validatorRegEx } from "../../../helpers";
import LanguageTranslate from '../../partials/UnderScoreLanguage'

const useStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 *
 * @name Quantity
 * @alias Quantity
 * @component
 * @exports Quantity
 *
 * @description
 * Quantity input field
 *
 */

export default function Quantity(props) {
    // console.log(props)
    const classes = useStyles();
    const [minValidQuantity, minValidQuantitySet] = React.useState(25)
    const [maxValidQuantity, maxValidQuantitySet] = React.useState(99999)

    React.useEffect(() => {
        if (props.quantity && props.quantityRangeMin && props.minimumOrderQuantity) {
            let calcMinQty = props.quantity * props.quantityRangeMin
            calcMinQty > props.minimumOrderQuantity ? minValidQuantitySet(calcMinQty) : minValidQuantitySet(props.minimumOrderQuantity)
        }
    }, [props.quantity, props.quantityRangeMin, props.minimumOrderQuantity])

    // React.useEffect(() => {
    //     if (props.quantity && props.quantityRangeMax && props.quantityRangeMax != '') {
    //         maxValidQuantitySet(props.quantity * props.quantityRangeMax)
    //     }
    // }, [props.quantity, props.quantityRangeMax])

    React.useEffect(() => {
       // console.log(props.user?.allowed_buying_qty_for_auction)
        if (props.marketData?.is_auction == 1 && props.vesselPriceSeller?.length > 0 && props.user?.allowed_buying_qty_for_auction != null) {
            maxValidQuantitySet(props.user?.allowed_buying_qty_for_auction > props.vesselPriceSeller[0]?.chat_data?.quantity ? props.vesselPriceSeller[0]?.chat_data?.quantity : props.user?.allowed_buying_qty_for_auction)
        } else {
            if (props.quantity && props.quantityRangeMax && props.quantityRangeMax != '') {
                maxValidQuantitySet(props.quantity * props.quantityRangeMax)
            }
        }
    }, [props.user, props.marketData, props.vesselPriceSeller, props.quantity, props.quantityRangeMax])

    React.useEffect(() => {
        if (props?.quantity && props.quantityTickSize) {
            if (props.quantity % props.quantityTickSize === 0) {
                props.setIsValidQuantity(true)
            }
            if (props.quantity % props.quantityTickSize !== 0) {
                props.setIsValidQuantity(false)
            }
        }
    }, [props.quantity, props.quantityTickSize])

    return (
        <Box>
            <InputLabel className={classes.marginBottom}><LanguageTranslate s='Quantity' /><sup style={{ color: "red" }}>*</sup></InputLabel>
            <TextValidator
                variant="outlined"
                fullWidth
                size="small"
                validators={[
                    "required",
                    "isNumber",
                    `matchRegexp:${validatorRegEx("numeric")}`,
                    `matchRegexp:${validatorRegEx("nonZero")}`,
                    `minNumber:${minValidQuantity}`,
                    `maxNumber:${maxValidQuantity}`
                ]}
                errorMessages={[
                    "Quantity field is required",
                    "Only numbers are accepted!",
                    "Quantity cannot be in negative numbers!",
                    "Quantity value can not be Zero !!",
                    `Quantity value cannot be less than ${minValidQuantity}MT`,
                    `Quantity value cannot be greater than ${maxValidQuantity}MT`
                ]}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            className="fsSmall"
                        >
                            MT
                        </InputAdornment>
                    )
                }}
                value={props.quantity}
                onChange={e => { props.setQuantity(e.target.value) }}
            />
            {props.isValidQuantity === false && (
                <Typography
                    component="p"
                    color="error"
                    className="has__error"
                    style={{ margin: '0 14px' }}
                >
                    Please enter Quantity as multiple of {props.quantityTickSize}.
                </Typography>
            )}
        </Box>
    );
}
